<template>
  <div></div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import messageDialogMixin from "@/mixins/messageDialogMixin";

export default defineComponent({
  mixins: [messageDialogMixin],
  created() {
    const message = decodeURI(this.$route.params.message);
    this.errorDialog(message, () => this.$router.push("/"));
  }
});
</script>

<style></style>
