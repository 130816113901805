import Vue from "vue";
import Router from "vue-router";
import store from "@/store/index.js";

// Common
import Error from "@/views/Error";
// Dashboard
import Dashboard from "@/views/dashboard/Dashboard";
import SendAssessment from "@/views/dashboard/SendAssessment";
// Auth
import SignIn from "@/views/auth/SignIn";
import ForgotPassword from "@/views/auth/ForgotPassword";
import ChangePassword from "@/views/auth/ChangePassword";
import RecoverPassword from "@/views/auth/RecoverPassword";
import BillingInfo from "@/views/auth/BillingInfo";
// Shop
import ProductList from "@/views/shop/ProductList";
import FinishCheckout from "@/views/shop/FinishCheckout";
// Assessment
import AssessmentStart from "@/views/assessment/AssessmentStart";
import AssessmentForm from "@/views/assessment/AssessmentForm";
import AssessmentQuestions from "@/views/assessment/AssessmentQuestions";
import AssessmentSubmit from "@/views/assessment/AssessmentSubmit";
import Report from "@/views/Report";

Vue.use(Router);

// TODO: Refactor routes in a separated file

const router = new Router({
  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "/error/:message",
      name: "Error",
      component: Error
    },
    {
      path: "/change-password",
      name: "ChangePassword",
      component: ChangePassword
    },
    {
      path: "/forgot-password",
      name: "ForgotPassword",
      component: ForgotPassword,
      meta: { requiresAuth: false }
    },
    {
      path: "/billing-info/:mode",
      name: "BillingInfo",
      component: BillingInfo
    },
    {
      path: "/shop/:type",
      name: "Shop",
      component: ProductList
    },
    {
      path: "/finish-checkout/:status",
      name: "FinishCheckout",
      component: FinishCheckout
    },
    {
      path: "/recover-password",
      name: "RecoverPassword",
      component: RecoverPassword,
      meta: { requiresAuth: false }
    },
    {
      path: "/signin",
      name: "signin",
      component: SignIn,
      meta: { requiresAuth: false }
    },
    // Dashboard
    {
      path: "/",
      name: "dashboard",
      component: Dashboard
    },
    {
      path: "/assessment/send",
      name: "SendAssessment",
      component: SendAssessment
    },
    {
      path: "/assessment/:assessmentId/resend",
      name: "ResendAssessment",
      component: SendAssessment
    },
    // Assessments
    {
      path: "/assessment/:assessmentId/start",
      name: "AssessmentStart",
      component: AssessmentStart,
      meta: { requiresAuth: false }
    },
    {
      path: "/assessment/:assessmentId/form",
      name: "AssessmentForm",
      component: AssessmentForm,
      meta: { requiresAuth: false }
    },
    {
      path: "/assessment/:assessmentId/questions",
      name: "AssessmentQuestions",
      component: AssessmentQuestions,
      meta: { requiresAuth: false }
    },
    {
      path: "/assessment/:assessmentId/submit",
      name: "AssessmentSubmit",
      component: AssessmentSubmit,
      meta: { requiresAuth: false }
    },
    // Report
    {
      path: "/report/:reportId",
      name: "Report",
      component: Report,
      meta: { requiresAuth: false }
    }
  ],
  mode: "history"
});

router.beforeEach((to, from, next) => {
  const requiresAuth = getIfRequiresAuth(to);
  if (requiresAuth) {
    const isAuth = store.getters.isUserAuthenticated;
    if (isAuth) {
      next(true);
    } else {
      store.dispatch("setRedirectRoute", to);
      next("/signin");
    }
  } else {
    next(true);
  }
});

function getIfRequiresAuth(route) {
  return !route.matched.some(record => record.meta.requiresAuth === false);
}

export default router;
