export default {
  methods: {
    openDialog(type, message, closeHandler, actions) {
      this.$emit("open-dialog", {
        type,
        message,
        closeHandler,
        actions
      });
    },
    errorDialog(message, closeHandler) {
      this.openDialog("error", message, closeHandler);
    },
    successDialog(message, closeHandler) {
      this.openDialog("success", message, closeHandler);
    },
    decisionDialog(message, actions) {
      this.openDialog("decision", message, null, actions);
    }
  }
};
