export default {
  1: {
    get: function (value) {
      return value >= 86 ? this.text1 : this.text2;
    },
    text1:
      "Le gusta trabajar en un ambiente innovador, moderno, orientado al futuro, preferiblemente libre de control, supervisión y detalles. Le gusta expresar sus ideas y opiniones sin restricciones. Le aburren los trabajos rutinarios, le gusta los cambios, la novedad y la actividad incesante. Su trabajo debe presentarle desafíos, retos, oportunidades para asumir riesgos, tomar decisiones y resolver problemas. Si tiene jefe, le gustaría que éste tomara decisiones rápidas y que evaluara su rendimiento en función de sus resultados y no del cumplimiento de los procedimientos o apego a los protocolos. Le gusta que su equipo o sus pares, cumplan los plazos, o mejor aún, trabajen con sentido de urgencia. Le gusta un ambiente de trabajo creativo, en donde se puedan desarrollar nuevas ideas, productos o metodologías.",
    text2:
      "Le suele gustar trabajar en un ambiente innovador, moderno, orientado al futuro, preferiblemente libre de control, supervisión y detalles. Le suele gustar expresar sus ideas y opiniones sin restricciones. Le pueden aburrir los trabajos rutinarios, le suelen gustar los cambios, la novedad y la actividad incesante. Su trabajo debe presentarle desafíos, retos, oportunidades para asumir riesgos, tomar decisiones y resolver problemas. Si tiene jefe, le gustaría que éste tomara decisiones rápidas y que lo evaluara en función de sus resultados y no del cumplimiento de los procedimientos o apego a los protocolos. Le tiende a gustar que su equipo o sus pares, cumplan los plazos, o mejor aún, que trabajen con sentido de urgencia. Le suele gustar un ambiente de trabajo creativo, en donde se puedan desarrollar nuevas ideas, productos o metodologías."
  },
  2: {
    get: function (value) {
      return value >= 86 ? this.text1 : this.text2;
    },
    text1:
      "Le gusta trabajar en un ambiente en donde tenga contacto con mucha gente, libre de control, supervisión y detalles. En donde tenga libertad de movimiento. Le gusta expresar sus ideas y opiniones. Un ambiente en donde pueda hablar con libertad.  Prefiere tener un jefe democrático a quien pueda tratar a la par y asociarse. Un ambiente de relaciones y actividades. Sus tareas deben incluir tener muchos contactos con personas, crear redes e integrarse a grupos. Le agrada un ambiente en donde pueda hablarle a grandes o pequeñas audiencias, a conocidos y extraños. Le gustan los ambientes cargados de entusiasmo y optimismo. Prefiere un ambiente en donde pueda capturar la atención de las personas y ejercer influencia con su encanto. Le agrada un clima libre de detalles y lleno de profundidad emocional. Le gusta un lugar de trabajo en donde pueda negociar los conflictos entre las personas de forma positiva.",
    text2:
      "Le suele gustar trabajar en un ambiente en donde tenga contacto con mucha gente, libre de control, supervisión y detalles. En donde tenga libertad de movimiento. Le tiende a gustar expresar sus ideas y opiniones. Le suele gustar un ambiente en donde pueda hablar con libertad.  Prefiere tener un jefe democrático a quien pueda tratar a la par y asociarse. Tiende a preferir un ambiente de relaciones y actividades. Prefiere que sus tareas incluyan tener contactos con personas, crear redes e integrarse a grupos. Le agrada un ambiente en donde pueda tener que hablarle a las personas o grupos. Le gustan los ambientes con tendencia al entusiasmo y optimismo. Prefiere un ambiente en donde pueda capturar la atención de las personas y ejercer influencia con su encanto. Le suele agradar un clima libre de detalles y lleno de profundidad emocional. Le tiende a gustar un lugar de trabajo en donde pueda negociar los conflictos entre las personas de forma positiva."
  },
  3: {
    get: function (value) {
      return value >= 86 ? this.text1 : this.text2;
    },
    text1:
      "Le gusta trabajar en un ambiente estable y predecible. Le gusta estar en un lugar que le permita crecer y desarrollarse a largo plazo, lo que comúnmente se llama, hacer carrera. Prefiere un ambiente que le permita cambiar a su ritmo, en donde no haya cambios repentinos ni abruptos, usted prefiere organizarse y planificar el cambio. Quiere trabajar con personas con las que pueda establecer relaciones profundas y duraderas. Le gusta trabajar en equipo. Le gusta un ambiente amigable, libre de conflictos y confrontaciones. Le gusta un ambiente de trabajo limpio, ordenado y organizado. Le gusta un ambiente que le brinde métodos y protocolos probados para realizar sus tareas. Le gusta un ambiente tradicional, familiar y conservador. Prefiere trabajar en un ambiente con un grupo pequeño de personas conocidas. Le gusta un ambiente que le dé el tiempo para reflexionar, antes de decidir y tomar acciones. Prefiere trabajar con un jefe que le apoye, cuando entre en dudas, sobre una decisión o escogencia.",
    text2:
      "Le suele gustar trabajar en un ambiente estable y predecible. Tiende a preferir estar en un lugar que le permita crecer y desarrollarse a largo plazo, lo que comúnmente se llama, hacer carrera. Suele preferir un ambiente que le permita cambiar a su ritmo, en donde no haya cambios repentinos ni abruptos, usted tiende a preferir organizarse y planificar el cambio. Suele querer tener compañeros con los que pueda establecer relaciones profundas y duraderas. Tiende a gustarle trabajar en equipo, en un ambiente amigable, libre de conflictos y confrontaciones. Tiende a preferir un ambiente de trabajo limpio, ordenado y organizado. Le gusta un ambiente que le tienda a brindar métodos y protocolos probados para realizar sus tareas. Suele preferir un ambiente tradicional, familiar y conservador. Tiene la tendencia a preferir trabajar en un ambiente con un grupo pequeño de personas conocidas. Suele gustarle un ambiente que le dé el tiempo para reflexionar, antes de decidir y tomar acciones. Tiende a preferir trabajar con un jefe que le apoye, cuando entre en dudas, sobre una decisión o escogencia."
  },
  4: {
    get: function (value) {
      return value >= 86 ? this.text1 : this.text2;
    },
    text1:
      "Le gusta trabajar en un ambiente en donde prevalezca el pensamiento crítico y analítico. Le gusta ocupar un cargo especializado, técnico o profesional. Prefiere trabajar con un grupo pequeño y establecer relaciones profesionales estrechas. Ambiente de trabajo familiar. Área de trabajo reservada, privada, sin mucho contacto con la gente y menos si son extraños. Un ambiente preferiblemente lógico y no emocional, predecible, en donde las normas estén claras y se respeten. Un ambiente en donde los controles de calidad sean minuciosos. Le gusta trabajar con un jefe que le dé toda la información que usted requiere y que lo oriente a la hora de dudas en una decisión. Le gusta ser evaluado por su calidad y su esmero en seguir los procedimientos establecidos. Le gusta un ambiente en donde los protocolos estén claros y se respeten. Los ambientes en donde se cuiden los altos estándares de calidad son de su preferencia.",
    text2:
      "Le tiende a gustar trabajar en un ambiente en donde prevalezca el pensamiento crítico y analítico. Suele ocuparse en cargos especializados, técnicos o profesionales. Prefiere trabajar con un grupo pequeño y establecer relaciones profesionales estrechas. Ambiente de trabajo familiar. Área de trabajo preferiblemente reservada, privada, y de ser posible sin mucho contacto con personas. Tiende a gustarle un ambiente preferiblemente lógico y no emocional, predecible, en donde las normas estén claras y se respeten. Un ambiente en donde los controles de calidad sean minuciosos. Le tiende a gustar trabajar con un jefe que le dé toda la información que requiere y que lo oriente a la hora de dudas en una decisión. Tiende a preferir ser evaluado por su calidad y su esmero en seguir los procedimientos establecidos. Le gusta un ambiente en donde los protocolos estén claros y se respeten. Los ambientes en donde se cuiden los altos estándares de calidad son de su preferencia."
  },
  5: "Prefiere un ambiente de trabajo que enfrente las crisis de forma rápida y directa. Que ofrezca retos, novedades y tenga garantizada la obtención de resultados y el éxito. Moderno y orientado al futuro. Un ambiente de trabajo que permita conocer personas nuevas y sea un escenario en el que pueda expresar sus ideas y opiniones. Un ambiente que se caracterice por el dinamismo, las relaciones, el entusiasmo y los retos. Un ambiente que le permita prever y resolver problemas. Que esté libre de supervisión y control. Con asignaciones dinámicas y libres de detalles. Un ambiente de trabajo que le permita cuestionar lo establecido y le deje aportar su criterio y creatividad. Un ambiente que permita un sentido del humor activo. Un ambiente de trabajo competitivo, con un equipo de personas que estimulen el progreso y la preparación.",
  6: "Prefiere un ambiente con alto nivel de riesgo en la toma de decisiones y audaz. Usted prefiere un ambiente de trabajo que le permita la posibilidad de participar activamente en la toma de decisiones, en donde pueda promover ideas innovadoras y cambios, enfrentar nuevos retos y resolver problemas. Prefiere trabajar con mucha gente más que de forma aislada, sobre todo prefiere conocer gente nueva. Le agrada un ambiente competitivo. Le gusta un ambiente de trabajo moderno, con visión de futuro, que le permita probar ideas retadoras, sin precedentes y repleto de actividad, dinamismo, optimismo y entusiasmo.",
  7: "Usted prefiere un ambiente de trabajo informal y relajado, rodeado de personas, y que le dé la oportunidad de conocer gente nueva. A usted le gusta trabajar en un ambiente en donde las relaciones personales sean claves para el negocio. Prefiere un trabajo en el que las decisiones se tomen rápido y con un líder, que más que jefe, sea un aliado democrático. Prefiere un ambiente en donde se puedan debatir ideas, en donde usted tenga la libertad de expresarse. Le gusta un ambiente innovador y con visión de futuro, libre de detalles y de rutinas.",
  8: "Usted prefiere un ambiente de trabajo que le proponga retos desafiantes con procedimientos y metodologías probadas. Usted desea un ambiente que le permita tener la responsabilidad y poder sobre las decisiones y que a la vez le permita jugar en equipo. Usted quiere un ambiente que le permita desarrollar proyectos novedosos, con tiempo para diseñar y aplicar un plan paso a paso. Usted quiere un ambiente laboral desafiante y confiable a la vez, retador y que al mismo tiempo permita la reflexión, de ritmo rápido, pero teniendo en cuenta los detalles y a la gente. Usted prefiere un ambiente en el que pueda debatir ideas novedosas de forma respetuosa, que permita la sana competencia.",
  9: "Usted prefiere un ambiente de trabajo que le proponga retos desafiantes con procedimientos y metodologías probadas. Usted desea un ambiente que le permita tener la responsabilidad y poder sobre las decisiones y que a la vez le permita jugar en equipo. Usted quiere un ambiente que le permita desarrollar proyectos novedosos con tiempo para diseñar y aplicar un plan paso a paso. Usted quiere que su ambiente de trabajo tome en cuenta los detalles en un proyecto, pero a buen ritmo. Usted prefiere un ambiente en el que pueda debatir ideas novedosas de forma respetuosa, que permita la sana competencia. A usted le gusta un ambiente principalmente armonioso y amistoso, pero activo y con energía. Usted quiere un ambiente laboral desafiante y confiable a la vez, retador y que al mismo tiempo permita la reflexión, de ritmo rápido, pero teniendo en cuenta los detalles y a la gente.",
  10: "Usted prefiere un ambiente de trabajo que le proponga retos desafiantes con procedimientos y metodologías probadas. Usted desea un ambiente que le permita jugar en equipo, en donde las decisiones se tomen de manera reflexiva y sin demoras. Usted quiere un ambiente que le permita desarrollar proyectos novedosos con tiempo para diseñar y aplicar un plan paso a paso. Usted quiere un ambiente laboral confiable y estable, pero que a la vez sea retador y desafiante. Usted quiere contar con tiempo para la reflexión. Usted quiere que su ambiente de trabajo tome en cuenta los detalles en un proyecto, pero a buen ritmo. Usted prefiere un ambiente en el que pueda debatir ideas novedosas de forma respetuosa, que permita la sana competencia. Usted prefiere un ambiente que se ocupe de las necesidades de la gente, para así facilitar la obtención de resultados. A usted le gusta un ambiente principalmente armonioso y amistoso, pero activo y con energía.",
  11: "Usted prefiere un ambiente de trabajo que le proponga retos desafiantes y tareas especializadas, y que le permita tener la responsabilidad y poder sobre las decisiones. Un jefe que le dé libertad de movimiento, le permita tener el control, pero que, al mismo tiempo, le facilite toda la información cuando sea necesario. Le gusta un ambiente basado en los logros, los hechos, con visión de futuro, ambicioso y profesional, más que emocional, sensible y basado en relaciones interpersonales. Un estilo de trabajo que le permita logros para ser alcanzados rápidamente, pero cuidando los estándares de calidad. Un trabajo retador y lleno de acción y cambios. Un trabajo con la suficiente presión, como para sentir el estímulo de su natural sentido de urgencia, pero que le permita la pausa para analizar información y recabar hechos. Necesita un ambiente que le permita conseguir el apoyo adecuado para el momento en que tenga demoras para decidir. Le gusta un ambiente que le de libertad para desarrollar sus ideas creativas e innovadoras.",
  12: "Usted prefiere un ambiente de trabajo que le proponga retos desafiantes y tareas especializadas, y que le permita tener la responsabilidad y poder sobre las decisiones. Un jefe que lo oriente de forma directa y específica a la vez. Un estilo de trabajo que le permita logros para ser alcanzados rápidamente, pero cuidando los estándares de calidad. Un trabajo retador y detallista a la vez. Un trabajo con la suficiente presión, como para sentir el estímulo de su natural sentido de urgencia, pero que le permita la pausa para analizar información y recabar hechos. Necesita un ambiente que le permita conseguir el apoyo adecuado para el momento en que tenga demoras para decidir. Libertad para desarrollar sus ideas creativas y asumir riesgos calculados.",
  13: "Usted prefiere dedicarse a un trabajo que le ofrezca múltiples retos y variados proyectos y tareas. Le gusta un ambiente en donde se puedan correr riesgos calculados, en donde la creatividad, el análisis y el pensamiento crítico sean importantes. Le agrada que haya protocolos, estándares de calidad establecidos, normas y manuales de procedimientos y a la vez que le den libertad para innovar. Prefiere un ambiente profesional, técnico, especializado, en donde se puedan producir resultados rápidos y de calidad. Le gusta un ambiente de acción, de proyectos, con visión de futuro y objetivo. Le gusta un ambiente que se dedique al análisis de información, de hechos y de evidencias, más que a las relaciones o a las emociones. Le gusta que le den tiempo para sopesar las fortalezas y las debilidades de las ideas propuestas.",
  14: "Usted prefiere dedicarse a actividades que involucren personas, le agrada construir y mantener redes y contactos cercanos. A usted le gusta un ambiente laboral, informal y relajado. Tiene una gran capacidad de relacionarse bien con casi todas las personas, no importa si los acaba de conocer o si son sus amigos de siempre. A usted le gusta un lugar de trabajo con un ambiente sincero, de confianza, personal y agradable; con pocos conflictos y poco hostil, en donde la comunicación y la credibilidad sean valores importantes. Aunque a usted le gusta la diversidad y los cambios, también va bien en un ambiente de trabajo estable y predecible, pero que le dé la oportunidad de relacionarse. A usted le gusta trabajar haciendo equipo con otros, en donde sea posible relacionarse y desarrollar amistades. Le gusta un ambiente de trabajo que atienda las necesidades de las personas y un jefe democrático, que se preocupe por su gente. Le gusta un ambiente con libertad de expresión, en donde se pueda conversar y debatir ideas, de forma amigable y abierta. Usted aporta estímulo, optimismo y entusiasmo al ambiente laboral.",
  15: "Usted prefiere dedicarse a actividades que involucren personas, le agrada construir y mantener redes y contactos cercanos. A usted le gusta un lugar de trabajo con un ambiente sincero, de confianza, personal y agradable; con pocos conflictos y poco hostil, en donde la comunicación y la credibilidad sean valores importantes. Usted prefiere un ambiente de trabajo estable y predecible. A usted le gusta trabajar haciendo equipo con otros, en donde sea posible relacionarse y desarrollar amistades. Le gusta un ambiente de trabajo que atienda las necesidades de las personas y un jefe que se preocupe por su gente. Le gusta un ambiente con libertad de expresión, en donde se pueda conversar y debatir ideas, de forma amigable y abierta.",
  16: "A usted le gusta un lugar de trabajo con un ambiente sincero, personal y agradable; con pocos conflictos y poco hostil. Usted prefiere dedicarse a actividades que involucren personas, le agrada construir y mantener redes y contactos cercanos. Usted prefiere un ambiente de trabajo estable, aunque se puede adaptar a los cambios si le dan el tiempo y le explican los detalles. Le gusta trabajar con un jefe democrático, abierto a la comunicación. Usted prefiere trabajar en equipo. Le gusta un ambiente alegre y relajado. Sus actividades preferidas son aquellas en las que se involucran otras personas.",
  17: "A usted le gusta un lugar de trabajo con un ambiente amigable, en donde pueda tener contacto con mucha gente y sea especializado en un área profesional; con un equipo de trabajo técnico, de alto nivel de calidad. Le gusta un ambiente que pueda ofrecerle métodos y procedimientos probados y que a la vez sea dinámico y activo. Le gusta trabajar con un jefe democrático, con quien se pueda comunicar, que tome en cuenta sus aportes y que además le ofrezca amplia información cada vez que usted lo requiera, ofreciéndole libertad de movimiento. Le gusta asumir proyectos con energía y optimismo, manteniendo el cuidado por la calidad. Le gusta un ambiente laboral que le facilite toda la información disponible a la hora de planificar y decidir. Disfruta de un ambiente laboral que le ofrezca variedad y contacto con gente amistosa, en donde haya un buen clima laboral y oportunidad para conocer gente nueva que sean profesionales y expertos, en sus áreas.",
  18: "A usted le gusta un lugar de trabajo con un ambiente amigable y especializado en un área profesional; con un equipo de trabajo técnico, de alto nivel de calidad. Le gusta un ambiente laboral que le facilite toda la información disponible a la hora de planificar y decidir. Le gusta un ambiente que pueda ofrecerle métodos y procedimientos probados y a la vez sea dinámico y activo. Le gusta trabajar con un jefe democrático, con quien se pueda comunicar, que tome en cuenta sus aportes y que además le ofrezca amplia información cada vez que usted lo requiera, ofreciéndole libertad de movimiento. Le gusta asumir proyectos con energía y optimismo, manteniendo el cuidado por la calidad. Disfruta de un ambiente laboral que le ofrezca variedad y contacto con gente amistosa, en donde haya un buen clima laboral y oportunidad para conocer gente nueva que sean profesionales y expertos, en sus áreas.",
  19: "A usted le gusta un lugar de trabajo con un ambiente amigable y especializado en un área profesional; con un equipo de trabajo técnico, de alto nivel de calidad. Le gusta trabajar en proyectos cuidadosamente pensados y analizados, que no muestren brechas. Le gusta que sus tareas y responsabilidades involucren investigar y vincularse con personas y clientes. Le gusta promover ideas técnicas, especializadas y complejas. Le gusta asumir los proyectos de principio a fin y cuidar que sean hechos bien desde el principio. Le agrada un ambiente de trabajo en donde se cuiden los detalles y la calidad. Para usted es importante un equipo de soporte técnico en el que confiar. Prefiere un ambiente de trabajo que cuide los estándares de calidad. Le gusta un ambiente de trabajo optimista, pero con una buena porción de realidad, lógica y análisis, a la hora de tomar decisiones. Usted es de actitud receptiva a la hora de conocer nuevas personas, pero a la hora de trabajar, prefiere un grupo pequeño de asociados, que le permita mantener relaciones cercanas. Usted le gusta un ambiente laboral que sea precavido y se anticipe a los problemas. Sus áreas preferidas, son las de especialidad técnica, que mantengan cuidado en el respeto de los protocolos establecidos y en los altos niveles de calidad. Le gusta tener el chance de poder escalar en complejidad en los proyectos.",
  20: "A usted tiende a gustarle un lugar de trabajo con un ambiente sincero, con pocas personas, más bien íntimo, privado y familiar. Usted prefiere un ambiente de trabajo estable, predecible, y que le aporte toda la información necesaria para ejercer sus responsabilidades. Quiere conocer, con antelación, todos los manuales, de normas y procedimientos y protocolos establecidos. Usted prefiere un ambiente de trabajo que le permita controlar sus actividades en todas las etapas. Usted tiende a preferir que se tome cuidado en la seguridad laboral, para proteger el control de calidad a lo largo de todo el proceso. Prefiere responsabilidades que le permitan revisar, al detalle, toda la información disponible, antes de tomar decisiones. Prefiere que no se le presione en la toma de decisiones. Prefiere un ambiente laboral que haga uso de sus habilidades de pensamiento lógico, crítico y analítico. Usted prefiere un ambiente que sea de altos niveles de especialidad técnica, un ambiente profesional o académico o que permita la investigación. Tiende a inclinarse por ambientes que tengan cultura de evitar problemas y ser precavidos.",
  21: "A usted le gusta un lugar de trabajo con un ambiente sincero, con pocas personas, más bien íntimo, privado y familiar. Usted prefiere un ambiente de trabajo estable, predecible, aunque se puede adaptar a los cambios, si le dan el tiempo y le explican los detalles. Le gusta que le den todas las explicaciones, de forma concreta, de todas sus tareas y responsabilidades. Le gusta un ambiente técnico, profesional, donde los controles estén muy claros y los estándares de calidad sean altos. Le gusta un jefe que le brinde apoyo a la hora de tomar decisiones. Prefiere que las decisiones se tomen usando la lógica, la razón y el análisis y no las emociones. Le gusta trabajar en un ambiente que saque ventaja de sus habilidades de análisis, pensamiento crítico, lógica y cautela. Su trabajo ideal es aquel que le ofrece el tiempo necesario para reflexionar y analizar información y hechos, antes de actuar o tomar una decisión.",
  22: "Le gusta que su jefe sea concreto y detallista a la hora de explicarle lo que espera de su trabajo, principalmente para cumplir con los estándares y con las exigencias desde la primera vez. Le gusta trabajar en un ambiente en donde prevalezca el pensamiento crítico. Puesto especializado, técnico o profesional. Prefiere trabajar con un grupo pequeño y establecer relaciones profesionales estrechas y sinceras. Ambiente de trabajo familiar. Área de trabajo reservada y privada. Un ambiente preferiblemente lógico y no emocional, predecible, en donde las normas estén claras y se respeten. Libre de confrontaciones. Aunque prefiere un ambiente estable, se puede adaptar a los cambios, si le dan el tiempo y le explican los detalles. Le gusta un ambiente en donde los controles estén muy claros y los estándares de calidad sean altos. Le gusta un jefe que le brinde apoyo a la hora de tomar decisiones. Prefiere que las decisiones se tomen usando la lógica, la razón y el análisis y no las emociones.",
  23: "Ambiente libre de controles y supervisión estrecha, con libertad de movimiento y variedad de relaciones. Le gusta que sus responsabilidades incluyan contacto con personas y brindar apoyo a los demás. Prefiere un ambiente de trabajo que enfrente las crisis de forma rápida y directa, pero de manera amable y considerando las necesidades de las personas. Le gusta un ambiente que le permita ayudar a las personas a progresar y hacer carrera. A usted le gusta un ambiente que se mueva equilibradamente, entre cambio y estabilidad. Usted quiere un jefe democrático, que al mismo tiempo le aclare sus responsabilidades y líneas de mando, desde el principio. Usted quiere una cultura que beneficie los resultados a través de métodos probados y se mantenga atento a las necesidades de las personas. Le gusta un ambiente laboral, preferiblemente amable, amigable, pero provisto de retos y desafíos.",
  24: "Ambiente libre de controles, detalles y supervisión estrecha. Prefiere un ambiente de trabajo que enfrente las crisis de forma rápida y directa. Que ofrezca retos, novedades y problemas para ser resueltos de forma ingeniosa. Usted quiere tener el escenario para conquistar terrenos de autoridad, influencia y poder. A usted le gusta un ambiente laboral que le permita tomar la iniciativa en los procesos, para ganar tiempo en la obtención de los resultados. Prefiere un trabajo que le ofrezca la oportunidad de crear nuevas relaciones y contactos claves. Un ambiente en donde pueda negociar los conflictos entre las personas. Un ambiente con tareas diversas, dinámico, con visión de futuro, apalancado en los vínculos y el equipo, que permita el progreso y facilite la innovación y el logro de resultados, con una atención adecuada a los protocolos y a las necesidades de la gente.",
  25: "Usted prefiere un ambiente de trabajo que le proponga retos desafiantes con procedimientos y metodologías probadas. Usted desea un ambiente que le permita tener la responsabilidad y poder sobre las decisiones y que a la vez le permita jugar en equipo en un ambiente dinámico y entusiasta. Usted quiere un ambiente que le permita desarrollar proyectos novedosos con tiempo para diseñar y aplicar un plan paso a paso. Usted quiere que su ambiente de trabajo tome en cuenta los detalles en un proyecto, pero a buen ritmo. Usted prefiere un ambiente en el que pueda debatir ideas novedosas de forma respetuosa, que permita la sana competencia. A usted le gusta un ambiente principalmente armonioso y amistoso, pero activo y con energía. Usted quiere un ambiente laboral desafiante y confiable a la vez, retador y que al mismo tiempo permita la reflexión, de ritmo rápido, pero teniendo en cuenta los detalles y a la gente.",
  26: "Usted prefiere un ambiente de trabajo que le proponga retos desafiantes con procedimientos y metodologías probadas. Prefiere un ambiente de trabajo que enfrente las crisis de forma rápida y directa, pero manteniendo un buen clima. A usted le gusta un ambiente laboral que le permita tomar la iniciativa en los procesos, para ganar tiempo en la obtención de los resultados. Usted desea un ambiente que le permita tener la responsabilidad y poder sobre las decisiones y que a la vez le permita jugar en equipo. Usted quiere un ambiente que le permita desarrollar proyectos novedosos, con tiempo para diseñar y aplicar un plan paso a paso. Usted quiere un ambiente laboral desafiante y confiable a la vez, retador y que al mismo tiempo permita la reflexión, de ritmo rápido, pero teniendo en cuenta los detalles y a la gente. Usted prefiere un ambiente en el que pueda debatir ideas novedosas de forma respetuosa, que permita la sana competencia.",
  27: "Ambiente libre de controles, detalles y supervisión estrecha. Prefiere un ambiente de trabajo que enfrente las crisis de forma rápida y directa. Que ofrezca retos, novedades y tenga garantizada la obtención de resultados y el éxito. Moderno y orientado al futuro. Que le permita conocer personas nuevas y sea un escenario en el que pueda expresar sus ideas y opiniones, en una diversidad de temas. Un ambiente que se caracterice por el dinamismo, la flexibilidad, los cambios, las relaciones, el entusiasmo y los retos. Un ambiente que le permita prever y resolver problemas. Asignaciones dinámicas y libres de detalles. Un ambiente de trabajo que le permita cuestionar lo establecido, revisar los procesos y mejorarlos y le deje aportar su criterio y creatividad. Un ambiente de trabajo competitivo, con un equipo de profesionales que estimulen el progreso y la preparación. Un ambiente lleno de variadas e innovadoras actividades. Usted quiere un ambiente de trabajo que le permita ejercer el liderazgo y le de autoridad. Un ambiente espontáneo, que haga reconocimientos públicos del trabajo bien hecho y de los objetivos alcanzados.",
  28: "A usted le agrada un ambiente de trabajo que le ofrezca la oportunidad de conocer gente nueva y mantener contacto con personas conocidas. Ambiente libre de controles, detalles y supervisión estrecha. Prefiere un ambiente de trabajo que enfrente las crisis de forma rápida y directa, manteniendo el cuidado con los estándares de calidad. Que ofrezca retos, novedades y tenga garantizada la obtención de resultados y el éxito, rápidamente. Moderno y orientado al futuro. Que le permita conocer personas nuevas y sea un escenario en el que pueda expresar sus ideas y opiniones, en una diversidad de temas. Un ambiente que se caracterice por el dinamismo, la flexibilidad, los cambios, las relaciones, el entusiasmo, los retos y la calidad. Un ambiente profesional que le permita prever y resolver problemas. Asignaciones dinámicas y libres de detalles. Un ambiente de trabajo que le permita cuestionar lo establecido, revisar los procesos y mejorarlos y le deje aportar su criterio y creatividad. Un ambiente de trabajo competitivo, con un equipo de profesionales expertos, que estimulen el progreso y la preparación. Un ambiente que permita seguir los protocolos pero que ofrezca retos y diversidad. Usted quiere un ambiente de trabajo que le permita ejercer el liderazgo y le dé autoridad. Un ambiente espontáneo, que haga reconocimientos públicos del trabajo bien hecho y de los objetivos alcanzados.",
  29: "Usted prefiere un ambiente de trabajo que le proponga retos desafiantes y tareas especializadas, y que le permita tener la responsabilidad y poder sobre las decisiones. Un jefe que lo oriente de forma directa y específica a la vez. Un estilo de trabajo que le permita logros para ser alcanzados rápidamente, pero cuidando los estándares de calidad. A usted le agrada un ambiente de trabajo que le ofrezca la oportunidad de conocer gente profesional y especializada y mantener contacto con personas conocidas. Ambiente libre de controles, detalles y supervisión estrecha, pero con un jefe disponible para consultar cuando sea necesario. Un trabajo retador y detallista a la vez. Un trabajo con la suficiente presión, como para sentir el estímulo de su natural sentido de urgencia, pero que le permita la pausa para analizar información y recabar hechos. Necesita un ambiente que le permita conseguir el apoyo adecuado para el momento en que tenga demoras para decidir. Libertad para desarrollar sus ideas creativas y asumir riesgos calculados. Un ambiente con un dinamismo equilibrado.",
  30: "A usted le gusta un ambiente que le permita obtener rápidos resultados, con altos niveles de calidad. Usted prefiere un ambiente de trabajo que le proponga retos desafiantes y tareas especializadas, y que le permita tener la responsabilidad y poder sobre las decisiones. Usted quiere un ambiente dinámico, motivante y con diversidad en la solución de nuevos problemas. Un jefe que le dé libertad de movimiento, le permita tener el control, pero que, al mismo tiempo, le facilite toda la información cuando sea necesario. Le gusta un ambiente basado en los logros, los hechos, con visión de futuro, ambicioso y profesional y que además le permita desarrollar una red de relaciones claves. Usted quiere que le valoren por sus resultados y por la calidad obtenida. Un trabajo retador y lleno de acción y cambios. Un trabajo con la suficiente presión como para sentir el estímulo de su natural sentido de urgencia, pero que le permita la pausa para analizar información y recabar hechos. Necesita un ambiente que le permita conseguir el apoyo adecuado para el momento en que tenga demoras para decidir. Le gusta un ambiente que le de libertad para desarrollar sus ideas creativas e innovadoras.",
  31: "Usted quiere poder innovar en los protocolos, para lograr cada vez mejores niveles de calidad. Usted prefiere un ambiente de trabajo que le proponga retos desafiantes con procedimientos y metodologías probadas. Usted desea un ambiente que le permita tener la responsabilidad y poder sobre las decisiones y que a la vez le permita jugar en equipo. Usted quiere un ambiente que le permita desarrollar proyectos novedosos, con tiempo para diseñar y aplicar un plan paso a paso. Usted quiere un ambiente laboral desafiante y confiable a la vez, retador y que al mismo tiempo permita la reflexión; de ritmo rápido, pero teniendo en cuenta los detalles y a la gente. Usted prefiere un ambiente en el que pueda debatir ideas novedosas de forma respetuosa, que permita la sana competencia. Usted quiere poder analizar información y hechos, para tomar decisiones rápidas, que respeten los estándares de calidad.",
  32: "Usted quiere un ambiente que estimule la innovación, el análisis y las metodologías exitosas. Usted desea un ambiente que le permita tener la responsabilidad y poder sobre las decisiones y que a la vez le permita jugar en equipo. Usted quiere poder innovar en los protocolos, para lograr cada vez mejores niveles de calidad. Usted prefiere un ambiente laboral que lo enfrente a retos desafiantes, con protocolos y metodologías probadas. Usted quiere un cargo que le permita desarrollar proyectos novedosos, con tiempo para diseñar y aplicar un plan paso a paso. Usted quiere un trabajo desafiante y confiable a la vez, retador y que al mismo tiempo permita la reflexión, de ritmo rápido, pero teniendo en cuenta los detalles, la calidad y la gente. Usted prefiere un ambiente en el que pueda debatir ideas novedosas de forma respetuosa, que permita la sana competencia. Usted quiere poder analizar información y hechos, para tomar decisiones rápidas, que respeten los estándares de calidad.",
  33: "Usted prefiere un ambiente de trabajo que le proponga retos desafiantes con procedimientos y metodologías probadas. Usted desea un ambiente que le permita tener la responsabilidad y poder sobre las decisiones y que a la vez le permita jugar en equipo y le dé tiempo para analizar las opciones. Usted quiere un ambiente que le permita desarrollar proyectos novedosos con tiempo para diseñar y aplicar un plan paso a paso. Usted quiere que su ambiente de trabajo tome en cuenta los detalles en un proyecto, pero a buen ritmo. Usted prefiere un ambiente en el que pueda debatir ideas novedosas de forma respetuosa, que permita la sana competencia. A usted le gusta un ambiente principalmente armonioso y amistoso, pero activo, con energía y de buen nivel profesional o técnico. Usted quiere un ambiente laboral desafiante y confiable a la vez; retador y que al mismo tiempo permita la reflexión y el cuidado de la calidad; de ritmo rápido, pero teniendo en cuenta los detalles y a la gente. ",
  34: "Usted quiere un ambiente de trabajo técnico y profesional, que estimule el alcance de los resultados con calidad y que reconozca los logros públicamente. Un ambiente que estimule la innovación y el análisis. Usted desea un ambiente que le permita progresar profesionalmente y le dé cada vez más responsabilidad y poder sobre las decisiones. Usted quiere poder innovar en los protocolos, para lograr cada vez mejores niveles de calidad y de rendimiento. Usted prefiere un ambiente laboral que lo enfrente a retos desafiantes, con protocolos y metodologías probadas. Usted quiere un cargo que le permita desarrollar proyectos novedosos, con tiempo para diseñar y aplicar un plan paso a paso. Usted prefiere un ambiente en el que se puedan debatir ideas novedosas y que permita la competencia sana. Usted quiere poder analizar información y hechos para tomar decisiones rápidas, que respeten los estándares de calidad y aumente el rendimiento.",
  35: "A usted le agrada un ambiente de trabajo y roles que le ofrezcan la oportunidad de conocer gente nueva y mantener contacto con personas conocidas, que las actividades le permitan trabajar en equipo y tomar decisiones rápidas. Le gusta trabajar con un jefe democrático y que tenga sentido de urgencia a la hora de accionar y decidir. Usted le gusta tener un lugar en donde pueda expresar sus ideas y que se le escuche. Le gusta un ambiente que esté orientado a los resultados y al futuro, pero, con una cuota moderada de estabilidad y reflexión. Usted quiere sentirse libre de detalles y de supervisión. Un ambiente que implique retos, desafíos, creatividad e innovación, al momento de solucionar problemas. Un ambiente que le dé autoridad para delegar e influir. Un ambiente amigable, con dinamismo y confiable.",
  36: "Le gusta un ambiente en donde pueda utilizar su capacidad para motivar e influenciar a los demás. Le gusta un ambiente laboral que le dé libertad de movimiento, con pocos controles, con un jefe democrático al que pueda expresarle sus ideas.  A usted le agrada un ambiente de trabajo que le ofrezca la oportunidad de conocer gente nueva y mantener contacto con personas conocidas, y que las actividades le permitan trabajar con una variedad de tareas y proyectos, en un buen clima laboral. A usted le gusta un ambiente de trabajo, libre de conflictos y confrontaciones, pero con retos y profesionalmente estimulante. Usted quiere formar parte del equipo, le gusta pertenecer, integrarse, le gusta además obtener la atención de los otros. Prefiere un trabajo poco rutinario y que le permita expresar sus ideas y comunicarse con personas y grupos, pero si se lo propone, tiene la capacidad para superar el aburrimiento natural de la rutina y ser persistente en los proyectos.",
  37: "Le gusta un ambiente en donde pueda utilizar su capacidad para motivar e influenciar a los demás. Le gusta un ambiente laboral que le dé libertad de movimiento, con pocos controles, con un jefe democrático al que pueda expresarle sus ideas.  A usted le agrada un ambiente de trabajo que le ofrezca retos y dinamismo. Le tiende a gustar que le den la oportunidad de conocer gente nueva y mantener contacto con personas conocidas, y que las actividades le permitan trabajar con una variedad de tareas y proyectos, en un buen clima laboral. A usted le gusta un ambiente de trabajo, libre de conflictos y confrontaciones, pero con retos y profesionalmente estimulante. Usted quiere formar parte del equipo, le gusta pertenecer e integrarse, le gusta además obtener la atención de los otros, si es posible prefiere asumir roles de liderazgo o poder. Prefiere un trabajo poco rutinario y que le permita expresar sus ideas y comunicarse con personas y grupos, pero si se lo propone, tiene la capacidad para superar el aburrimiento natural de la rutina y ser persistente en los proyectos.",
  38: "A usted le agrada un ambiente de trabajo que le ofrezca la oportunidad de conocer gente nueva y mantener contacto con personas conocidas, y que las actividades le permitan trabajar acompañado más que en un ambiente aislado. Usted trabaja mejor en un lugar en el que le reconozcan, públicamente, sus resultados. Le gusta integrarse a un equipo de trabajo enérgico, moderno, jovial, con visión de futuro, amigable y servicial. Le gusta apalancarse en metodologías probadas para alcanzar los resultados. Un ambiente que permita libertad de acción, con un supervisor democrático y disponible para cuando usted requiera despejar sus dudas. Un ambiente que no exija mucho pensamiento analítico, sino más bien pensamiento crítico, habilidades relacionales y creatividad. Usted se desenvuelve bien motivando y persuadiendo al equipo para el logro de las metas. Usted prefiere un ambiente de trabajo que le permita impulsar ideas, servicios y productos. Un ambiente libre de conflictos y confrontaciones, con un buen clima laboral.",
  39: "A usted le gusta un lugar de trabajo con un ambiente amigable, personal y agradable; que esté libre de rutinas, de detalles y de supervisión. Usted quiere un ambiente creativo, con visión de futuro, con retos profesionales e innovador. Un ambiente con desafíos y riesgos. Le gusta un ambiente con sentido de urgencia y con diversidad. Usted quiere un jefe democrático que le asigne autoridad y poder, en la toma de decisiones. Usted quiere poder liderar equipos profesionales y motivarlos al logro de resultados rápidos, con altos estándares de calidad. Le gustan los cargos que le permitan construir una red de personas amplias y claves. Usted quiere trabajar con personas que puedan probar un alto rendimiento. Le gusta rodearse de personas creativas y profesionales, a la hora de lograr resultados y resolver problemas. Le gusta un trabajo que le permita comunicarse con un grupo grande de personas y ejercer influencia y motivación.",
  40: "Usted quiere un ambiente creativo, con visión de futuro, con retos profesionales e innovador en los protocolos. A usted le gusta que le evalúen por su capacidad de liderazgo y por sus resultados de calidad. Usted le gusta un lugar de trabajo con un ambiente amigable, personal y agradable; que esté libre de rutinas, de detalles y de supervisión. Un ambiente con desafíos y riesgos. Le gusta un ambiente con sentido de urgencia y con diversidad, pero que dedique tiempo al análisis y al cuidado de la calidad. Usted quiere un jefe democrático que le asigne autoridad y poder, en la toma de decisiones. Le gustan los cambios y enfrentar retos a la hora de resolver problemas. Usted quiere poder liderar equipos profesionales y motivarlos al logro de resultados rápidos con altos estándares de calidad. Le gustan los cargos que le permita construir una red de personas amplias y claves. Usted quiere trabajar con personas que puedan probar un alto rendimiento y profesionalismo. Le gusta rodearse de personas creativas y especializadas, a la hora de lograr resultados y resolver problemas. Le gusta un trabajo que le permita comunicarse, con un grupo grande de personas, y ejercer influencia y motivación.",
  41: "A usted le gusta un lugar de trabajo con un ambiente amigable, dinámico, profesional y retador; que esté libre de rutinas, de detalles y de supervisión. Usted quiere un ambiente creativo, con visión de futuro, con retos profesionales, innovador y que permita tiempo para analizar. Un ambiente en los que se innove en los protocolos y se cuide la calidad de forma activa. Le gusta un ambiente con sentido de urgencia y con diversidad en la solución de problemas. Usted quiere un jefe democrático que le asigne autoridad y poder, en la toma de decisiones. Usted quiere poder liderar equipos profesionales y motivarlos al logro de resultados rápidos y con altos estándares de calidad. Le gustan los cargos que le permitan construir una red de personas amplias y claves para el logro de las metas. Usted quiere trabajar con personas que puedan probar un alto rendimiento. Le gusta rodearse de personas creativas y profesionales, a la hora de lograr resultados y resolver problemas. Le gusta un trabajo que le permita comunicarse con un grupo grande de personas y ejercer influencia y motivación.",
  42: "A usted le gusta un lugar de trabajo con un ambiente cuidadoso de la calidad, como soporte en la ejecución de los proyectos. Usted se siente confortable cuando obtiene la atención de la gente que le rodea. Usted prefiere un ambiente que reconozca sus logros públicamente. Usted quiere un ambiente que le permita establecer vínculos con personas, y que estimule el pensamiento crítico. Usted quiere poder tomar decisiones rápidas cuando son rutinarias y tener el tiempo necesario para analizar la información disponible, cuando la decisión es de mayor envergadura. Usted quiere un ambiente estimulante y retador en el logro de resultados, con altos estándares de calidad. Usted se maneja bien en un ambiente laboral que cumpla los procesos y que atienda las necesidades de las personas. Usted prefiere un ambiente amigable, amable y diplomático. Le gusta que le ofrezcan la mayor cantidad de explicaciones, cuando le asignan nuevas tareas o roles. Le gusta rodearse de personas profesionales, a la hora de lograr resultados de calidad. Le gusta un trabajo que le permita comunicarse y motivar a las personas.",
  43: "Usted quiere poder construir una red de vínculos clave de personas y grupos. A usted le gusta un lugar de trabajo con un ambiente estable, amigable, sincero, privado, personal y agradable; con pocos conflictos y poco hostil. Usted quiere tener libertad de movimiento y de expresión. A usted le agrada un ambiente que le permita vincularse con una gran variedad de personas y a la vez desarrollarse profesionalmente. Usted quiere un lugar en donde pueda hacer carrera y que le permita crecer y aprender. Usted quiere que le provean de información detallada que le aclare su nivel de responsabilidad y de autoridad. Usted prefiere un ambiente de trabajo en donde sus logros sean reconocidos y tomados en cuenta, de ser posible, públicamente. Usted prefiere un jefe democrático, que le dé libertad de movimiento. Usted desea tener la oportunidad de poder delegar y hacer seguimiento, a lo largo de todo el proyecto. Usted prefiere un ambiente laboral que cuide de la aplicación de los procedimientos, para garantizar altos niveles de calidad. Usted quiere poder utilizar sus habilidades de análisis y de pensamiento crítico y al mismo tiempo su habilidad para relacionarse con los demás.",
  44: "A usted le agrada un ambiente que le permita vincularse con una gran variedad de personas, y a la vez desarrollarse profesionalmente. A usted le gusta un lugar de trabajo con un ambiente amigable, sincero, estable, personal y agradable; con pocos conflictos y poco hostil. Usted quiere que le provean de información detallada, que le aclaren su nivel de responsabilidad y de autoridad. A usted le gusta un lugar de trabajo que le permita libertad de expresión y libertad de controles, pero que al mismo tiempo cuide de los estándares de calidad. Usted quiere un lugar en donde pueda hacer carrera y que le permita rápidamente, crecer y aprender. Usted prefiere un ambiente de trabajo en donde sus logros sean reconocidos y tomados en cuenta, de ser posible, públicamente. Usted desea tener la oportunidad de poder delegar y hacer seguimiento, a lo largo de todo el proyecto. Usted prefiere un jefe de actitud democrática, que le de libertad de movimiento, dentro de un ambiente estable. A usted le gusta un ambiente de trabajo en donde se pueda relacionar y se puedan aplicar métodos y protocolos. A usted le gusta ver los efectos de su influencia en las personas y en los equipos, a través de resultados de calidad. Le gusta un ambiente dinámico en las relaciones y en los retos técnicos.",
  45: "Usted quiere poder construir una red de vínculos claves de personas y grupos. A usted le gusta un lugar de trabajo con un ambiente estable, amigable, profesional, sincero, privado, personal y agradable; con pocos conflictos y poco hostil. Usted quiere tener libertad para expresarse en un ambiente respetuoso. A usted le agrada un ambiente que le permita vincularse con una gran variedad de personas, y a la vez desarrollarse profesionalmente. Usted quiere un lugar en donde pueda hacer carrera y que le permita crecer y aprender. Usted quiere que le provean de información detallada, que le aclaren su nivel de responsabilidad y de autoridad. Usted prefiere un ambiente de trabajo en donde sus logros sean reconocidos y tomados en cuenta. Usted prefiere un jefe democrático con el que pueda hablar y que le comunique toda la información que usted necesita para hacer bien su trabajo. Usted desea tener la oportunidad de poder delegar y hacer seguimiento, a lo largo de todo el proyecto. Usted prefiere un ambiente laboral que cuide de la aplicación de los procedimientos, para garantizar altos niveles de calidad. Usted quiere poder utilizar sus habilidades de análisis y de pensamiento crítico y al mismo tiempo su habilidad para relacionarse con los demás.",
  46: "A usted le agrada un ambiente amigable, que le permita vincularse con las personas, pero, que a la vez beneficie el control de calidad y el respeto a las normas y procesos.  A usted le gusta un lugar de trabajo con un ambiente sincero, personal y agradable; con pocos conflictos y poco hostil. Le gusta un ambiente que haga reconocimientos a las personas por los logros alcanzados. Le gusta trabajar con gente inclinada a mantener altos estándares de calidad y en un ambiente que le dé libertad de movimiento. A usted le gusta participar en actividades en donde pueda interactuar con gente y tener la oportunidad de motivarlos y persuadirlos en el logro de resultados excelentes. Usted quiere trabajar con un jefe democrático, que le permita expresarse libremente y que a la vez le dé toda la información necesaria, para hacer su trabajo y tomar decisiones. Usted quiere un ambiente estable y a la vez lleno de diversidad.",
  47: "Usted prefiere un ambiente de trabajo que le permita innovar con procedimientos y metodologías probadas. Usted desea un ambiente que beneficie el trabajo de equipo, en donde las decisiones se tomen de manera reflexiva y sin demoras. Usted quiere un ambiente que le permita tener contacto con personas, enfocadas en la obtención de resultados. Usted prefiere un ambiente estable, en el que pueda promover ideas innovadoras, que le permita desarrollar proyectos novedosos, con tiempo para diseñar y aplicar un plan, paso a paso. Usted quiere un ambiente laboral confiable y estable, pero dinámico y con variedad. Usted quiere contar con tiempo para la reflexión. Usted quiere que su ambiente de trabajo tome en cuenta los detalles en un proyecto, pero a buen ritmo. Usted prefiere un ambiente en el que pueda debatir ideas novedosas de forma respetuosa, que permita la sana competencia. Usted prefiere un ambiente que se ocupe de las necesidades de la gente, para así facilitar la obtención de resultados. A usted le gusta un ambiente principalmente armonioso y amistoso, pero activo y con energía.",
  48: "A usted le agrada que le expliquen con detalles los sistemas, métodos y procedimientos de sus tareas. Le gusta un ambiente amigable, que le permita vincularse con las personas, pero que a la vez beneficie el logro de resultados.  Usted prefiere un ambiente con las áreas claves de responsabilidad muy claras. Prefiere sistemas y métodos probados y con posibilidad de innovar en ellos. Un ambiente que le permita tomar decisiones reflexivas y lógicas con cierto grado de creatividad. Un ambiente con un ritmo amable y positivo para actuar, pero a la vez dinámico. Un ambiente que controle, positivamente, la presión y las urgencias. Un lugar de trabajo que tenga respaldos en procesos exitosos para enfrentar los proyectos o problemas difíciles.",
  49: "Un ambiente que controle, positivamente, la presión y las urgencias. A usted le agrada un ambiente amigable, que le permita vincularse con las personas, pero, que a la vez le brinde retos. Le agrada construir y mantener redes y contactos cercanos. A usted le gusta un lugar de trabajo con un ambiente sincero, de confianza, personal, creativo y agradable; con pocos conflictos y poco hostil, en donde la comunicación y la credibilidad sean valores importantes. A usted le gusta trabajar haciendo equipo con otros, en donde sea posible relacionarse y desarrollar amistades. Le gusta un ambiente de trabajo que atienda las necesidades de las personas y un jefe que se preocupe por su gente. Le gusta un ambiente con libertad de expresión, en donde se pueda conversar y debatir ideas, de forma amigable y abierta. A usted le gusta participar en proyectos que cuide que los protocolos se cumplan bien, para que los resultados sean buenos desde el primer intento. Usted prefiere un ambiente con las áreas claves de responsabilidad claras. Sistemas y métodos probados y con posibilidad de innovar en ellos. Un ambiente que le permita tomar decisiones reflexivas y lógicas, con cierto grado de creatividad. Un ambiente con un ritmo amable y positivo para actuar, pero a la vez dinámico. Un lugar de trabajo que tenga respaldos en procesos exitosos para enfrentar los proyectos o problemas difíciles. Un jefe que le dé orientación cuando dude en la toma de decisiones.",
  50: "A usted le agrada un ambiente amigable, que le permita vincularse con las personas, pero, que a la vez le brinde retos y tareas especializadas. Usted quiere poder ir a su ritmo y sentirse libre de estrés. A usted le gusta participar en proyectos que cuide que los protocolos se cumplan bien, para que los resultados sean buenos desde el primer intento. A usted le agrada que le expliquen con detalles los sistemas, métodos y procedimientos de sus tareas. Usted prefiere un ambiente con las áreas claves de responsabilidad muy claras. Sistemas y métodos probados, y con posibilidad de innovar en ellos. Un ambiente que le permita tomar decisiones reflexivas y lógicas, con cierto grado de creatividad. Un ambiente con un ritmo amable y positivo para actuar, pero a la vez dinámico. Un ambiente que controle, positivamente, la presión y las urgencias. Un lugar de trabajo que tenga respaldos en procesos exitosos, para enfrentar los proyectos o problemas difíciles. Un jefe que le dé orientación cuando dude en la toma de decisiones.",
  51: "A usted le gusta un lugar de trabajo con un ambiente sincero, personal y agradable; con pocos conflictos y poco hostil. A usted le agrada un ambiente amigable, que le permita vincularse con los demás, y a la vez desarrollarse profesionalmente. A usted le gusta un lugar de trabajo que le provea información detallada sobre sus tareas y responsabilidades. Usted prefiere dedicarse a actividades que involucren personas, le agrada construir y mantener redes y contactos cercanos. Le gusta poder consultar las normas, procedimientos y protocolos. Usted prefiere un ambiente de trabajo estable, aunque se puede adaptar a los cambios si le dan el tiempo y le explican los detalles. Aprecia un ambiente en donde la ética sea un valor importante. Usted prefiere las líneas de autoridad clara y una estructura jerárquica de reporte establecida. Le gusta trabajar con un jefe democrático, abierto a la comunicación. Usted prefiere trabajar en equipo. Le gusta un ambiente alegre y relajado. A usted le gusta involucrarse e identificarse con la organización e integrase con el equipo.",
  52: "A usted le agrada un ambiente de trabajo que le ofrezca información amplia y detallada sobre sus responsabilidades y tareas. Ambientes claros en la comunicación, sin ambigüedades. Con un alto sentido de confianza y credibilidad. Prefiere trabajar con personas cercanas, en las que confíe, aunque es una persona abierta a conocer gente nueva. Usted prefiere un ambiente de trabajo que le permita tomar un proyecto de principio a fin y que se asuma de tal manera que se haga bien la primera vez. Prefiere evitar los problemas de un trabajo mal hecho. Le gusta pensar muy bien sus proyectos, tener tiempo para reflexionar y tomar decisiones con consciencia. Le gusta poder supervisar la calidad de sus proyectos dentro de un ambiente predecible. Ambientes de trabajo en los que se manejen protocolos y normas claras. Prefiere un jefe que le dé suficiente información y direcciones claras, pero que al mismo tiempo sea una persona cercana.",
  53: "Le agrada un ambiente que le permita debatir sus ideas con argumentos, y aprecie la reflexión y el pensamiento analítico. A usted le agrada un ambiente de trabajo que le ofrezca información amplia y detallada sobre sus responsabilidades y tareas. Le gusta tener tiempo para adaptarse a los cambios. Ambientes claros en la comunicación, sin ambigüedades. Con un alto sentido de confianza y credibilidad. Usted prefiere un ambiente de trabajo que le permita tomar un proyecto de principio a fin y que se asuma de tal manera que se haga bien desde el principio. Prefiere trabajar con personas cercanas, en las que confíe, aunque puede tener una actitud abierta a la hora de conocer gente nueva. Prefiere evitar los problemas de un trabajo mal hecho. Le gusta pensar muy bien sus proyectos, tener tiempo para reflexionar y tomar decisiones con consciencia. Le gusta poder supervisar la calidad de sus proyectos dentro de un ambiente predecible. Ambiente de trabajo en los que se maneje protocolos y normas claras. Le gusta que le den soporte al momento que lo requiera. Prefiere un jefe que le dé suficiente información y direcciones claras, pero que al mismo tiempo sea una persona cercana. ",
  54: "A usted le agrada un ambiente de trabajo que le ofrezca información amplia y detallada sobre sus responsabilidades y tareas. Un ambiente que le permita debatir sus ideas con argumentos, y aprecie la reflexión y el pensamiento crítico. Ambientes claros en la comunicación, sin ambigüedades. Con un alto sentido de confianza y credibilidad. Prefiere trabajar con personas cercanas, en las que confíe, aunque puede tener una actitud abierta a la hora de conocer gente nueva. Usted prefiere un ambiente de trabajo que le permita tomar un proyecto de principio a fin y que se asuma de tal manera que se haga bien la primera vez. Prefiere evitar los problemas de un trabajo mal hecho. Le gusta pensar muy bien sus proyectos, tener tiempo para reflexionar y tomar decisiones con consciencia. Le gusta tener tiempo para adaptarse a los cambios. Le gusta poder supervisar la calidad de sus proyectos dentro de un ambiente predecible. Ambiente de trabajo en los que se maneje protocolos y normas claras. Prefiere un jefe que le dé suficiente información y direcciones claras, pero que al mismo tiempo sea una persona cercana. Le gusta que le den libertad de movimiento, pero, con un buen soporte al momento que lo requiera.",
  55: "A usted le gusta un lugar de trabajo con un jefe que le ofrezca toda la información y explicaciones de su nivel de responsabilidad y al mismo tiempo le de libertad, para proponer nuevas ideas o modificaciones a los sistemas. Prefiere trabajar en un ambiente que entienda la importancia de hacer el trabajo bien desde el primer intento, siguiendo los procedimientos establecidos. Usted prefiere un ambiente que le ofrezca el tiempo necesario para reflexionar activamente las decisiones. Usted prefiere asumir retos que le permitan ver resultados de calidad, rápidamente. Usted quiere trabajar en un negocio que se preocupe por la estabilidad laboral y el logro de resultados. Le gusta que le permitan la privacidad a la hora de pensar en los proyectos y en ideas creativas y soluciones. Le gusta un ambiente que le permita controlar los estándares de calidad. Quiere oportunidades para innovar en la realización de un proyecto, en los métodos o en la solución de los problemas. Le gusta trabajar con un jefe que confíe en su capacidad de autogestión y sentido de urgencia cuando es necesario, a la hora de actuar y que, además, esté disponible en los momentos en que tenga dudas para decidir. Prefiere tareas que le permitan apoyarse en la información, en el análisis de los hechos y en los datos objetivos y no en el aspecto emocional.",
  56: "Prefiere un ambiente que entienda la importancia de hacer el trabajo bien desde el primer intento, siguiendo los procedimientos establecidos. Usted prefiere asumir retos que le permitan ver resultados de calidad, rápidamente. A usted le agrada un ambiente privado para trabajar y prefiere hacer equipo con su pequeño grupo de personas cercanas. A usted le gusta un lugar de trabajo con un jefe que le ofrezca toda la información y explicaciones de su nivel de responsabilidad y al mismo tiempo le de libertad, para proponer nuevas ideas o modificaciones a los sistemas. Usted prefiere un ambiente que le ofrezca el tiempo necesario para analizar bien las decisiones y asumir los cambios. Usted prefiere un cargo que le permita utilizar sus habilidades de análisis, pensamiento crítico y meticulosidad, pero que permita cierto grado de creatividad. Usted quiere un ambiente que cuide de la calidad, en donde los procedimientos y la seguridad sean importantes y en donde se logren resultados, manteniendo satisfechas las necesidades de la gente. Usted prefiere un ambiente de trabajo libre de conflictos y democrático, pero con foco en la calidad. Usted prefiere un ambiente que le ofrezca el tiempo necesario para reflexionar activamente las decisiones. Usted quiere trabajar en un negocio que se preocupe por la estabilidad laboral y el logro de resultados.",
  57: "A usted le gusta un lugar de trabajo con un ambiente sincero, con pocas personas, más bien íntimo y privado. Usted prefiere un ambiente de trabajo estable, predecible, aunque se puede adaptar a los cambios, si le dan el tiempo y le explican los detalles. Usted prefiere asumir retos que le permitan ver resultados de calidad, rápidamente. A usted le gusta un lugar de trabajo con un jefe que le ofrezca toda la información y explicaciones de su nivel de responsabilidad y al mismo tiempo le de libertad, para proponer nuevas ideas o modificaciones a los sistemas. Usted prefiere un ambiente que le ofrezca el tiempo necesario para analizar bien las decisiones y asumir los cambios. Usted prefiere un cargo que le permita utilizar sus habilidades de análisis, pensamiento crítico y meticulosidad, pero que permita cierto grado de creatividad. Usted quiere un ambiente que cuide de la calidad, en donde los procedimientos y la seguridad sean importantes y en donde se logren resultados, manteniendo satisfechas las necesidades de la gente. Usted prefiere un ambiente de trabajo libre de conflictos y democrático, pero con foco en la calidad. Usted prefiere un ambiente que le ofrezca el tiempo necesario para reflexionar activamente las decisiones. Usted quiere trabajar en un negocio que se preocupe por la estabilidad laboral, la calidad y el logro de resultados.",
  58: "A usted le gusta un lugar de trabajo con un jefe que le ofrezca toda la información y explicaciones de su nivel de responsabilidad. Usted prefiere un ambiente que le ofrezca el tiempo necesario para analizar bien las decisiones y asumir los cambios. Usted prefiere un cargo que le permita utilizar sus habilidades de análisis, pensamiento crítico y meticulosidad. Usted prefiere trabajar en un ambiente privado y con gente cercana. Usted quiere un ambiente que cuide de la calidad, en donde los procedimientos y la seguridad sean importantes y en donde se logren resultados, manteniendo satisfechas las necesidades de la gente. Usted quiere ser parte de quienes motivan a las personas a cumplir con los proyectos. Usted prefiere un ambiente de trabajo libre de conflictos y democrático, pero con foco en la calidad.",
  59: "A usted le gusta un lugar de trabajo en el que pueda obtener resultados rápidos con buen control de calidad. Prefiere un jefe que le ofrezca toda la información y explicaciones posibles, sobre sus tareas y responsabilidades, pero que le dé espacio para moverse creativamente. Le gusta ocupar cargos que le permitan desarrollar su creatividad y su capacidad de análisis. Le gusta una empresa en donde pueda ir conquistando roles de mayor poder, liderazgo y responsabilidad. Le gusta un ambiente de trabajo repleto de gente experta y de técnicos, pero que también le dé la oportunidad de contar con un espacio privado para pensar en solitario. Usted quiere que el trabajo le proponga desafíos y retos para afrontarlos detalladamente, para probar procedimientos, manteniendo los estándares de calidad.  Prefiere un ambiente en donde abunde la información y se pueda investigar. Le gusta proponer novedades bien sustentadas. Le gusta que le den libertad de movimiento. Usted se autoimpondrá la presión necesaria para cumplir con los plazos. Usted quiere un jefe que le brinde apoyo en los momentos en los que se atasque y tenga demoras. Usted quiere un lugar de trabajo que se esmere en el logro de resultados, con altos estándares de calidad y con un ambiente entusiasta. Usted quiere que los demás le brinden información de calidad.",
  60: "A usted le gusta un lugar de trabajo con un jefe que le ofrezca toda la información y explicaciones posibles, sobre sus tareas y responsabilidades. A usted le gusta que le aclaren lo que esperan de su desempeño, le gusta ocupar cargos que le permita desarrollar su creatividad y su capacidad de análisis. Le gusta asumir proyectos que se realicen meticulosamente, para que salgan bien al primer intento. Le gusta un ambiente de trabajo repleto de gente experta y de técnicos, pero que también le dé la oportunidad de contar con un espacio privado para pensar en solitario. Usted quiere que el trabajo le proponga desafíos y retos para afrontarlos detalladamente, para probar procedimientos, manteniendo los estándares de calidad.  Prefiere un ambiente en donde abunde la información y se pueda investigar. Le gusta proponer novedades bien sustentadas. Le gusta que le den libertad de movimiento. Usted se autoimpondrá la presión necesaria para cumplir con los plazos. Usted quiere un jefe que le brinde apoyo en los momentos en los que se atasque y tenga demoras. Usted quiere un lugar de trabajo que se esmere en el logro de resultados, con altos estándares de calidad y con un ambiente entusiasta.",
  61: "A usted le agrada un ambiente que le permita libertad de movimiento. Usted prefiere trabajar con un jefe democrático, que le dé toda la información disponible para que usted haga bien su trabajo, pero que a la vez no lo supervise de forma estrecha. Usted quiere poder utilizar su capacidad para influenciar y persuadir. Usted quiere poder usar su capacidad de pensamiento crítico y analítico en un ambiente de trabajo retador y desafiante. Usted quiere tener la posibilidad de expresarse libremente y debatir ideas con los demás. Usted quiere tener contacto con mucha gente, preferiblemente profesionales en sus áreas, en un ambiente activo y dinámico, pero que a la vez le permita un buen nivel de privacidad. Usted quiere poder asegurarse un buen nivel de control de calidad. Usted quiere poder analizar las ventajas y las desventajas de cualquier decisión y las posibilidades y amenazas de los procesos en curso.",
  62: "A usted le gusta que le aclaren lo que esperan de su desempeño. Le gusta ocupar cargos que le permitan desarrollar su creatividad, su capacidad de análisis y que tenga contacto con personas. Prefiere un ambiente en donde abunde la información y se pueda investigar. A usted le gusta un lugar de trabajo con un jefe que le ofrezca toda la información y explicaciones posibles, sobre sus tareas y responsabilidades, pero que también le permita espacio para la creatividad y la innovación, sobre todo en los protocolos y en el control de la calidad. Le gusta asumir proyectos que se realicen meticulosamente, para que salgan bien al primer intento. Le gusta un ambiente de trabajo repleto de gente experta y de técnicos, pero que también le dé la oportunidad de contar con un espacio privado para pensar en solitario. Usted quiere que el trabajo le proponga desafíos y retos para afrontarlos detalladamente y para probar procedimientos, manteniendo los estándares de calidad. Le gusta proponer novedades bien sustentadas. Le gusta que le den libertad de movimiento. Usted se autoimpondrá la presión necesaria para cumplir con los plazos. Usted quiere un jefe que le brinde apoyo en los momentos en los que se atasque y tenga demoras en la toma de decisiones. Usted quiere un lugar de trabajo que se esmere en el logro de resultados, con altos estándares de calidad y con un ambiente entusiasta.",
  63: "A usted le gusta que le aclaren lo que esperan de su desempeño. Prefiere un ambiente de trabajo en donde pueda expresarse libremente. Le gusta ocupar cargos que le permitan desarrollar su capacidad de análisis y su habilidad para relacionarse. Prefiere mantener distancia de lo burocrático y contar con un buen margen de movimiento. Le gusta tener un jefe democrático, que le reconozca sinceramente sus logros. Prefiere un ambiente de trabajo amistoso, con un buen clima laboral, sin muchos conflictos ni confrontaciones. Le gusta poder vincularse con muchas personas a través de sus asignaciones. Prefiere un ambiente en el que se cuiden las normas, los protocolos y los procedimientos establecidos y que a la vez sea positivo y optimista.  Le gusta un lugar de trabajo en donde le apoyen a la hora de tomar decisiones. Le gusta trabajar dando soporte a la gente, entrenamiento técnico y guía sobre estándares de calidad. Le gusta un ambiente más bien estable y predecible, con algunas oportunidades de cambio y diversidad. Le gusta un ambiente laboral que premie el respeto a la seguridad y a la calidad. Le gusta un ambiente que le permita desarrollarse profesionalmente y aprender constantemente.",
  64: "A usted le gusta que le aclaren lo que esperan de su desempeño, le gusta ocupar cargos que le permitan desarrollar su capacidad de análisis y su habilidad para relacionarse. Le gusta trabajar con un jefe democrático, que le reconozca sinceramente sus logros. Prefiere un ambiente de trabajo amistoso, con un buen clima laboral, sin muchos conflictos ni confrontaciones. Prefiere un ambiente en el que se cuiden las normas, los protocolos y los procedimientos establecidos y que a la vez sea positivo y optimista.  Le gusta un lugar de trabajo en donde lo apoyen a la hora de tomar decisiones. Le gusta trabajar dando soporte a la gente, entrenamiento técnico y guía sobre estándares de calidad. Le gusta un ambiente más bien estable y predecible, con algunas oportunidades de cambio y diversidad. Le gusta un ambiente laboral que premie el respeto a la seguridad y a la calidad. Le gusta un ambiente que le permita desarrollarse profesionalmente y aprender constantemente.",
  65: "Le gusta un ambiente que pueda ofrecerle métodos y procedimientos probados y a la vez sea dinámico, activo y libre de conflictos. A usted le gusta un lugar de trabajo con un ambiente amigable y especializado en un área profesional; con un equipo de trabajo técnico, de alto nivel de calidad. Le gusta un ambiente laboral que le facilite toda la información disponible a la hora de planificar y decidir. Le gusta trabajar con un jefe democrático, con quien se pueda comunicar, que tome en cuenta sus aportes y que además le ofrezca amplia información cada vez que usted lo requiera, ofreciéndole libertad de movimiento. Le gusta asumir proyectos con energía y optimismo, manteniendo el cuidado por la calidad. Disfruta de un ambiente laboral que le ofrezca variedad y contacto con gente amistosa, en donde haya un buen clima laboral y oportunidad para conocer gente nueva que sean profesionales y expertos, en sus áreas. Le gusta que le den el tiempo suficiente para adaptarse a los cambios.",
  66: "Le gusta un ambiente de trabajo que se preocupe sinceramente de las necesidades de la gente. Prefiere un ambiente laboral que le permita adaptarse a los cambios, que le dé el tiempo necesario para asumir los retos. A usted le gusta que le aclaren lo que esperan de su desempeño, le gusta ocupar cargos que le permitan desarrollar su capacidad de análisis y su habilidad para relacionarse de forma cómoda. Le gusta tener claras sus áreas de responsabilidad. Le gusta integrarse al equipo y generar sentido de pertenencia con la empresa. Usted quiere sentirse seguro sobre la calidad, manteniendo especial cuidado en el cumplimiento de los protocolos, normas y procedimientos. Usted prefiere contar con un espacio privado y con el tiempo necesario, para sus reflexiones, antes de actuar o decidir. Le gusta un ambiente libre de conflictos y de hostilidades. Prefiere un jefe disponible que lo ayude a decidir cuando usted se atasque en los detalles y que le dé más importancia a la lógica y el análisis que a lo emocional.",
  67: "Prefiere un ambiente en donde se puedan correr riesgos calculados, en donde la creatividad, el análisis, la reflexión y el pensamiento crítico sean importantes. Le agrada que haya protocolos, estándares de calidad establecidos, normas y manuales de procedimientos y a la vez que le den libertad para innovar, buscando mantener un buen clima laboral. Prefiere un ambiente profesional, técnico, especializado, en donde se puedan producir resultados rápidos y de calidad, de forma metódica. Le gusta un ambiente de acción, de proyectos, con visión de futuro y objetivo. Le gusta que le den tiempo para sopesar las fortalezas y las debilidades de las ideas propuestas. A usted le gusta un lugar de trabajo con un jefe que le ofrezca toda la información y explicaciones posibles, sobre sus tareas y responsabilidades, pero que al mismo tiempo le de libertad de movimiento. A usted le gusta que le aclaren lo que esperan de su desempeño, le gusta ocupar cargos que le permita desarrollar su creatividad y su capacidad de análisis. Le gusta que su jefe le reconozca de forma sincera sus logros. Le gusta un ambiente con variedad de proyectos y tareas, que le permita hacerle seguimiento de principio a fin. Le gusta un ambiente que principalmente se dedique al desarrollo de ideas y proyectos con excelencia y logro de resultados, en un clima amigable.",
  68: "Le gusta un ambiente que principalmente se dedique al desarrollo de ideas y proyectos con excelencia y logro de resultados, en un clima amigable. Prefiere un lugar de trabajo con un jefe que le ofrezca toda la información y explicaciones posibles, sobre sus tareas y responsabilidades, pero que al mismo tiempo le de libertad de movimiento. A usted le gusta que le aclaren lo que esperan de su desempeño, le gusta ocupar cargos que le permitan desarrollar su creatividad y su capacidad de análisis. Le gusta que su jefe le reconozca de forma sincera sus logros. Prefiere asumir proyectos que se realicen meticulosamente, para que salgan bien al primer intento. Le gusta un ambiente de trabajo con gente experta y profesional. Le gusta contar con un espacio privado para pensar en solitario. Usted quiere que el trabajo le proponga desafíos y retos para afrontarlos detalladamente. Le gusta un ambiente con variedad de proyectos y tareas, que le permita hacerle seguimiento de principio a fin.",
  69: "A usted le gusta un lugar de trabajo con un jefe que le ofrezca toda la información y explicaciones posibles, sobre sus tareas y responsabilidades, pero que al mismo tiempo le de libertad de movimiento. A usted le gusta que le aclaren lo que esperan de su desempeño, le gusta ocupar cargos que le permita desarrollar su creatividad y su capacidad de análisis. Le gusta que su jefe le reconozca de forma sincera sus logros. Prefiere asumir proyectos que se realicen meticulosamente, para que salgan bien al primer intento. Le gusta un ambiente de trabajo repleto de gente experta y de técnicos, pero que también le dé la oportunidad de contar con un espacio privado para pensar en solitario. Usted quiere que el trabajo le proponga desafíos y retos para afrontarlos detalladamente. Le gusta un ambiente con variedad de proyectos y tareas, que le permita hacerle seguimiento de principio a fin. Le gusta un ambiente que principalmente se dedique al desarrollo de ideas y proyectos con excelencia y logro de resultados, en un clima amigable.",
  70: "A usted le gusta un lugar de trabajo con un jefe que le ofrezca toda la información y explicaciones posibles, sobre sus tareas y responsabilidades. A usted le gusta que le aclaren lo que esperan de usted en su cargo. A usted le gusta realizar proyectos en los que se vean los resultados. Le gusta tener datos, hechos, evidencias, información disponible, para analizar. Prefiere un ambiente que se apoye en la lógica y la razón y no en las emociones. Le gusta que su ambiente de trabajo esté organizado y limpio. Le gusta trabajar con personas expertas y disciplinadas. Le gusta trabajar con un equipo pequeño de profesionales en sus áreas. Le gusta tener su propia oficina privada.  Usted le gusta enfrentar los proyectos de principio a fin. Sus relaciones de amistad en el trabajo siempre serán con pequeños grupos.",
  71: "A usted le gusta un lugar de trabajo con un ambiente que ofrezca retos, que sea amigable y agradable. Usted prefiere dedicarse a actividades que involucren construir y mantener redes de personas y explorar nuevos territorios. Usted prefiere un ambiente de trabajo con un alto grado de relaciones con las personas y al mismo tiempo que le permita resolver problemas. Le gusta un ambiente que le permita la creatividad y que también tenga sentido del orden. Sus actividades preferidas son aquellas en las que se involucran otras personas. Si bien puede asumir la rutina, prefiere un ambiente de trabajo que le ofrezca novedad. Prefiere un jefe que ejerza el liderazgo con política de puertas abiertas, con quien pueda debatir nuevas ideas.",
  72: "A usted le agrada un ambiente que le permita libertad de movimiento. Usted prefiere trabajar con un jefe democrático, que le dé toda la información disponible para que usted haga bien su trabajo, pero que a la vez no lo supervise de forma estrecha. Usted quiere poder utilizar su capacidad para influenciar y persuadir. Usted quiere poder usar su capacidad de pensamiento crítico y analítico en un ambiente de trabajo retador y desafiante. Usted quiere tener la posibilidad de expresarse libremente y debatir ideas con los demás. Le gusta poder tener contacto con mucha gente, preferiblemente profesionales en sus áreas, en un ambiente activo y dinámico, pero que a la vez le permita un buen nivel de privacidad. Usted quiere poder asegurarse un buen nivel de control de calidad. Usted quiere poder analizar las ventajas y las desventajas de cualquier decisión y las posibilidades y amenazas de los procesos en curso.",
  73: "Usted prefiere un trabajo que le brinde la oportunidad de aplicar métodos y protocolos en la solución de problemas nuevos. Le gusta un ambiente de trabajo innovador, pero al mismo tiempo ordenado y limpio. A usted le gusta que le aclaren lo que esperan de su desempeño. Usted prefiere un lugar de trabajo con un jefe que le ofrezca toda la información y explicaciones posibles, sobre sus tareas y responsabilidades, pero que al mismo tiempo le de libertad de movimiento y de supervisión. Le gusta ocupar cargos que le permita desarrollar su creatividad y su capacidad de análisis. Le gusta que su jefe le reconozca de forma sincera sus logros. Le gusta asumir proyectos que se realicen meticulosamente, para que salgan bien al primer intento. Le gusta un ambiente de trabajo repleto de gente experta y de técnicos, pero que también le dé la oportunidad de contar con un espacio privado para pensar y reflexionar en solitario. Usted quiere que el trabajo le proponga desafíos y retos para afrontarlos detalladamente. Le gusta un ambiente con variedad de proyectos y tareas, que le permita hacerle seguimiento de principio a fin. Le gusta un ambiente que principalmente se dedique al desarrollo de ideas y proyectos con excelencia y logro de resultados, en un clima amigable.",
  74: "A usted le agrada un ambiente que le permita vincularse con los demás, y a la vez desarrollarse profesionalmente. A usted le gusta un lugar de trabajo que le provea información detallada sobre sus tareas y responsabilidades. Le gusta poder consultar las normas, procedimientos y protocolos. Le gusta un ambiente de trabajo técnico, profesional, con contacto con gente experta. Aprecia un ambiente en donde la ética sea un valor importante.  Prefiere un ambiente sincero, personal y agradable; con pocos conflictos y poco hostil, que las decisiones se beneficien de la lógica y la razón y también de la empatía, cuando sea necesario. Usted prefiere las líneas de autoridad clara y una estructura jerárquica de reporte establecida. Usted le gusta involucrarse e identificarse con la organización e integrase con el equipo. Usted prefiere un ambiente de trabajo estable, aunque se puede adaptar a los cambios si le dan el tiempo y le explican los detalles. Prefiere que los procesos de las operaciones estén estandarizados y acompañen la escalada en la complejidad de los mismos."
};
