import axios from "axios";
import firebaseConfig from "@/config/firebase";
import stripePromise from "@/plugins/payment.js";
// const API_URL = "https://api.stripe.com";

const instance = axios.create({
  baseURL: firebaseConfig.apiBaseURL,
});

const API_URL = "https://api.higherupcoaching.com/";

// TODO: Add domain at Client-Only Integration Stripe config

export default {
  async processPayment(params) {
    const { priceId, customerId, type, quantity } = params;
    const session = await instance.post("/shop/start-checkout", {
      priceId,
      customerId,
      type,
      quantity,
    });
    stripePromise.then(async (stripe) => {
      stripe
        .redirectToCheckout({
          sessionId: session.data.id,
        })
        .then((response) => {
          console.log("Checkout Success");
          console.log(response);
        })
        .catch((error) => {
          console.log("Checkout Error");
          console.log(error);
        });
    });
  },
  async createCheckoutSession(body) {
    try {
      if (body) {
        let data = await axios.post(API_URL + "stripe/", body);
        return data;
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  async getCheckoutStatus(id) {
    try {
      if (id) {
        let data = await axios.get(API_URL + `stripe/status/${id}`);
        return data;
      } else {
        console.error("id not found, please enter id param");
      }
    } catch (error) {
      throw new Error(error);
    }
  },
  async getPaymentMethods() {
    try {
      let data = await axios.get(API_URL + "woocommerce/payment/methods");
      return data;
    } catch (error) {
      throw new Error(error);
    }
  },
};
