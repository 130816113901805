<template>
  <v-container
    class="container--fluid mx-0 mb-4 pt-0 pb-8 white-background od-mt-16 od-mb-16 px-6"
    style="margin-top: -15rem"
  >
    <v-row align="center" class="ma-0 pa-6 print-width" id="introduction">
      <v-col class="text-left" cols="auto" v-if="isUserAuthenticated">
        <v-btn class="no-print" icon large color="primary" to="/">
          <v-icon>fas fa-arrow-alt-circle-left</v-icon>
        </v-btn>
      </v-col>
      <v-col class="ma-0 pa-0 text-left">
        <span class="o-label">Aplicante:</span> {{ name }}<br />
        <span class="o-label">Correo Electrónico:</span> {{ email }}<br />
        <span class="o-label">Fecha de Aplicación:</span> {{ date }}<br />
      </v-col>
      <v-col class="d-sm-flex d-none justify-end ma-0 pa-0">
        <v-btn class="no-print" color="primary" outlined text @click="onPrint">
          <v-icon left>fas fa-print</v-icon>
          Imprimir
        </v-btn>
      </v-col>
      <v-col class="pa-0 text-left shrink no-screen">
        <img
          alt="Global DISC Assessments"
          class="mt-0"
          height="70"
          src="../assets/logo-print.png"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col class="ma-0 py-0">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row class="ma-0 o-content-area px-2 print-width" id="content-area">
      <v-col class="pa-0" cols="12">
        <v-container class="container--fluid ma-0 pa-0">
          <!-- Introducción -->
          <od-report-title>Introducción</od-report-title>
          <div class="centerContainer">
            <v-row class="ma-0">
              <v-col class="text-justify pa-0">
                <p>
                  Este informe analiza el comportamiento de una persona, es
                  decir, el modo en CÓMO hace las cosas. El DISC está basado en
                  los cuadrantes de conducta desarrollados por el Dr. William
                  Marston. La ciencia del comportamiento afirma, que las
                  personas exitosas son quienes se conocen a sí mismas, sus
                  habilidades y talentos, y se apalancan en ellas y en el poder
                  de su entusiasmo para dar respuesta al medio ambiente. Este
                  reporte le ofrece afirmaciones valiosas, sobre su estilo de
                  comportamiento y principales tendencias de conducta, que le
                  permitirán profundizar en el conocimiento de sí mismo.
                </p>
                <p
                  style="
                    font-weight: bold;
                    margin: auto;
                    justify-content: center;
                    display: flex;
                  "
                >
                  La medición utiliza cuatro dimensiones del comportamiento
                  normal
                </p>
              </v-col>
            </v-row>
          </div>
          <div class="centerContainer">
            <v-row class="ma-0">
              <v-col class="pa-0">
                <v-container class="container--fluid text-center pa-0">
                  <!-- Only for screen -->
                  <v-row class="ma-0 hidden-print-only">
                    <v-col cols="12" sm="3">
                      <v-row class="ma-0">
                        <v-col
                          class="text-h4 o-d-color o-letter pa-0"
                          cols="2"
                          sm="12"
                        >
                          D
                        </v-col>
                        <v-col class="pa-0" cols="10" sm="12">
                          <v-row class="ma-0 text-sm-center text-left">
                            <v-col class="o-label pa-0" cols="12">
                              Dominante
                            </v-col>
                            <v-col class="pa-0">
                              Enfocado en la solución de problemas y obtención
                              de resultados
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="d-print-inline-flex" cols="12" sm="3">
                      <v-row class="ma-0">
                        <v-col
                          class="text-h4 o-i-color o-letter pa-0"
                          cols="2"
                          sm="12"
                        >
                          I
                        </v-col>
                        <v-col class="pa-0" cols="10" sm="12">
                          <v-row class="ma-0 text-left text-sm-center">
                            <v-col class="o-label pa-0" cols="12">
                              Influyente
                            </v-col>
                            <v-col class="pa-0">
                              Enfocado en los vínculos interpersonales y en la
                              expresión de emociones
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="d-print-flex" cols="12" sm="3">
                      <v-row class="ma-0">
                        <v-col
                          class="text-h4 o-s-color o-letter pa-0"
                          cols="2"
                          sm="12"
                        >
                          S
                        </v-col>
                        <v-col class="pa-0" cols="10" sm="12">
                          <v-row class="ma-0 text-left text-sm-center">
                            <v-col class="o-label pa-0" cols="12">
                              Sostenedor
                            </v-col>
                            <v-col class="pa-0">
                              Enfocado en el mantenimiento del ritmo constante,
                              la persistencia y la estabilidad
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-row class="ma-0">
                        <v-col
                          class="text-h4 o-c-color o-letter pa-0"
                          cols="2"
                          sm="12"
                        >
                          C
                        </v-col>
                        <v-col class="pa-0" cols="10" sm="12">
                          <v-row class="ma-0 text-left text-sm-center">
                            <v-col class="o-label pa-0" cols="12">
                              Cauteloso
                            </v-col>
                            <v-col class="pa-0">
                              Enfocado en los procedimientos, protocolos y
                              estándares de calidad
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <!-- Only for print -->
                  <v-row class="ma-0 hidden-screen-only">
                    <v-col cols="3">
                      <v-row class="ma-0">
                        <v-col
                          class="text-h4 o-d-color o-letter pa-0"
                          cols="12"
                        >
                          D
                        </v-col>
                        <v-col class="pa-0" cols="12">
                          <v-row class="ma-0 text-center">
                            <v-col class="o-label pa-0" cols="12">
                              Dominante
                            </v-col>
                            <v-col class="pa-0">
                              Enfocado en la solución de problemas y obtención
                              de resultados
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="3">
                      <v-row class="ma-0">
                        <v-col
                          class="text-h4 o-i-color o-letter pa-0"
                          cols="12"
                        >
                          I
                        </v-col>
                        <v-col class="pa-0" cols="12">
                          <v-row class="ma-0 text-center">
                            <v-col class="o-label pa-0" cols="12">
                              Influyente
                            </v-col>
                            <v-col class="pa-0">
                              Enfocado en los vínculos interpersonales y en la
                              expresión de emociones
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="3">
                      <v-row class="ma-0">
                        <v-col
                          class="text-h4 o-s-color o-letter pa-0"
                          cols="12"
                        >
                          S
                        </v-col>
                        <v-col class="pa-0" cols="12">
                          <v-row class="ma-0 text-center">
                            <v-col class="o-label pa-0" cols="12">
                              Sostenedor
                            </v-col>
                            <v-col class="pa-0">
                              Enfocado en el mantenimiento del ritmo constante,
                              la persistencia y la estabilidad
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="3">
                      <v-row class="ma-0">
                        <v-col
                          class="text-h4 o-c-color o-letter pa-0"
                          cols="12"
                        >
                          C
                        </v-col>
                        <v-col class="pa-0" cols="12">
                          <v-row class="ma-0 text-center">
                            <v-col class="o-label pa-0" cols="12">
                              Cauteloso
                            </v-col>
                            <v-col class="pa-0">
                              Enfocado en los procedimientos, protocolos y
                              estándares de calidad
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </div>

          <!-- Estilos de Comportamiento -->
          <od-report-title id="your_styles" has-page-break>
            Comparativa de sus Estilos de Comportamiento
          </od-report-title>
          <div class="centerContainer">
            <v-row class="ma-0 pb-6">
              <v-col class="pa-0 text-justify" cols="12" md="6">
                <v-container class="container--fluid ma-0 pa-0">
                  <v-row class="ma-0">
                    <v-col class="pa-0 text-center text-h6" cols="12">
                      Estilo Primario
                    </v-col>
                    <v-col class="py-0 pl-0 pr-2" cols="12">
                      <p>
                        Su estilo primario o básico es su forma natural de
                        comportarse. Es la manera en la que usted se siente más
                        confortable, es el estilo que utiliza cuando está bajo
                        presión, estrés o cuando actúa sin pensar. Este es el
                        estilo de comportamiento que usted debe tratar de
                        mantener en su vida diaria, ya que, no requiere de su
                        parte, ni esfuerzo ni estrés. En el gráfico está
                        representado por las barras de colores.
                      </p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col class="pa-0 text-justify" cols="12" md="6">
                <v-container class="container--fluid ma-0 pa-0">
                  <v-row class="ma-0">
                    <v-col class="pa-0 text-center text-h6" cols="12">
                      Estilo Consciente
                    </v-col>
                    <v-col class="py-0 pr-0 pl-2" cols="12">
                      <p>
                        El estilo consciente de comportamiento se refiere a la
                        forma en la que usted modifica su estilo primario, para
                        adaptarse a las exigencias del medio ambiente. Es uno de
                        sus dos estilos importantes de comportamiento, pero si
                        la modificación del estilo primario es mucha, usted
                        deberá esforzarse demasiado para sostener este estilo y
                        esto pudiera generarle estrés. En el gráfico está
                        representado por las barras grises.
                      </p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col class="pa-0 text-center" ref="your_styles_chart_container">
                <GChart
                  class="hidden-print-only centerContainer"
                  type="ColumnChart"
                  :data="yourStylesChartData"
                  :options="yourStylesChartOptions"
                  @ready="onChartYourStylesReady"
                />
                <img class="hidden-screen-only" :src="imageURIYourStyles" />
              </v-col>
            </v-row>
          </div>

          <!-- Estilo Primario -->
          <od-report-title id="primary_style" has-page-break>
            Estilo Primario: {{ primaryProfileName }}
          </od-report-title>
          <div class="centerContainer">
            <v-row>
              <v-col
                class="pa-0 text-center"
                ref="primary_style_chart_container"
              >
                <GChart
                  class="hidden-print-only"
                  type="ColumnChart"
                  :data="primaryStyleChartData"
                  :options="primaryStyleChartOptions"
                  @ready="onChartPrimaryStyleReady"
                />
                <img class="hidden-screen-only" :src="imageURIPrimaryStyle" />
              </v-col>
            </v-row>
          </div>
          <div class="centerContainer">
            <v-row class="ma-0 py-8">
              <v-col class="pa-0 text-justify" cols="12">
                <ul>
                  <li v-for="(phrase, i) in primaryProfilePhrases" :key="i">
                    {{ phrase }}
                  </li>
                </ul>
              </v-col>
            </v-row>
          </div>

          <!-- Estilo Consciente -->
          <od-report-title id="conscious_style" has-page-break>
            Estilo Consciente: {{ consciousProfileName }}
          </od-report-title>
          <div class="centerContainer">
            <v-row class="ma-0 py-8">
              <v-col
                class="pa-0 text-center"
                ref="conscious_style_chart_container"
              >
                <GChart
                  class="hidden-print-only"
                  type="ColumnChart"
                  :data="consciousStyleChartData"
                  :options="consciousStyleChartOptions"
                  @ready="onChartConsciousStyleReady"
                />
                <img class="hidden-screen-only" :src="imageURIConsciousStyle" />
              </v-col>
            </v-row>
          </div>
          <div class="centerContainer">
            <v-row class="ma-0 pb-6 pt-0">
              <v-col class="pa-0 text-justify" cols="12">
                <ul>
                  <li v-for="(phrase, i) in consciousProfilePhrases" :key="i">
                    {{ phrase }}
                  </li>
                </ul>
              </v-col>
            </v-row>
          </div>

          <!-- Ambiente de Trabajo Ideal -->
          <od-report-title id="work_environment" has-page-break>
            Ambiente de Trabajo Ideal
          </od-report-title>
          <div class="centerContainer">
            <v-row class="ma-0 pb-4 pt-0">
              <v-col class="pa-0 text-justify" cols="12">
                <p>
                  Esta sección resalta el ambiente ideal de trabajo, según el
                  <strong>estilo primario</strong> de comportamiento. Estas
                  afirmaciones se refieren a las funciones, responsabilidades y
                  características adecuadas para que se sienta a gusto y
                  disfrute de su lugar de trabajo. Es importante resaltar que,
                  una persona flexible puede ampliar su sensación de disfrute a
                  diversos ambientes laborales, sin embargo, si se pudiera crear
                  uno ideal, este sería el adecuado para usted.
                </p>
              </v-col>
              <v-col class="pa-0 mt-6 text-justify" cols="12">
                <p class="ma-0">{{ primaryProfileWorkEnvironment }}</p>
              </v-col>
            </v-row>
          </div>

          <!-- Entienda a los Demás -->
          <od-report-title id="understanding_others" has-page-break>
            Entienda a los Demás
          </od-report-title>
          <v-row class="mt-0 mx-0 mb-2 pt-0">
            <v-col class="pa-0 text-justify" cols="12">
              <p
                style="
                  font-weight: bold;
                  margin: auto;
                  justify-content: center;
                  display: flex;
                  margin-bottom: 2rem;
                  width: 90%;
                "
              >
                A continuación se muestran algunas pautas de cosas que se deben
                hacer y no se deben hacer para comunicarse con otras personas
                que poseen algún estilo de comportamiento en particular:
              </p>
            </v-col>
          </v-row>
          <div class="centerContainer">
            <v-row
              class="ma-0 pt-0"
              style="
                font-weight: bold;
                margin: auto;
                justify-content: center;
                display: flex;
              "
            >
              <v-col
                style="
                  font-weight: bold;
                  margin: auto;
                  justify-content: center;
                  display: flex;
                "
                class="pa-0 text-justify"
                cols="12"
              >
                <v-container py-0 ma-0>
                  <v-row class="o-understand-row text-h6" v-if="!isXS">
                    <v-col class="o-understand-col py-1" cols="2">Factor</v-col>
                    <v-col class="o-understand-col py-1">¿Qué hacer?</v-col>
                    <v-col class="o-understand-col o-understand-last-col py-1">
                      ¿Qué evitar?
                    </v-col>
                  </v-row>
                  <v-row class="o-understand-row">
                    <v-col
                      class="o-understand-col py-1 py-sm-4"
                      cols="12"
                      sm="2"
                    >
                      <div :class="isXS ? 'title' : 'font-weight-bold'">
                        Dominante
                      </div>
                      <div
                        class="text-left"
                        :class="isXS ? 'subtitle-1' : 'caption'"
                      >
                        Enérgica, independiente, decidida, orientada a
                        resultados, creativa, ambiciosa.
                      </div>
                    </v-col>
                    <v-col
                      class="o-understand-col py-0 py-sm-4"
                      cols="12"
                      sm="5"
                    >
                      <span class="d-inline font-weight-black" v-if="isXS">
                        <v-icon color="success" small>fas fa-check</v-icon>
                        Hacer:
                      </span>
                      Vaya directo al grano, de forma clara, breve y concreta.
                      Sea más bien específico. Muéstrese seguro y firme. Ponga
                      el foco en los temas profesionales. Vaya preparado y
                      ofrezca alternativas y soluciones. Permita que tome la
                      decisión.
                    </v-col>
                    <v-col
                      class="o-understand-col o-understand-last-col pb-0 pt-1 py-sm-4"
                      cols="12"
                      sm="5"
                    >
                      <span class="d-inline font-weight-black" v-if="isXS">
                        <v-icon color="error" small>fas fa-ban</v-icon>
                        Evitar:
                      </span>
                      No sea evasivo ni divague. No pierda tiempo. No busque
                      establecer vínculos personales. No presente puntos en los
                      que no se sienta seguro.
                    </v-col>
                  </v-row>
                  <v-row class="o-understand-row">
                    <v-col
                      class="o-understand-col pb-1 pt-6 py-sm-4"
                      cols="12"
                      sm="2"
                    >
                      <div :class="isXS ? 'title' : 'font-weight-bold'">
                        Influyente
                      </div>
                      <div
                        class="text-left"
                        :class="isXS ? 'subtitle-1' : 'caption'"
                      >
                        Amigable, jovial, expresiva, entusiasta, optimista,
                        motivadora, conversadora, carismática.
                      </div>
                    </v-col>
                    <v-col
                      class="o-understand-col py-0 py-sm-4"
                      cols="12"
                      sm="5"
                    >
                      <span class="d-inline font-weight-black" v-if="isXS">
                        <v-icon color="success" small>fas fa-check</v-icon>
                        Hacer:
                      </span>
                      Permita que fluya una conversación amistosa. Invierta
                      tiempo en relacionarse y socializar. Mantenga un clima
                      cálido y relajado. Muestre interés en sus opiniones. Sea
                      rápido y comunicativo y deje que el otro hable. Tome en
                      cuenta sus recomendaciones y aportaciones.
                    </v-col>
                    <v-col
                      class="o-understand-col o-understand-last-col pb-0 pt-1 py-sm-4"
                      cols="12"
                      sm="5"
                    >
                      <span class="d-inline font-weight-black" v-if="isXS">
                        <v-icon color="error" small>fas fa-ban</v-icon>
                        Evitar:
                      </span>
                      No muestre la información dura de primero. No se muestre
                      frío ni distante. No domine ni controle la conversación.
                      No se enfoque solamente en las tareas o en los
                      procedimientos.
                    </v-col>
                  </v-row>
                  <v-row class="o-understand-row">
                    <v-col
                      class="o-understand-col pb-1 pt-6 py-sm-4"
                      cols="12"
                      sm="2"
                    >
                      <div :class="isXS ? 'title' : 'font-weight-bold'">
                        Sostenedor
                      </div>
                      <div
                        class="text-left"
                        :class="isXS ? 'subtitle-1' : 'caption'"
                      >
                        Paciente, estable, poco expresiva, desconfiada, modesta,
                        predecible, amable, sabe escuchar.
                      </div>
                    </v-col>
                    <v-col
                      class="o-understand-col py-0 py-sm-4"
                      cols="12"
                      sm="5"
                    >
                      <span class="d-inline font-weight-black" v-if="isXS">
                        <v-icon color="success" small>fas fa-check</v-icon>
                        Hacer:
                      </span>
                      Muéstrese empática y escuche con paciencia. Dedique tiempo
                      a descubrir, de forma sincera, sus intereses y objetivos.
                      Rompa el hielo. Sea considerado. Hable usando la lógica.
                      Sea sistemático y metódico.
                    </v-col>
                    <v-col
                      class="o-understand-col o-understand-last-col pb-0 pt-1 py-sm-4"
                      cols="12"
                      sm="5"
                    >
                      <span class="d-inline font-weight-black" v-if="isXS">
                        <v-icon color="error" small>fas fa-ban</v-icon>
                        Evitar:
                      </span>
                      No haga falsas promesas. No sea imponente ni se muestre
                      apurado. No vaya de forma directa al asunto o al tema. No
                      se muestre exigente, ni dominante. No se muestre
                      amenazante ni utilice su autoridad para presionar. No
                      interrumpa cuando esté hablando.
                    </v-col>
                  </v-row>
                  <v-row class="o-understand-row o-understand-last-row">
                    <v-col
                      class="o-understand-col pb-1 pt-6 py-sm-4"
                      cols="12"
                      sm="2"
                    >
                      <div :class="isXS ? 'title' : 'font-weight-bold'">
                        Cauteloso
                      </div>
                      <div
                        class="text-left"
                        :class="isXS ? 'subtitle-1' : 'caption'"
                      >
                        Exacta, analítica, consciente, equilibrada, cortés,
                        diplomática, busca hechos, crítica.
                      </div>
                    </v-col>
                    <v-col
                      class="o-understand-col py-0 py-sm-4"
                      cols="12"
                      sm="5"
                    >
                      <span class="d-inline font-weight-black" v-if="isXS">
                        <v-icon color="success" small>fas fa-check</v-icon>
                        Hacer:
                      </span>
                      Sea realista. Vaya preparado con hechos, cifras y análisis
                      detallados. Sea ordenado y organizado en la forma de
                      presentar la información. Sea específico en la
                      planificación, el alcance y la metodología. Tome en cuenta
                      los protocolos. Prometa lo que puede cumplir. Utilice
                      argumentos lógicos, científicos y técnicos.
                    </v-col>
                    <v-col
                      class="o-understand-col o-understand-last-col pb-0 pt-1 py-sm-4"
                      cols="12"
                      sm="5"
                    >
                      <span class="d-inline font-weight-black" v-if="isXS">
                        <v-icon color="error" small>fas fa-ban</v-icon>
                        Evitar:
                      </span>
                      No improvise. No sea brusco. No deje cosas sueltas ni al
                      azar. No grite. No genere confusión. No sea desorganizado.
                      No presione. No sea informal. No estimule un clima íntimo
                      ni personal. No utilice argumentos emocionales.
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </div>

          <!-- Descriptores de su Estilo Primario -->
          <od-report-title id="primary_style_descriptors" has-page-break>
            Descriptores de su Estilo Primario
          </od-report-title>
          <od-xs-rotation-message />
          <div class="centerContainer">
            <v-row class="ma-0 pa-0" v-show="!isXS">
              <v-col class="text-justify pa-0 hidden-print-only" cols="12">
                <p
                  style="
                    font-weight: bold;
                    margin: auto;
                    justify-content: center;
                    display: flex;
                    margin-bottom: 2rem;
                    width: 90%;
                  "
                >
                  Esta sección presenta los descriptores de cada factor. Los
                  colores en cada columna reflejan los resultados del estilo
                  primario. Los factores que resultaron por encima del 50% están
                  representados en la columna con el símbolo + y los que
                  resultaron por debajo del 50% en la columna con el símbolo -.
                </p>
              </v-col>
              <v-row class="centerContainer">
                <v-col cols="12" class="centerContainer">
                  <v-container class="o-table" v-if="!isXS">
                    <v-row>
                      <v-col class="pa-0 text-h6">Extroversión</v-col>
                      <v-col class="pa-0 text-h6">Introversión</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pa-0 text-h6">Tarea</v-col>
                      <v-col class="pa-0 text-h6" cols="6">Relaciones</v-col>
                      <v-col class="pa-0 text-h6">Tarea</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pa-0 text-h6 o-d-bgcolor-lighted">D</v-col>
                      <v-col class="pa-0 text-h6 o-i-bgcolor-lighted">I</v-col>
                      <v-col class="pa-0 text-h6 o-s-bgcolor-lighted">S</v-col>
                      <v-col class="pa-0 text-h6 o-c-bgcolor-lighted">C</v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pa-0 text-h6">+</v-col>
                      <v-col class="pa-0 text-h6">+</v-col>
                      <v-col class="pa-0 text-h6">+</v-col>
                      <v-col class="pa-0 text-h6">+</v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        class="ma-0 pa-0 o-descriptor-separator"
                        v-for="(group, index) in descriptors"
                        :key="index"
                      >
                        <div
                          class="o-descriptor-gradient"
                          :style="getDescriptorStyle(group.factor)"
                        >
                          <div
                            v-for="descriptor in group.descriptors"
                            :key="descriptor"
                          >
                            {{ descriptor }}
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        class="ma-0 pa-0"
                        v-for="(group, index) in descriptors"
                        :key="index"
                      >
                        <div
                          class="o-descriptor-gradient"
                          :style="getDescriptorStyle(group.factor, true)"
                        >
                          <div
                            v-for="descriptor in group.oppositeDescriptors"
                            :key="descriptor"
                          >
                            {{ descriptor }}
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pa-0 text-h6">-</v-col>
                      <v-col class="pa-0 text-h6">-</v-col>
                      <v-col class="pa-0 text-h6">-</v-col>
                      <v-col class="pa-0 text-h6">-</v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        v-for="(group, index) in descriptors"
                        :class="
                          'o-' +
                          group.factor.toLowerCase() +
                          '-bgcolor-lighted pa-0'
                        "
                        :key="index"
                      >
                        {{ group.approach }}
                      </v-col>
                    </v-row>
                  </v-container>
                  <v-container class="o-table pa-0" v-else>
                    <v-row
                      class="py-2"
                      v-for="(group, index) in descriptors"
                      :key="index"
                    >
                      <v-col
                        class="px-1 py-0 text-center text-h6"
                        cols="12"
                        :class="'o-' + group.factor.toLowerCase() + '-bgcolor'"
                      >
                        {{ metadata[group.factor].label }}
                      </v-col>
                      <v-col class="pa-0 text-h6" cols="12">+</v-col>
                      <v-col
                        class="px-1 py-0 text-center o-descriptor-separator"
                        cols="12"
                        :style="getDescriptorStyle(group.factor)"
                      >
                        {{ group.descriptors.join(", ") }}
                      </v-col>
                      <v-col
                        class="px-1 py-0 text-center"
                        cols="12"
                        :style="getDescriptorStyle(group.factor, true)"
                      >
                        {{ group.oppositeDescriptors.join(", ") }}
                      </v-col>
                      <v-col class="pa-0 text-h6" cols="12">-</v-col>
                      <v-col
                        class="pa-0"
                        :class="'o-' + group.factor.toLowerCase() + '-bgcolor'"
                      >
                        {{ group.approach }}
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-row>
          </div>

          <!-- Fuerza de los Factores -->
          <od-report-title id="factors_force" has-page-break>
            Fuerza de los Factores
          </od-report-title>
          <od-xs-rotation-message />
          <v-row class="ma-0 pa-0" v-show="!isXS">
            <div class="centerContainer">
              <v-col class="pa-0">
                <v-container class="o-table">
                  <v-row
                    class="pt-0 pb-8"
                    v-for="(item, index) in descriptors"
                    :class="index == 2 ? 'page-break-before' : ''"
                    :key="item.factor"
                  >
                    <v-col
                      class="text-h6"
                      cols="12"
                      :class="
                        'o-' + item.factor.toLowerCase() + '-bgcolor-lighted'
                      "
                      >{{ metadata[item.factor].label }}</v-col
                    >
                    <v-col class="text-center o-label px-0 ma-0" cols="12">
                      <v-icon left>fas fa-arrow-left</v-icon>
                      <span>Fuerza del Factor {{ item.factor }}</span>
                      &nbsp;
                      <v-icon left>fas fa-arrow-right</v-icon>
                    </v-col>
                    <v-col class="ma-0 pa-0" cols="12">
                      <v-container class="pa-0 o-table">
                        <v-row class="text-caption ma-0">
                          <v-col class="px-0" ref="intensity_label">
                            Muy baja
                          </v-col>
                          <v-col class="px-0">Baja</v-col>
                          <v-col class="px-0">Media baja</v-col>
                          <v-col class="px-0">Media</v-col>
                          <v-col class="px-0">Media alta</v-col>
                          <v-col class="px-0">Alta</v-col>
                          <v-col class="px-0">Muy alta</v-col>
                        </v-row>
                        <v-row class="ma-0">
                          <v-col
                            class="o-factor-force-bar pa-2"
                            :style="getFactorBarStyle(item.factor, 'primary')"
                          >
                            Primario
                          </v-col>
                        </v-row>
                        <v-row class="ma-0">
                          <v-col
                            class="o-factor-force-bar pa-2"
                            :style="getFactorBarStyle(item.factor, 'conscious')"
                          >
                            Consciente
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                    <v-col class="pa-1 text-center font-weight-bold" cols="12">
                      {{ item.approach }}
                    </v-col>
                    <v-col class="ma-0 pa-0" cols="12">
                      <v-container class="pa-0 o-table">
                        <v-row class="text-caption ma-0">
                          <v-col class="px-0"> Ud siempre es </v-col>
                          <v-col class="px-0"> Ud tiende a ser </v-col>
                          <v-col
                            class="px-0"
                            :ref="`factor_how_sometimes_${index}`"
                          >
                            Ud algunas veces es
                          </v-col>
                          <v-col class="px-0"> Ud tiende a ser </v-col>
                          <v-col class="px-0"> Ud siempre es </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                    <v-col class="text-justify" cols="6">
                      {{ item.oppositeInterpretation }}
                    </v-col>
                    <v-col class="text-justify" cols="6">
                      {{ item.interpretation }}
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </div>
          </v-row>

          <!-- Óvalo Personal -->
          <od-report-title id="personal_oval" has-page-break>
            Circulo Personal
          </od-report-title>
          <od-xs-rotation-message />
          <v-row class="ma-0 pt-2 text-body-2" v-show="!isXS">
            <v-col class="pa-0 ma-0 centerContainer" cols="12">
              <od-oval
                :primary-profile-id="primaryProfileOvalId"
                :conscious-profile-id="consciousProfileOvalId"
              ></od-oval>
            </v-col>
            <v-col class="text-center py-1" cols="12" v-if="equalProfiles">
              <div class="o-oval-shape o-background-black"></div>
              <span class="o-label">Primario = Consciente</span>:
              {{ primaryProfileId }} - {{ primaryProfileName }} ({{
                primaryProfileCode
              }})
            </v-col>
            <v-col class="text-center py-1" cols="12" v-else>
              <div class="o-oval-shape o-background-black"></div>
              <span class="o-label">Primario</span>: {{ primaryProfileId }} -
              {{ primaryProfileName }} ({{ primaryProfileCode }}) <br />
              <div class="o-oval-shape o-background-gray"></div>
              <span class="o-label">Consciente</span>:
              {{ consciousProfileId }} - {{ consciousProfileName }} ({{
                consciousProfileCode
              }})
            </v-col>
          </v-row>

          <!-- Plan de Progreso Personal -->
          <od-report-title id="personal_plan" has-page-break>
            Plan de Progreso Personal
          </od-report-title>
          <od-xs-rotation-message />
          <div class="centerContainer">
            <v-row class="ma-0 pt-2" v-show="!isXS">
              <v-col class="pa-0 text-justify" cols="12">
                <p>
                  La información de este reporte es útil en la medida en que
                  usted la pueda utilizar para su propio descubrimiento y
                  progreso. Esta sección está diseñada como una guía que le
                  ayudará a sacar el mayor provecho de sus resultados. Le
                  recomendamos que le dedique tiempo y profundice en estas
                  indagaciones.
                </p>
                <p>
                  1.- Lea su estilo <b>PRIMARIO</b> y destaque aquí las tres
                  frases que usted piensa que le describen mejor:
                </p>
                <ol class="o-answer-space">
                  <li>
                    <div></div>
                    <div></div>
                  </li>
                  <li>
                    <div></div>
                    <div></div>
                  </li>
                  <li>
                    <div></div>
                    <div></div>
                  </li>
                </ol>
                <p>
                  2.- Lea su estilo <b>CONSCIENTE</b> y destaque aquí las tres
                  frases que usted piensa que le describen mejor:
                </p>
                <ol class="o-answer-space">
                  <li>
                    <div></div>
                    <div></div>
                  </li>
                  <li>
                    <div></div>
                    <div></div>
                  </li>
                  <li>
                    <div></div>
                    <div></div>
                  </li>
                </ol>
                <p>
                  3.- Hay mucha diferencia entre su estilo primario y su estilo
                  consciente (si tiene dudas, puede revisar los gráficos de este
                  reporte):
                </p>
                <div class="o-answer-space">
                  Sí
                  <div class="o-yes-no-fill">
                    <span class="no-screen">:</span>
                  </div>
                  No
                  <div class="o-yes-no-fill">
                    <span class="no-screen">:</span>
                  </div>
                </div>
                <p>
                  4.- Si su respuesta fue sí, ¿por qué cree que está teniendo
                  que adaptar su comportamiento primario o natural?
                </p>
                <div class="o-answer-space">
                  <div></div>
                  <div></div>
                </div>
                <p class="page-break-before">
                  5.- Mencione 3 aspectos de su estilo primario en los que
                  debería progresar, para seguir avanzando en su rendimiento:
                </p>
                <ol class="o-answer-space">
                  <li>
                    <div></div>
                    <div></div>
                  </li>
                  <li>
                    <div></div>
                    <div></div>
                  </li>
                  <li>
                    <div></div>
                    <div></div>
                  </li>
                </ol>
                <p>
                  6.- Desarrolle un plan de acción, que le ayude a progresar en
                  los tres aspectos mencionados en la pregunta anterior:
                </p>
                <v-container class="o-table o-plan-table">
                  <v-row>
                    <v-col class="text-h6 text-center">
                      Plan de Progreso Personal
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="font-weight-bold">Aspecto</v-col>
                    <v-col class="font-weight-bold">Objetivo</v-col>
                    <v-col class="font-weight-bold">Acciones</v-col>
                    <v-col class="font-weight-bold">
                      Tiempo para el resultado
                    </v-col>
                    <v-col class="font-weight-bold"
                      >Evidencias de progreso</v-col
                    >
                  </v-row>
                  <v-row>
                    <v-col>1.</v-col>
                    <v-col></v-col>
                    <v-col></v-col>
                    <v-col></v-col>
                    <v-col></v-col>
                  </v-row>
                  <v-row>
                    <v-col>2.</v-col>
                    <v-col></v-col>
                    <v-col></v-col>
                    <v-col></v-col>
                    <v-col></v-col>
                  </v-row>
                  <v-row>
                    <v-col>3.</v-col>
                    <v-col></v-col>
                    <v-col></v-col>
                    <v-col></v-col>
                    <v-col></v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
    </v-row>
    <div id="report-footer">
      <div id="report-footer-cell"></div>
    </div>
  </v-container>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

import Oval from "./oval/Oval";
import ReportTitle from "./report/ReportTitle.vue";
import XSRotationMessage from "./report/XSRotationMessage";

export default defineComponent({
  components: {
    "od-oval": Oval,
    "od-report-title": ReportTitle,
    "od-xs-rotation-message": XSRotationMessage
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    consciousProfile() {
      return this.data && this.data.profiles && this.data.profiles.conscious
        ? this.data.profiles.conscious
        : null;
    },
    consciousProfileCode() {
      return this.consciousProfile ? this.consciousProfile.code : "-";
    },
    consciousProfileId() {
      return this.consciousProfile ? this.consciousProfile.id : "-";
    },
    consciousProfileName() {
      return this.consciousProfile ? this.consciousProfile.name : "-";
    },
    consciousProfileOvalId() {
      return this.consciousProfile ? this.consciousProfile.ovalId : 0;
    },
    consciousProfilePhrases() {
      return this.consciousProfile ? this.consciousProfile.phrases : [];
    },
    consciousProfileWorkingEnvironment() {
      return this.consciousProfile
        ? this.consciousProfile.workingEnvironment
        : "-";
    },
    consciousStyleChartData() {
      const chartData = [
        ["Factor", "Consciente", { role: "style" }, "Umbral", { role: "style" }]
      ];
      this.formatData("conscious", chartData);
      return chartData;
    },
    consciousStyleChartOptions() {
      return {
        chartArea: {
          height: "90%",
          width: "90%"
        },
        hAxis: {
          textPosition: "out"
        },
        height: this.consciousStyleChartContainer.height,
        legend: { position: "none" },
        series: { 1: { type: "line" } },
        seriesType: "bars",
        vAxis: {
          maxValue: 100
        },
        width: this.consciousStyleChartContainer.width
      };
    },
    date() {
      if (this.data) {
        const date = new Date(this.data.submittedAt);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      } else {
        return "-";
      }
    },
    descriptors() {
      return this.data ? this.data.descriptors : [];
    },
    email() {
      return this.data ? this.data.applicantEmail : "-";
    },
    equalProfiles() {
      const consciousProfileId = this.consciousProfile
        ? this.consciousProfile.id
        : 0;
      const primaryProfileId = this.primaryProfile ? this.primaryProfile.id : 0;
      return consciousProfileId === primaryProfileId;
    },
    imageURIConsciousStyle() {
      return this.chartConsciousStyleReady && this.chartConsciousStyle
        ? this.chartConsciousStyle.getImageURI()
        : "";
    },
    imageURIPrimaryStyle() {
      return this.chartPrimaryStyleReady && this.chartPrimaryStyle
        ? this.chartPrimaryStyle.getImageURI()
        : "";
    },
    imageURIYourStyles() {
      return this.chartYourStylesReady && this.chartYourStyles
        ? this.chartYourStyles.getImageURI()
        : "";
    },
    isUserAuthenticated() {
      return this.$store.getters.isUserAuthenticated;
    },
    isXS() {
      return this.$vuetify.breakpoint.name == "xs";
    },
    metadataAsArray() {
      const res = [];
      for (let factor in this.metadata) {
        const item = this.metadata[factor];
        item.factor = factor;
        res.push(item);
      }
      return res;
    },
    name() {
      return this.data ? this.data.applicantName : "-";
    },
    primaryProfile() {
      return this.data && this.data.profiles && this.data.profiles.primary
        ? this.data.profiles.primary
        : null;
    },
    primaryProfileCode() {
      return this.primaryProfile ? this.primaryProfile.code : "-";
    },
    primaryProfileId() {
      return this.primaryProfile ? this.primaryProfile.id : "-";
    },
    primaryProfileName() {
      return this.primaryProfile ? this.primaryProfile.name : "-";
    },
    primaryProfileOvalId() {
      return this.primaryProfile ? this.primaryProfile.ovalId : 0;
    },
    primaryProfilePhrases() {
      return this.primaryProfile ? this.primaryProfile.phrases : [];
    },
    primaryProfileWorkEnvironment() {
      return this.primaryProfile ? this.primaryProfile.workEnvironment : "-";
    },
    primaryStyleChartData() {
      const chartData = [
        ["Factor", "Primario", { role: "style" }, "Umbral", { role: "style" }]
      ];
      this.formatData("primary", chartData);
      return chartData;
    },
    primaryStyleChartOptions() {
      return {
        chartArea: {
          height: "90%",
          width: "90%"
        },
        hAxis: {
          textPosition: "out"
        },
        height: this.primaryStyleChartContainer.height,
        legend: { position: "none" },
        series: { 1: { type: "line" } },
        seriesType: "bars",
        vAxis: {
          maxValue: 100
        },
        width: this.primaryStyleChartContainer.width
      };
    },
    yourStylesChartData() {
      let chartData = [
        [
          "Factor",
          "Primario",
          { role: "style" },
          "Consciente",
          { role: "style" },
          "Umbral",
          { role: "style" }
        ]
      ];
      if (this.data) {
        const primary = this.primaryProfile;
        const conscious = this.data.profiles.conscious;
        for (let factor in this.metadata) {
          chartData.push([
            this.metadata[factor].label,
            primary.values[factor].percentage,
            "stroke-color: #000000; stroke-width: 0.25; fill-color: " +
              this.metadata[factor].color,
            conscious.values[factor].percentage,
            "stroke-color: #000000; stroke-width: 0.25; fill-color: #999999",
            this.threshold.value,
            this.threshold.color
          ]);
        }
      }
      return chartData;
    },
    yourStylesChartOptions() {
      return {
        chartArea: {
          height: "90%",
          width: "90%"
        },
        hAxis: {
          textPosition: "out"
        },
        height: this.yourStylesChartContainer.height,
        legend: { position: "none" },
        series: { 2: { type: "line" } },
        seriesType: "bars",
        vAxis: {
          maxValue: 100
        },
        width: this.yourStylesChartContainer.width
      };
    }
  },
  created() {},
  data() {
    return {
      chartConsciousStyle: null,
      chartConsciousStyleReady: false,
      chartPrimaryStyle: null,
      chartPrimaryStyleReady: false,
      chartYourStyles: null,
      chartYourStylesReady: false,
      consciousStyleChartContainer: {
        height: null,
        width: null
      },
      data: null,
      metadata: {
        D: {
          color: "#ff0000",
          label: "Dominante",
          lightedColor: "#ff0000c0"
        },
        I: {
          color: "#ffff00",
          label: "Influyente",
          lightedColor: "#ffff00c0"
        },
        S: {
          color: "#00ff00",
          label: "Sostenedor",
          lightedColor: "#00ff00c0"
        },
        C: {
          color: "#0000ff",
          label: "Cauteloso",
          lightedColor: "#0000ffc0"
        }
      },
      primaryStyleChartContainer: {
        height: null,
        width: null
      },
      printing: false,
      profile: null,
      threshold: {
        color: "#000000",
        value: 50
      },
      reportId: null,
      yourStylesChartContainer: {
        height: null,
        width: null
      }
    };
  },
  methods: {
    calculateDescriptorHighlight(factor, percentage, opposite) {
      const descriptorGroup = this.descriptors.find(
        group => group.factor == factor
      );
      const descriptors = opposite
        ? descriptorGroup.oppositeDescriptors
        : descriptorGroup.descriptors;
      const nDescriptors = Math.ceil((descriptors.length * percentage) / 100);
      return (nDescriptors / descriptors.length) * 100;
    },
    formatData(profile, dataChart) {
      if (this.data) {
        const data = this.data.profiles[profile];
        for (let factor in this.metadata) {
          dataChart.push([
            this.metadata[factor].label,
            data.values[factor].percentage,
            "stroke-color: #000000; stroke-width: 0.25; fill-color: " +
              this.metadata[factor].color,
            this.threshold.value,
            this.threshold.color
          ]);
        }
      }
    },
    getDescriptorStyle(factor, opposite) {
      const data = this.primaryProfile.values[factor];
      const color = this.metadata[factor].lightedColor;
      if (data.percentage >= 50) {
        if (opposite == undefined || !opposite) {
          let value = (data.percentage - 50) * 2;
          value = this.calculateDescriptorHighlight(factor, value, opposite);
          const params = `to top, ${color} ${value}%, white ${value}%`;
          return `background: -webkit-linear-gradient(${params});background: -moz-linear-gradient(${params});background: -ms-linear-gradient(${params});background: linear-gradient(${params})`;
        } else {
          return "";
        }
      } else {
        if (opposite != undefined && opposite) {
          let value = (50 - data.percentage) * 2;
          value = this.calculateDescriptorHighlight(factor, value, opposite);
          const params = `to bottom, ${color} ${value}%, white ${value}%`;
          return `background: -webkit-linear-gradient(${params});background: -moz-linear-gradient(${params});background: -ms-linear-gradient(${params});background: linear-gradient(${params})`;
        } else {
          return "";
        }
      }
    },
    getFactorBarStyle(factor, profile) {
      const data = this.data.profiles[profile].values[factor];
      const color =
        profile == "primary" ? this.metadata[factor].lightedColor : "#999999c0";
      const value = (data.intensity / 7) * 100;
      const params = `to right, ${color} ${value}%, white ${value}%`;
      return `background: -webkit-linear-gradient(${params});background: -moz-linear-gradient(${params});background: -ms-linear-gradient(${params});background: linear-gradient(${params})`;
    },
    getReportData() {
      this.$store
        .dispatch("getReportData", this.reportId)
        .then(response => {
          this.data = response;
          console.log(response);
          this.$nextTick(() => {
            this.onResize();
          });
        })
        .catch(error => {
          console.log(error);
        });
    },
    initData() {
      this.reportId = this.$route.params.reportId;
    },
    initEvents() {
      const beforePrint = () => {
        this.printing = true;
        this.onResize();
      };
      const afterPrint = () => {
        this.printing = false;
      };
      window.addEventListener("resize", this.onResize);
      window.addEventListener("beforeprint", beforePrint);
      window.addEventListener("afterprint", afterPrint);
      if (window.matchMedia) {
        var mediaQueryList = window.matchMedia("print");
        mediaQueryList.addListener(mql => {
          if (mql.matches) {
            beforePrint();
          } else {
            afterPrint();
          }
        });
      }
    },
    onChartConsciousStyleReady(chart, google) {
      this.chartConsciousStyle = chart;
      google.visualization.events.addListener(chart, "ready", () => {
        this.chartConsciousStyleReady = true;
      });
    },
    onChartPrimaryStyleReady(chart, google) {
      this.chartPrimaryStyle = chart;
      google.visualization.events.addListener(chart, "ready", () => {
        this.chartPrimaryStyleReady = true;
      });
    },
    onChartYourStylesReady(chart, google) {
      this.chartYourStyles = chart;
      google.visualization.events.addListener(chart, "ready", () => {
        this.chartYourStylesReady = true;
      });
    },
    onPrint() {
      window.print();
    },
    onResize(event) {
      this.resizeCharts();
      this.resizeFactorsForce();
      this.$forceUpdate();
    },
    resizeChart(refName, dataName) {
      let width;
      const container = this.$refs[refName];
      if (container) {
        width = container.offsetWidth;
      } else {
        width = 500;
      }
      this[dataName].height = width * 0.5;
      this[dataName].width = width;
    },
    resizeCharts() {
      this.resizeChart(
        "your_styles_chart_container",
        "yourStylesChartContainer"
      );
      this.resizeChart(
        "primary_style_chart_container",
        "primaryStyleChartContainer"
      );
      this.resizeChart(
        "conscious_style_chart_container",
        "consciousStyleChartContainer"
      );
    },
    resizeFactorsForce() {
      const intensityLabelEl = this.$refs["intensity_label"];
      if (intensityLabelEl) {
        intensityLabelEl.forEach((intensityLabel, i) => {
          const width = intensityLabel.offsetWidth * 3;
          this.$refs[
            `factor_how_sometimes_${i}`
          ][0].style.minWidth = `${width}px`;
        });
      }
      this.$forceUpdate();
    }
  },
  mounted() {
    this.initData();
    this.initEvents();
    this.$nextTick(this.getReportData);
  }
});
</script>
<style>
html {
  /* overflow-y: hidden; */
  overflow-y: auto;
}

.align-center {
  align-self: center;
}

.centerGraphics {
  margin: auto;
  justify-content: center;
  display: flex;
  width: 50%;
}

.centerContainer {
  margin: auto;
  justify-content: center;
  display: flex;
  width: 90%;
}

.full-width {
  width: 100%;
}

.o-col-with-border {
  border: #ccc solid 1px;
}

.o-label {
  font-weight: bold;
}

/* .o-content-area {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 225px);
} */
.o-d-color {
  color: #ff0000;
}

.o-i-color {
  color: #ffff00;
}

.o-s-color {
  color: #00ff00;
}

.o-c-color {
  color: #0000ff;
}

.o-d-bgcolor {
  background-color: #ff0000;
}

.o-i-bgcolor {
  background-color: #ffff00;
}

.o-s-bgcolor {
  background-color: #00ff00;
}

.o-c-bgcolor {
  background-color: #0000ff;
}

.o-d-bgcolor-lighted {
  background-color: #ff0000c0;
}

.o-i-bgcolor-lighted {
  background-color: #ffff00c0;
}

.o-s-bgcolor-lighted {
  background-color: #00ff00c0;
}

.o-c-bgcolor-lighted {
  background-color: #0000ffc0;
}

.o-letter {
  font-weight: 900 !important;
  -webkit-text-stroke: 0.25px black;
}

.o-answer-space {
  padding-left: 10px;
  padding-bottom: 20px;
}

.o-answer-space div {
  margin-right: 10mm;
  border-bottom: 1px #ccc solid;
  width: 100%;
  display: inline-block;
  margin-top: 10px;
}

.o-descriptor-gradient {
  height: 100%;
}

.o-factor-force-bar {
  text-transform: uppercase;
  letter-spacing: 20px;
}

.o-yes-no-fill {
  border-bottom: 1px #ccc solid;
  width: 2cm !important;
  display: inline-block;
}

.o-table > .row > .col {
  border: 1px #ccc solid;
}

.o-descriptor-separator {
  border-bottom: 1px #606060 solid !important;
}

.o-plan-table > .row:nth-child(3) > .col,
.o-plan-table > .row:nth-child(4) > .col,
.o-plan-table > .row:nth-child(5) > .col {
  height: 7cm;
}

.only-border-bottom {
  border-bottom: 1px #ccc solid;
}

.only-border-x {
  border-left: 2px #ccc solid;
  border-right: 2px #ccc solid;
}

.o-oval-shape {
  width: 17.64px;
  height: 9.9px;
  border-radius: 50%;
  border-color: #000;
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  margin-right: 12px;
}

.o-background-black {
  background-color: #000;
}

.o-background-gray {
  background-color: #ccc;
}

@media only screen and (min-width: 600px) {
  .o-understand-last-row {
    border-bottom: rgba(0, 0, 0, 0.12) solid 1px;
  }

  .o-understand-row {
    border-top: rgba(0, 0, 0, 0.12) solid 1px;
  }

  .o-understand-col {
    border-left: rgba(0, 0, 0, 0.12) solid 1px;
  }

  .o-understand-last-col {
    border-right: rgba(0, 0, 0, 0.12) solid 1px;
  }

  .o-answer-space div {
    padding-bottom: 30px;
  }

  .o-yes-no-fill {
    padding-bottom: 0px !important;
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact !important;
    /* Para Webkit (Chrome, Safari) */
    print-color-adjust: exact !important;
    /* Para Firefox */
  }

  ul,
  li {
    break-inside: auto;
    page-break-inside: auto;
  }

  .page-break-before {
    break-before: always !important;
    page-break-before: always !important;
  }

  .col-sm-2 {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 16.6666666667% !important;
    flex: 0 0 16.6666666667% !important;
    max-width: 16.6666666667% !important;
  }

  .col-sm-3 {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 25% !important;
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }

  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66666666666667%;
    flex: 0 0 41.66666666666667%;
    max-width: 41.66666666666667%;
  }

  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-12 {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .o-understand-row {
    border-top: rgba(0, 0, 0, 0.12) solid 1px;
    border-bottom: rgba(0, 0, 0, 0.12) solid 1px;
    break-inside: avoid;
    page-break-inside: avoid;
  }

  .o-understand-col {
    border-left: rgba(0, 0, 0, 0.12) solid 1px;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    padding-left: 12 px !important;
    padding-right: 12 px !important;
  }

  .o-understand-last-col {
    border-right: rgba(0, 0, 0, 0.12) solid 1px;
  }

  .o-descriptor-gradient {
    margin: 1px;
  }

  .o-answer-space div {
    margin-bottom: 30px;
  }

  .print-width {
    width: 1100px;
  }

  #introduction,
  #your_styles,
  #work_environment {
    margin-top: 230px !important;
    margin-bottom: 20px !important;
  }

  #content-area {
    margin-top: 50px !important;
  }

  #primary_style,
  #conscious_style,
  #understanding_others,
  #primary_style_descriptors,
  #factors_force,
  #personal_plan {
    margin-bottom: 20px !important;
  }
}

/* @page:right {
  @bottom-right {
    content: counter(page);
  }
} */
#report-footer {
  display: table;
}

#report-footer-cell {
  display: table-footer-group;
}

#report-footer-cell {
  counter-increment: page;
  content: counter(page);
}
</style>
