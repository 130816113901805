export default {
  computed: {
    isUserAuthenticated() {
      return this.$store.getters.isUserAuthenticated;
    }
  },
  watch: {
    isUserAuthenticated(value) {
      if (value) {
        this.$router.push("/");
      }
    }
  }
};
