<template>
  <v-row
    class="ma-0 pt-4"
    :class="{ 'page-break-before': hasPageBreak }"
    :id="id"
  >
    <v-col class="text-h4 text-center px-0 pt-6 pb-4">
      <slot></slot>
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    id: String,
    hasPageBreak: {
      default: false,
      type: Boolean
    }
  },
  setup(props) {}
});
</script>
