<template>
  <svg class="ovalo" width="100%" viewBox="0 0 2253.89 1330.33">
    <defs>
      <clipPath id="shape-3-clip-path" transform="translate(110.37 -360)">
        <ellipse class="fill-none" cx="1018" cy="1024" rx="899" ry="520" />
      </clipPath>
      <linearGradient
        id="shape-13-gradient"
        x1="378.37"
        y1="661.5"
        x2="2043.37"
        y2="661.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.03" stop-color="#0817f5" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="shape-14-gradient"
        x1="1366.5"
        y1="917.5"
        x2="1871.19"
        y2="917.5"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-15-gradient"
        x1="170.5"
        y1="1129.5"
        x2="672.19"
        y2="1129.5"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-16-gradient"
        x1="1087.62"
        y1="672.5"
        x2="1200.38"
        y2="672.5"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-17-gradient"
        x1="832.62"
        y1="1373.5"
        x2="945.38"
        y2="1373.5"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-18-gradient"
        x1="830.62"
        y1="672.5"
        x2="943.38"
        y2="672.5"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-19-gradient"
        x1="1083.63"
        y1="1375.5"
        x2="1200.37"
        y2="1375.5"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-20-gradient"
        x1="164.5"
        y1="919"
        x2="670.19"
        y2="919"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-21-gradient"
        x1="1366.5"
        y1="1130"
        x2="1871.21"
        y2="1130"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-22-gradient"
        x1="675.35"
        y1="1036.81"
        x2="1357.82"
        y2="1010.72"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-23-gradient"
        x1="479.5"
        y1="1025"
        x2="1553.5"
        y2="1025"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-24-gradient"
        x1="1016.72"
        y1="1022.75"
        x2="1020.72"
        y2="1022.75"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-25-gradient"
        x1="1322.88"
        y1="877.31"
        x2="1517.38"
        y2="877.31"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-26-gradient"
        x1="1264.27"
        y1="826.5"
        x2="1568.23"
        y2="761.44"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-27-gradient"
        x1="471.75"
        y1="1283.64"
        x2="778.91"
        y2="1217.14"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-28-gradient"
        x1="1152.94"
        y1="765.44"
        x2="1363.82"
        y2="706.25"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-29-gradient"
        x1="668.99"
        y1="1339.21"
        x2="886.09"
        y2="1278.69"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-30-gradient"
        x1="1126.54"
        y1="768.07"
        x2="1201.54"
        y2="768.07"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-31-gradient"
        x1="832.77"
        y1="1277.18"
        x2="908.11"
        y2="1277.18"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-32-gradient"
        x1="1049.84"
        y1="756.14"
        x2="1076.14"
        y2="756.14"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-33-gradient"
        x1="954.53"
        y1="1291.72"
        x2="981.13"
        y2="1291.72"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-34-gradient"
        x1="954.85"
        y1="756.17"
        x2="982.11"
        y2="756.17"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-35-gradient"
        x1="1049.47"
        y1="1291.2"
        x2="1076.47"
        y2="1291.2"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-36-gradient"
        x1="829.55"
        y1="768.09"
        x2="906.01"
        y2="768.09"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-37-gradient"
        x1="1125.7"
        y1="1279.21"
        x2="1201.23"
        y2="1279.21"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-38-gradient"
        x1="668.99"
        y1="709.73"
        x2="882.83"
        y2="767.58"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-39-gradient"
        x1="1148.5"
        y1="1280.27"
        x2="1364.89"
        y2="1342.98"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-40-gradient"
        x1="683.94"
        y1="801.41"
        x2="815.76"
        y2="801.41"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-41-gradient"
        x1="1216.14"
        y1="1247.71"
        x2="1346.8"
        y2="1247.71"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-42-gradient"
        x1="520.42"
        y1="879.49"
        x2="714.24"
        y2="879.49"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-43-gradient"
        x1="1321.86"
        y1="1171.53"
        x2="1517.54"
        y2="1171.53"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-44-gradient"
        x1="1014.88"
        y1="1023.83"
        x2="1018.88"
        y2="1023.83"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-45-gradient"
        x1="525.81"
        y1="1167.5"
        x2="719.19"
        y2="1167.5"
        xlink:href="#shape-13-gradient"
      />
      <linearGradient
        id="shape-46-gradient"
        x1="1013.5"
        y1="1024.5"
        x2="1017.5"
        y2="1024.5"
        xlink:href="#shape-13-gradient"
      />
      <clipPath id="shape-47-clip-path" transform="translate(110.37 -360)">
        <rect class="fill-none" x="-56" y="1025" width="2119" height="586" />
      </clipPath>
      <linearGradient
        id="shape-48-gradient"
        x1="114.78"
        y1="1024"
        x2="1924.22"
        y2="1024"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#7bfa4c" />
        <stop offset="1" stop-color="#fffe54" />
      </linearGradient>
      <clipPath id="shape-49-clip-path" transform="translate(126.37 -363)">
        <rect class="fill-none" x="-59" y="440" width="2119" height="586" />
      </clipPath>
      <linearGradient
        id="shape-50-gradient"
        x1="114.78"
        y1="1024"
        x2="1924.22"
        y2="1024"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.03" stop-color="#0817f5" />
        <stop offset="1" stop-color="#e73322" />
      </linearGradient>
      <linearGradient
        id="shape-51-gradient"
        x1="130.9"
        y1="959.91"
        x2="130.9"
        y2="1069.84"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.03" stop-color="#0817f5" />
        <stop offset="1" stop-color="#7bfa4c" />
      </linearGradient>
      <linearGradient
        id="shape-52-gradient"
        x1="1840.21"
        y1="962.23"
        x2="1840.21"
        y2="1072.16"
        gradientTransform="matrix(-1, 0, 0, 1, 3748.55, 0)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.03" stop-color="#e43424" />
        <stop offset="1" stop-color="#fefe54" />
      </linearGradient>
      <linearGradient
        id="white-circle-line-gradient"
        x1="1123.87"
        y1="662.68"
        x2="1127.87"
        y2="662.68"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.03" stop-color="#0817f5" />
        <stop offset="1" stop-color="#fff" />
      </linearGradient>
    </defs>
    <g id="Capa_2" data-name="Capa 2">
      <g class="opacity-0_4">
        <g class="shape-3">
          <rect
            class="fill-blue"
            x="221.44"
            y="112"
            width="907.5"
            height="553"
          />
          <rect class="fill-red" x="1128.37" y="112" width="901" height="553" />
          <rect
            class="fill-green"
            x="221.37"
            y="664"
            width="907.5"
            height="562"
          />
          <rect
            class="fill-yellow"
            x="1129.37"
            y="665"
            width="892"
            height="561"
          />
        </g>
      </g>
    </g>
    <g id="detalles">
      <path
        id="main_zones"
        data-name="main zones"
        class="fill-white opacity-0_5"
        d="M1198,1527s-102,2-180,2c-83,0-183-7-183-7l185-497L833,521h185c84,0,180,1,180,1l-180,501Z"
        transform="translate(110.37 -360)"
      />
      <path
        id="main_zones_2"
        data-name="main zones 2"
        class="fill-white opacity-0_5"
        d="M1871,1176l-852.5-152.5L171,1174s-32.32-46.49-33.5-149.5C136.5,937.5,165,875,165,875l853.5,148.5L1871,874s48.83,85.59,45.5,150.5C1912.5,1102.5,1871,1176,1871,1176Z"
        transform="translate(110.37 -360)"
      />
      <g id="mini_ovals" data-name="mini ovals" class="opacity-0_1">
        <ellipse
          class="fill-white stroke-miterlimit-10 stroke-white-9px"
          cx="1130.87"
          cy="664"
          rx="881.5"
          ry="495"
        />
        <g id="mini_ovals-2" data-name="mini ovals">
          <ellipse
            class="stroke-miterlimit-10 stroke-white-9px"
            cx="1130.87"
            cy="664"
            rx="774.65"
            ry="435"
          />
        </g>
        <g id="mini_ovals-3" data-name="mini ovals">
          <ellipse
            class="fill-white stroke-miterlimit-10 stroke-white-9px"
            cx="1130.87"
            cy="664"
            rx="585.5"
            ry="328.78"
          />
        </g>
        <g id="mini_ovals-4" data-name="mini ovals">
          <ellipse
            class="stroke-miterlimit-10 stroke-white-9px"
            cx="1130.87"
            cy="664"
            rx="369.5"
            ry="207.49"
          />
        </g>
      </g>
    </g>
    <g id="Capa_13" data-name="Capa 13" class="opacity-0_4">
      <g class="opacity-0_3">
        <polygon
          class="shape-13"
          points="362.37 664.5 570.5 663.52 778.62 662.97 1194.87 662.5 1611.12 662.96 1819.25 663.52 2027.37 664.5 1819.25 665.48 1611.12 666.04 1194.87 666.5 778.62 666.03 570.5 665.48 362.37 664.5"
        />
        <path
          class="shape-14"
          d="M1366.5,961.5l15.7-3,15.71-3,31.42-5.94L1492.24,938c41.93-7.69,83.93-15.08,125.92-22.46l63-10.9c21-3.54,42-7.2,63.07-10.65l63.09-10.45,31.58-5L1854.7,876l15.8-2.45-15.7,3-15.71,3-31.42,6L1744.76,897c-21,3.89-42,7.56-62.93,11.35l-63,11.1c-42,7.28-84,14.57-126.08,21.54l-63.09,10.45-31.58,5.06-15.79,2.53Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-15"
          d="M170.5,1173.5l15.6-3,15.62-3,31.24-5.94L295.48,1150c41.69-7.69,83.43-15.08,125.17-22.46l62.65-10.9c20.89-3.54,41.77-7.2,62.68-10.65l62.73-10.45,31.39-5,15.69-2.53,15.71-2.45-15.6,3-15.62,3-31.24,6L546.52,1109c-20.84,3.89-41.71,7.56-62.57,11.35l-62.6,11.1C379.59,1138.75,337.83,1146,296,1153l-62.73,10.45-31.39,5.06-15.69,2.53Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-16"
          d="M1089.5,822.5l3.12-9.48,3.2-9.45,6.39-18.9q6.48-18.87,13.08-37.7c4.37-12.56,8.87-25.08,13.31-37.61l13.52-37.54,13.73-37.47c4.64-12.46,9.22-25,13.93-37.38s9.41-24.88,14.17-37.3l7.24-18.6,3.62-9.3,3.69-9.27-3.12,9.48-3.19,9.45-6.39,18.9q-6.49,18.87-13.08,37.7c-4.37,12.57-8.88,25.08-13.32,37.62l-13.52,37.53-13.73,37.46c-4.64,12.47-9.22,25-13.94,37.39s-9.41,24.88-14.17,37.3l-7.23,18.6-3.62,9.3Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-17"
          d="M834.5,1521.5l3.12-9.35,3.2-9.33,6.39-18.66q6.48-18.62,13.08-37.2c4.37-12.39,8.87-24.74,13.31-37.11l13.52-37,13.73-37c4.64-12.3,9.22-24.62,13.94-36.89s9.4-24.55,14.17-36.8l7.23-18.34,3.62-9.17,3.69-9.15-3.12,9.35-3.19,9.33-6.4,18.66q-6.48,18.62-13.08,37.2c-4.36,12.4-8.87,24.74-13.31,37.11l-13.52,37-13.73,37c-4.65,12.3-9.23,24.62-13.94,36.89s-9.41,24.54-14.17,36.8l-7.23,18.34-3.62,9.17Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-18"
          d="M941.5,823.5l-3.69-9.34-3.62-9.36L927,786.08q-7.14-18.75-14.17-37.55c-4.72-12.52-9.3-25.09-13.94-37.64l-13.73-37.71-13.53-37.79c-4.43-12.62-8.94-25.21-13.31-37.86s-8.76-25.28-13.08-37.95l-6.39-19L835.62,531l-3.12-9.54,3.69,9.33,3.62,9.37,7.24,18.72q7.14,18.75,14.17,37.55c4.71,12.52,9.29,25.09,13.94,37.64l13.72,37.71,13.52,37.79c4.44,12.62,8.94,25.22,13.31,37.86s8.76,25.29,13.08,37.95l6.39,19,3.2,9.51Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-19"
          d="M1198.5,1526.5l-3.81-9.33-3.75-9.36-7.48-18.72q-7.39-18.75-14.67-37.55c-4.88-12.51-9.62-25.08-14.43-37.63l-14.23-37.71-14-37.79c-4.6-12.62-9.28-25.21-13.81-37.86s-9.09-25.29-13.58-38l-6.64-19-3.32-9.52-3.25-9.54,3.81,9.33,3.75,9.36,7.48,18.72q7.39,18.75,14.67,37.54c4.88,12.52,9.63,25.09,14.44,37.64l14.22,37.71,14,37.79c4.61,12.62,9.28,25.22,13.82,37.87s9.09,25.28,13.58,38l6.64,19,3.32,9.52Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-20"
          d="M669.5,963.5,653.67,961l-15.82-2.55-31.64-5.13L543,942.76c-42.14-7.06-84.23-14.42-126.33-21.79l-63.1-11.23c-21-3.83-42.06-7.54-63.07-11.47l-63-11.68-31.48-6-15.74-3L164.5,874.5,180.33,877l15.82,2.56,31.65,5.12L291,895.23c21.08,3.49,42.12,7.19,63.18,10.78l63.15,11c42.07,7.47,84.15,14.94,126.17,22.71l63,11.68,31.49,6,15.74,3Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-21"
          d="M1870.5,1175.5l-15.8-2.55-15.79-2.62-31.58-5.25-63.1-10.82c-42.07-7.23-84.08-14.76-126.08-22.29l-63-11.48c-21-3.91-42-7.71-62.93-11.73l-62.9-11.92-31.42-6.13-15.71-3.07-15.7-3.14,15.8,2.55,15.79,2.62,31.58,5.24,63.1,10.83c21,3.56,42,7.35,63.07,11l63,11.27c42,7.64,84,15.27,125.92,23.21l62.9,11.93,31.42,6.12,15.71,3.07Z"
          transform="translate(110.37 -360)"
        />
      </g>
      <g class="opacity-0_3">
        <path
          class="shape-22"
          d="M686.67,1300.8l40.9-35,40.95-35,82.12-69.69c27.34-23.27,54.86-46.32,82.3-69.47l82.36-69.4,82.59-69.12,41.3-34.56,20.65-17.28,20.71-17.21,82.84-68.83L1304.92,781l20.76-17.14,20.82-17.08-20.43,17.54-20.47,17.49-40.95,35-82.12,69.68L1162,903.85l-20.59,17.36-41.18,34.7-82.36,69.39-82.6,69.12c-27.55,23-55,46.14-82.66,69l-82.83,68.83-41.53,34.29Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-23"
          d="M1551.5,1334.5l-67.26-38.23L1417,1258l-134.28-76.89c-44.78-25.59-89.43-51.42-134.14-77.13l-134.09-77.22L746.73,871.58,613,793.72l-66.78-39.07L479.5,715.5l67.27,38.23L614,792l134.28,76.89,268.23,154.35,133.91,77.53c44.62,25.87,89.31,51.62,133.86,77.62L1418,1256.28l66.77,39.08Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-24"
          d="M1018.72,950.37c.82,12.06,1.26,24.12,1.55,36.19.19,6,.23,12.06.33,18.09l.12,18.1-.11,18.09c-.11,6-.15,12.06-.33,18.09-.3,12.07-.73,24.13-1.56,36.19-.84-12.06-1.27-24.12-1.56-36.19-.19-6-.23-12.06-.34-18.09l-.1-18.09.11-18.1c.11-6,.15-12.06.33-18.09C1017.46,974.49,1017.89,962.43,1018.72,950.37Z"
          transform="translate(110.37 -360)"
        />
      </g>
      <g class="opacity-0_3">
        <path
          class="shape-25"
          d="M1323.56,912.45c15.82-6.63,31.76-12.9,47.76-19,8-3.1,16-6.07,24-9.1l24.11-8.89,24.18-8.68c8.08-2.83,16.14-5.72,24.25-8.48,16.2-5.58,32.44-11,48.82-16.1-15.81,6.64-31.76,12.9-47.75,19-8,3.1-16,6.06-24,9.09l-24.11,8.89-24.17,8.68c-8.09,2.83-16.15,5.72-24.26,8.47C1356.19,901.92,1339.94,907.37,1323.56,912.45Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="fill-none"
          d="M526.5,1202.5"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-26"
          d="M1275.34,875.3,1284,870l8.69-5.28,17.39-10.55q17.45-10.47,34.94-20.83c11.65-6.94,23.37-13.75,35.06-20.62l35.17-20.43L1450.53,772c11.8-6.68,23.56-13.43,35.39-20s23.66-13.26,35.52-19.83l17.84-9.78,8.92-4.88,9-4.82L1548.5,718l-8.69,5.28-17.39,10.56q-17.45,10.47-34.94,20.83c-11.65,6.94-23.37,13.75-35.06,20.62l-35.17,20.42L1382,815.94c-11.8,6.68-23.56,13.42-35.4,20s-23.65,13.26-35.51,19.83l-17.84,9.78-8.92,4.88Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-27"
          d="M483,1332.55l8.74-5.39,8.79-5.33,17.57-10.66q17.63-10.58,35.3-21.05c11.77-7,23.62-13.89,35.43-20.83l35.53-20.63L660,1228.2c11.92-6.75,23.8-13.56,35.75-20.25s23.9-13.39,35.89-20l18-9.88,9-4.94,9.05-4.87-8.75,5.4-8.78,5.33-17.57,10.66q-17.62,10.58-35.31,21c-11.77,7-23.61,13.89-35.42,20.83l-35.54,20.64-35.65,20.44c-11.91,6.76-23.8,13.57-35.75,20.26s-23.9,13.39-35.89,20l-18,9.88-9,4.94Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-28"
          d="M1174,836.38l5-6.47,5.1-6.43,10.2-12.86q10.28-12.79,20.65-25.5c6.89-8.5,13.88-16.91,20.83-25.35l21-25.21L1278,709.49c7.11-8.3,14.17-16.66,21.34-24.92s14.32-16.53,21.53-24.76l10.89-12.28,5.45-6.14,5.5-6.09-5,6.48-5.1,6.43-10.19,12.86q-10.29,12.79-20.65,25.51c-6.89,8.5-13.89,16.9-20.83,25.35l-21,25.2-21.18,25.06c-7.11,8.31-14.17,16.66-21.34,24.92s-14.32,16.54-21.53,24.77L1185,824.16l-5.45,6.13Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-29"
          d="M690.61,1412.56l5.2-6.67,5.26-6.62L711.6,1386q10.59-13.17,21.28-26.27c7.11-8.76,14.32-17.42,21.48-26.12l21.65-26,21.81-25.84c7.33-8.56,14.6-17.17,22-25.69s14.75-17,22.18-25.52L853.2,1218l5.61-6.33,5.66-6.28-5.2,6.67-5.26,6.63-10.52,13.24q-10.61,13.17-21.29,26.27c-7.1,8.75-14.32,17.41-21.48,26.12l-21.65,26-21.82,25.83c-7.32,8.56-14.6,17.17-22,25.68s-14.76,17-22.18,25.53L701.88,1400l-5.61,6.33Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-30"
          d="M1128.27,830.1c5.24-10.75,10.83-21.31,16.54-31.79,2.82-5.26,5.76-10.45,8.65-15.67l8.85-15.57,9-15.45c3.08-5.11,6.09-10.26,9.23-15.34,6.22-10.19,12.56-20.31,19.24-30.23-5.24,10.75-10.82,21.3-16.53,31.79-2.82,5.26-5.77,10.45-8.66,15.67l-8.85,15.56-9,15.45c-3.07,5.12-6.09,10.27-9.23,15.34C1141.29,810.05,1135,820.18,1128.27,830.1Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-31"
          d="M834.5,1339.5c5.27-10.8,10.89-21.4,16.62-31.94,2.84-5.28,5.8-10.49,8.7-15.74l8.89-15.64,9.07-15.52c3.09-5.14,6.12-10.32,9.28-15.42,6.24-10.24,12.61-20.41,19.32-30.38-5.27,10.81-10.88,21.41-16.62,31.94-2.83,5.29-5.8,10.5-8.7,15.75l-8.89,15.63-9.08,15.52c-3.09,5.14-6.12,10.32-9.27,15.42C847.57,1319.36,841.21,1329.53,834.5,1339.5Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-32"
          d="M1051.81,819.07c1-10.64,2.48-21.2,4-31.74.76-5.28,1.65-10.53,2.47-15.79l2.69-15.75,2.9-15.72c1-5.22,2-10.46,3.12-15.67,2.15-10.44,4.44-20.85,7.13-31.19-1.05,10.63-2.48,21.19-4.06,31.73-.75,5.28-1.64,10.53-2.46,15.79l-2.7,15.75-2.9,15.72c-1,5.22-2,10.46-3.12,15.67C1056.77,798.31,1054.48,808.72,1051.81,819.07Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-33"
          d="M956.5,1355.5c1.07-10.77,2.54-21.48,4.13-32.16.77-5.35,1.67-10.67,2.51-16l2.72-16,2.94-15.92c1.05-5.3,2-10.61,3.16-15.89,2.18-10.58,4.49-21.13,7.2-31.61-1.07,10.77-2.53,21.48-4.13,32.16-.76,5.35-1.67,10.67-2.5,16l-2.73,16L966.86,1308c-1.05,5.29-2,10.6-3.16,15.88C961.52,1334.46,959.2,1345,956.5,1355.5Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-34"
          d="M980.14,822.18c-2.76-10.85-5.13-21.78-7.36-32.73-1.16-5.47-2.17-11-3.25-16.45l-3-16.48L963.7,740c-.86-5.52-1.8-11-2.59-16.56-1.65-11-3.17-22.13-4.29-33.28,2.76,10.86,5.13,21.79,7.36,32.74,1.16,5.47,2.17,11,3.25,16.44l3,16.49,2.81,16.52c.87,5.52,1.8,11,2.59,16.57C977.49,800,979,811,980.14,822.18Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-35"
          d="M1074.5,1356.5c-2.74-10.74-5.09-21.55-7.3-32.38-1.14-5.41-2.14-10.84-3.21-16.27l-3-16.3-2.78-16.34c-.85-5.46-1.78-10.91-2.56-16.39-1.63-10.93-3.12-21.89-4.22-32.92,2.74,10.74,5.09,21.55,7.3,32.38,1.14,5.41,2.14,10.85,3.21,16.27l3,16.3,2.77,16.35c.85,5.46,1.78,10.91,2.56,16.38C1071.9,1334.51,1073.39,1345.47,1074.5,1356.5Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-36"
          d="M904.28,831.18c-6.8-10.1-13.26-20.4-19.59-30.77-3.21-5.16-6.28-10.4-9.42-15.61l-9.22-15.71-9-15.83c-2.95-5.31-6-10.59-8.84-15.94-5.83-10.66-11.54-21.4-16.9-32.33,6.81,10.1,13.27,20.4,19.6,30.77,3.21,5.16,6.28,10.4,9.42,15.6l9.21,15.72,9,15.83c3,5.32,6,10.59,8.84,15.94C893.21,809.52,898.91,820.25,904.28,831.18Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-37"
          d="M1199.5,1341.5c-6.72-10-13.1-20.13-19.36-30.37-3.16-5.09-6.2-10.26-9.3-15.4l-9.1-15.52-8.92-15.62c-2.91-5.25-5.88-10.46-8.72-15.74-5.75-10.53-11.38-21.13-16.67-31.93,6.73,10,13.11,20.13,19.37,30.37,3.16,5.09,6.2,10.27,9.3,15.4l9.1,15.52,8.91,15.63c2.91,5.24,5.88,10.45,8.72,15.74C1188.58,1320.11,1194.21,1330.7,1199.5,1341.5Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-38"
          d="M861.78,841l-5.59-6.2-5.54-6.25L839.57,816q-11-12.57-21.91-25.22c-7.3-8.41-14.49-16.91-21.73-25.37l-21.55-25.51L753,714.29c-7.07-8.6-14.2-17.16-21.21-25.8s-14-17.28-21-26l-10.39-13.08-5.19-6.55L690,636.31l5.6,6.2,5.54,6.25,11.08,12.51q11,12.57,21.92,25.21c7.3,8.41,14.48,16.91,21.72,25.37l21.55,25.52L798.82,763c7.08,8.6,14.2,17.16,21.22,25.8s14.05,17.28,21,26l10.39,13.08,5.2,6.55Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-39"
          d="M1343,1414.49l-5.62-6.24-5.57-6.28-11.14-12.57q-11.06-12.63-22-25.34c-7.33-8.46-14.56-17-21.83-25.51l-21.67-25.64-21.49-25.78c-7.11-8.65-14.28-17.24-21.33-25.93s-14.12-17.37-21.13-26.09L1180.76,1222l-5.22-6.58-5.16-6.62L1176,1215l5.57,6.29,11.13,12.57q11.05,12.63,22,25.34c7.34,8.45,14.56,17,21.84,25.5l21.66,25.65,21.49,25.79c7.11,8.64,14.27,17.24,21.32,25.93s14.12,17.36,21.14,26.09l10.44,13.14,5.23,6.58Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-40"
          d="M814.47,855.66c-11.3-8.41-22.35-17.12-33.31-25.93-5.5-4.38-10.91-8.87-16.37-13.31l-16.23-13.48L732.48,789.3c-5.32-4.61-10.68-9.16-16-13.82-10.58-9.27-21.07-18.64-31.3-28.32,11.3,8.4,22.35,17.11,33.31,25.93,5.5,4.38,10.91,8.87,16.37,13.31l16.22,13.48,16.09,13.65c5.31,4.6,10.67,9.15,15.94,13.81C793.74,836.61,804.23,846,814.47,855.66Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-41"
          d="M1345.52,1301.48c-11.21-8.33-22.16-17-33-25.69-5.45-4.34-10.82-8.79-16.22-13.19l-16.09-13.36-15.94-13.52c-5.27-4.57-10.58-9.07-15.8-13.7-10.48-9.18-20.88-18.48-31-28.08,11.21,8.33,22.17,17,33,25.69,5.46,4.34,10.82,8.79,16.23,13.19l16.07,13.36,16,13.53c5.26,4.56,10.57,9.07,15.79,13.69C1325,1282.59,1335.37,1291.88,1345.52,1301.48Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-42"
          d="M713.56,914.51c-16.33-5.06-32.51-10.49-48.65-16-8.08-2.75-16.12-5.63-24.17-8.44l-24.1-8.66-24-8.85c-8-3-16-6-23.94-9.07-15.94-6.11-31.83-12.36-47.58-19,16.32,5.06,32.51,10.49,48.65,16,8.08,2.74,16.11,5.63,24.17,8.44L618,877.61l24,8.86c8,3,16,6,23.94,9.07C681.91,901.65,697.8,907.89,713.56,914.51Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-43"
          d="M1516.86,1206.89c-16.48-5.12-32.82-10.6-49.11-16.22-8.16-2.78-16.27-5.68-24.4-8.53L1419,1173.4l-24.25-8.93c-8.06-3.06-16.15-6-24.18-9.16-16.09-6.17-32.14-12.47-48-19.15,16.47,5.12,32.81,10.6,49.11,16.22,8.16,2.77,16.27,5.68,24.4,8.53l24.33,8.74,24.25,8.94c8.06,3.05,16.14,6,24.17,9.16C1484.91,1193.92,1501,1200.22,1516.86,1206.89Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-44"
          d="M1016.88,996.31a126.27,126.27,0,0,1,1.57,13.76q.43,6.88.43,13.76t-.43,13.76a123.57,123.57,0,0,1-1.57,13.76,121.25,121.25,0,0,1-1.57-13.76c-.3-4.58-.43-9.17-.43-13.76s.14-9.17.43-13.76A123.84,123.84,0,0,1,1016.88,996.31Z"
          transform="translate(110.37 -360)"
        />
        <path
          class="shape-45"
          d="M526.5,1202.5c15.72-6.61,31.57-12.85,47.47-19,7.93-3.09,15.92-6,23.88-9.06l24-8.86,24-8.65c8-2.81,16-5.69,24.12-8.44,16.1-5.55,32.24-11,48.53-16-15.71,6.62-31.57,12.86-47.47,19-7.93,3.09-15.92,6-23.88,9.06l-24,8.85-24,8.64c-8,2.82-16,5.7-24.12,8.44C558.93,1192,542.78,1197.44,526.5,1202.5Z"
          transform="translate(110.37 -360)"
        />
      </g>
      <g class="opacity-0_3">
        <path
          class="shape-46"
          d="M1015.5,1455l-.53-53.81-.44-53.81-.56-107.63c-.24-35.87-.2-71.75-.29-107.63l-.18-107.62.18-107.62.09-53.82.05-26.9.14-26.91.56-107.63.45-53.81.23-26.9.3-26.91.3,26.91.23,26.9.45,53.81.56,107.63.14,26.91.05,26.9.09,53.82.18,107.62-.18,107.62c-.09,35.88-.05,71.76-.29,107.63l-.56,107.63-.44,53.81Z"
          transform="translate(110.37 -360)"
        />
      </g>
    </g>
    <g id="bordes">
      <g class="shape-47">
        <path
          class="shape-48"
          d="M1019.5,529c120.15,0,236.65,13.51,346.25,40.16a1201.62,1201.62,0,0,1,150.54,47A960.54,960.54,0,0,1,1646.78,678c39.61,22.77,75.52,47.78,106.73,74.32s58,54.85,79.48,84.11c44,59.72,66.23,122.84,66.23,187.59s-22.28,127.87-66.23,187.59c-21.53,29.26-48.28,57.56-79.48,84.11s-67.12,51.55-106.73,74.32a960.54,960.54,0,0,1-130.49,61.87,1201.62,1201.62,0,0,1-150.54,46.95c-109.6,26.65-226.1,40.16-346.25,40.16s-236.65-13.51-346.25-40.16a1201.62,1201.62,0,0,1-150.54-46.95A960.54,960.54,0,0,1,392.22,1370c-39.61-22.77-75.52-47.78-106.73-74.32s-58-54.85-79.48-84.11c-43.95-59.72-66.23-122.84-66.23-187.59S162.06,896.13,206,836.41c21.53-29.26,48.28-57.56,79.48-84.11S352.61,700.75,392.22,678a960.54,960.54,0,0,1,130.49-61.87,1201.62,1201.62,0,0,1,150.54-47C782.85,542.51,899.35,529,1019.5,529m0-25c-499.66,0-904.72,232.81-904.72,520s405.06,520,904.72,520,904.72-232.81,904.72-520S1519.16,504,1019.5,504Z"
          transform="translate(110.37 -360)"
        />
      </g>
      <g class="shape-49">
        <path
          class="shape-50"
          d="M1019.5,529c120.15,0,236.65,13.51,346.25,40.16a1201.62,1201.62,0,0,1,150.54,47A960.54,960.54,0,0,1,1646.78,678c39.61,22.77,75.52,47.78,106.73,74.32s58,54.85,79.48,84.11c44,59.72,66.23,122.84,66.23,187.59s-22.28,127.87-66.23,187.59c-21.53,29.26-48.28,57.56-79.48,84.11s-67.12,51.55-106.73,74.32a960.54,960.54,0,0,1-130.49,61.87,1201.62,1201.62,0,0,1-150.54,46.95c-109.6,26.65-226.1,40.16-346.25,40.16s-236.65-13.51-346.25-40.16a1201.62,1201.62,0,0,1-150.54-46.95A960.54,960.54,0,0,1,392.22,1370c-39.61-22.77-75.52-47.78-106.73-74.32s-58-54.85-79.48-84.11c-43.95-59.72-66.23-122.84-66.23-187.59S162.06,896.13,206,836.41c21.53-29.26,48.28-57.56,79.48-84.11S352.61,700.75,392.22,678a960.54,960.54,0,0,1,130.49-61.87,1201.62,1201.62,0,0,1,150.54-47C782.85,542.51,899.35,529,1019.5,529m0-25c-499.66,0-904.72,232.81-904.72,520s405.06,520,904.72,520,904.72-232.81,904.72-520S1519.16,504,1019.5,504Z"
          transform="translate(110.37 -360)"
        />
      </g>
      <path
        class="shape-51"
        d="M143,1069.4l-25,.44s-2.49-28.32-3.1-43.8c-1.11-27.87,6.86-66.13,6.86-66.13l25.21.44s-8.18,37.16-7.3,65.69C140,1036.91,143,1069.4,143,1069.4Z"
        transform="translate(110.37 -360)"
      />
      <path
        class="shape-52"
        d="M1896.23,1071.72l25,.44s2.48-28.32,3.09-43.79c1.11-27.87-6.85-66.14-6.85-66.14l-25.22.44s8.18,37.16,7.3,65.7C1899.21,1039.23,1896.23,1071.72,1896.23,1071.72Z"
        transform="translate(110.37 -360)"
      />
    </g>
    <g id="white_circle" data-name="white circle">
      <ellipse
        class="fill-white"
        cx="1129.09"
        cy="662.75"
        rx="125.72"
        ry="72.38"
      />
      <line
        class="white-circle-line stroke-miterlimit-10 fill-none"
        x1="1125.87"
        y1="590.36"
        x2="1125.87"
        y2="735"
      />
    </g>
    <g id="textos">
      <rect
        class="fill-none"
        x="1003.25"
        y="590.75"
        width="125.5"
        height="145"
      />

      <rect class="fill-none" x="1129.75" y="591" width="126.5" height="146" />
      <!-- Realizador -->
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(1026.04, 117.23) rotate(-3.23)"
      >
        R
      </text>
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="matrix(1, -0.04, 0.04, 1, 1051.16, 115.81)"
      >
        e
      </text>
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(1075.06, 114.83) rotate(-1.42)"
      >
        a
      </text>
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="matrix(1, -0.01, 0.01, 1, 1101.96, 114.21)"
      >
        l
      </text>
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(1113.93, 114.08) rotate(-0.55)"
      >
        i
      </text>
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(1125.88, 113.92) rotate(0.55)"
      >
        z
      </text>
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(1146.6, 114.05) rotate(1.13)"
      >
        a
      </text>
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(1173.47, 114.57) rotate(2.12)"
      >
        d
      </text>
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(1201.44, 115.61) rotate(3.11)"
      >
        o
      </text>
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(1228.3, 117.1) rotate(3.96)"
      >
        r
      </text>
      <!-- Director -->
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="matrix(0.93, 0.36, -0.36, 0.93, 1621.79, 197.23)"
      >
        D
      </text>
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="matrix(0.93, 0.38, -0.38, 0.93, 1650.85, 208.5)"
      >
        i
      </text>
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="matrix(0.92, 0.39, -0.39, 0.92, 1661.95, 212.99)"
      >
        r
      </text>
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(1678.26, 219.85) rotate(24.04)"
      >
        e
      </text>
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(1700.08, 229.57) rotate(25.33)"
      >
        c
      </text>
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(1721.67, 239.83) rotate(26.47)"
      >
        t
      </text>
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="matrix(0.89, 0.47, -0.47, 0.89, 1735.92, 246.86)"
      >
        o
      </text>
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(1759.64, 259.37) rotate(29.14)"
      >
        r
      </text>
      <!-- Pionero -->
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="matrix(0.26, 0.97, -0.97, 0.26, 2045.66, 565.91)"
      >
        P
      </text>
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="matrix(0.21, 0.98, -0.98, 0.21, 2051.82, 589.68)"
      >
        i
      </text>
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(2054.44 601.06) rotate(81.39)"
      >
        o
      </text>
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(2058.43, 627.2) rotate(85.98)"
      >
        n
      </text>
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(2060.53, 653.73) rotate(91.57)"
      >
        e
      </text>
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="matrix(-0.07, 1, -1, -0.07, 2060.04, 678.24)"
      >
        r
      </text>
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="matrix(-0.14, 0.99, -0.99, -0.14, 2058.97, 695.59)"
      >
        o
      </text>
      <!-- Coordinador -->
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(218.31, 783.67) rotate(-108.12)"
      >
        C
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="matrix(-0.24, -0.97, 0.97, -0.24, 209.3, 756.17)"
      >
        o
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="matrix(-0.16, -0.99, 0.99, -0.16, 203.01, 730.47)"
      >
        o
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(198.86, 704.29) rotate(-95.31)"
      >
        r
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(197.05, 686.96) rotate(-91.57)"
      >
        d
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(196.52 659.3) rotate(-88.14)"
      >
        i
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="matrix(0.09, -1, 1, 0.09, 196.67, 647.63)"
      >
        n
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="matrix(0.17, -0.99, 0.99, 0.17, 199.08, 621.02)"
      >
        a
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(203.52, 594.96) rotate(-75.67)"
      >
        d
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(210.35, 568.25) rotate(-71.27)"
      >
        o
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(218.99, 543.13) rotate(-67.7)"
      >
        r
      </text>
      <!-- Analizador -->
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="matrix(0.84, -0.54, 0.54, 0.84, 428.4, 300.79)"
      >
        A
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(451.82, 285.67) rotate(-31.01)"
      >
        n
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(475.02, 271.73) rotate(-29.27)"
      >
        a
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(498.48, 258.66) rotate(-28.08)"
      >
        l
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(508.98, 253.06) rotate(-27.36)"
      >
        i
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(519.59, 247.53) rotate(-26.38)"
      >
        z
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(538.07, 238.32) rotate(-25.05)"
      >
        a
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(562.35, 226.96) rotate(-23.56)"
      >
        d
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="matrix(0.93, -0.38, 0.38, 0.93, 587.96, 215.8)"
      >
        o
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(612.85, 205.72) rotate(-20.95)"
      >
        r
      </text>
      <!-- Dominante -->
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(1529.13, 94.15) rotate(15.65)"
      >
        D
      </text>
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(1588.16 110.76) rotate(17.86)"
      >
        o
      </text>
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="matrix(0.94, 0.35, -0.35, 0.94, 1638.41, 126.67)"
      >
        m
      </text>
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(1707.23, 152.77) rotate(22.5)"
      >
        i
      </text>
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(1728.98 161.52) rotate(24.24)"
      >
        n
      </text>
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="matrix(0.85, 0.52, -0.52, 0.85, 1777.55, 183.36)"
      >
        a
      </text>
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(1824.6, 207.05) rotate(29.48)"
      >
        n
      </text>
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="matrix(0.85, 0.53, -0.53, 0.85, 1870.92, 233.47)"
      >
        t
      </text>
      <text
        class="fill-red stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(1897.46, 249.7) rotate(34.15)"
      >
        e
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="matrix(0.83, -0.56, 0.56, 0.83, 292.21, 273.29)"
      >
        C
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(339.95, 241.33) rotate(-30.61)"
      >
        a
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(385.22, 214.53) rotate(-27.7)"
      >
        u
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(432.56, 189.92) rotate(-25.53)"
      >
        t
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="matrix(0.92, -0.4, 0.4, 0.92, 460.68, 176.32)"
      >
        e
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(505.33, 157.02) rotate(-21.93)"
      >
        l
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="matrix(0.94, -0.35, 0.35, 0.94, 524.67, 148.98)"
      >
        o
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(574.25, 130.69) rotate(-18.33)"
      >
        s
      </text>
      <text
        class="fill-blue stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(614.49, 117.27) rotate(-16.4)"
      >
        o
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(251.59, 1083.53) rotate(34.42)"
      >
        S
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="matrix(0.86, 0.52, -0.52, 0.86, 298.11, 1115.3)"
      >
        o
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(345.19, 1143.62) rotate(28.58)"
      >
        s
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="matrix(0.89, 0.45, -0.45, 0.89, 383.95, 1164.61)"
      >
        t
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(413.28, 1179.48) rotate(24.73)"
      >
        e
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(459.14, 1200.61) rotate(22.38)"
      >
        n
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(509.98, 1221.46) rotate(20.19)"
      >
        e
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="matrix(0.95, 0.31, -0.31, 0.95, 554.06, 1237.77)"
      >
        d
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="matrix(0.96, 0.28, -0.28, 0.96, 606.36, 1254.95)"
      >
        o
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(657.22, 1269.58) rotate(14.52)"
      >
        r
      </text>
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(1576.82, 1275.26) rotate(-14.86)"
      >
        I
      </text>
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(1599.78, 1269.37) rotate(-16.26)"
      >
        n
      </text>
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(1651.05, 1254.4) rotate(-18.23)"
      >
        fl
      </text>
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(1699.14, 1238.6) rotate(-20.29)"
      >
        u
      </text>
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(1750.53, 1219.48) rotate(-22.35)"
      >
        y
      </text>
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(1789.62, 1203.49) rotate(-24.35)"
      >
        e
      </text>
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="matrix(0.89, -0.45, 0.45, 0.89, 1835.72, 1182.67)"
      >
        n
      </text>
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="matrix(0.87, -0.49, 0.49, 0.87, 1884.9, 1157.54)"
      >
        t
      </text>
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-79px font-style"
        transform="translate(1913.85, 1141.64) rotate(-31.25)"
      >
        e
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-40px"
        transform="matrix(0.84, 0.54, -0.54, 0.84, 405.33, 1044.95)"
      >
        C
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-40px"
        transform="translate(430.04, 1060.63) rotate(30.66)"
      >
        o
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-40px"
        transform="translate(453.07, 1074.29) rotate(29.01)"
      >
        n
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-40px"
        transform="translate(476.77, 1087.41) rotate(27.52)"
      >
        c
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-40px"
        transform="translate(498.01, 1098.41) rotate(26.5)"
      >
        i
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-40px"
        transform="translate(508.76 1103.77) rotate(25.84)"
      >
        l
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-40px"
        transform="matrix(0.9, 0.43, -0.43, 0.9, 519.49, 1108.97)"
      >
        i
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-40px"
        transform="translate(530.26 1114.11) rotate(24.16)"
      >
        a
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-40px"
        transform="matrix(0.92, 0.39, -0.39, 0.92, 554.75, 1125.1)"
      >
        d
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-40px"
        transform="translate(580.54 1135.91) rotate(21.4)"
      >
        o
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-40px"
        transform="translate(605.55 1145.67) rotate(20.29)"
      >
        r
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-42px"
        transform="translate(978.36 1235.75) rotate(4.71)"
      >
        R
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-42px"
        transform="translate(1004.73 1237.93) rotate(3.79)"
      >
        e
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-42px"
        transform="matrix(1, 0.05, -0.05, 1, 1030.72, 1239.61)"
      >
        l
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-42px"
        transform="translate(1041.97 1240.28) rotate(2.46)"
      >
        a
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-42px"
        transform="translate(1070.25 1241.48) rotate(1.54)"
      >
        c
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-42px"
        transform="matrix(1, 0.02, -0.02, 1, 1095.42, 1242.13)"
      >
        i
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-42px"
        transform="translate(1108.09 1242.29) rotate(0.55)"
      >
        o
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-42px"
        transform="translate(1136.81 1242.47) rotate(-0.77)"
      >
        n
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-42px"
        transform="translate(1165.4 1242.09) rotate(-1.74)"
      >
        a
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-42px"
        transform="translate(1193.64 1241.24) rotate(-2.73)"
      >
        d
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-42px"
        transform="translate(1223.06 1239.83) rotate(-3.73)"
      >
        o
      </text>
      <text
        class="fill-green stroke-miterlimit-10 stroke-black-2px font-size-42px"
        transform="translate(1251.28 1237.96) rotate(-4.58)"
      >
        r
      </text>
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(1623.13 1167.12) rotate(-19.7)"
      >
        M
      </text>
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(1659.9 1153.87) rotate(-21.25)"
      >
        o
      </text>
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(1684.92 1144.08) rotate(-22.29)"
      >
        t
      </text>
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(1699.69 1138.01) rotate(-22.99)"
      >
        i
      </text>
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(1710.75 1133.37) rotate(-23.85)"
      >
        v
      </text>
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(1729.93 1124.93) rotate(-25.07)"
      >
        a
      </text>
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(1754.22 1113.58) rotate(-26.56)"
      >
        d
      </text>
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="translate(1779.21 1101.09) rotate(-28.1)"
      >
        o
      </text>
      <text
        class="fill-yellow stroke-miterlimit-10 stroke-black-2px font-size-40px font-style"
        transform="matrix(0.87, -0.49, 0.49, 0.87, 1802.85, 1088.42)"
      >
        r
      </text>
    </g>
    <g id="zonas">
      <template v-for="zoneData in zonesData">
        <polygon
          class="fill-none"
          :ref="`polygon-${zoneData.id}`"
          :id="`_${zoneData.id}`"
          :data-name="zoneData.id"
          :key="zoneData.id"
          :points="zoneData.points"
        ></polygon>
      </template>
      <ellipse
        class="fill-black stroke-black-2px"
        :cx="primaryCenterX"
        :cy="primaryCenterY"
        rx="19.40"
        ry="10.89"
      />
      <ellipse
        class="fill-gray stroke-black-2px"
        :cx="consciousCenterX"
        :cy="consciousCenterY"
        rx="19.40"
        ry="10.89"
        v-if="!equalProfiles"
      />
    </g>
  </svg>
</template>

<script>
import ovalData from "./OvalSVGData";
import center from "svg-polygon-center";

export default {
  props: {
    primaryProfileId: {
      type: Number,
      required: true
    },
    consciousProfileId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      primaryCenter: null,
      consciousCenter: null
    };
  },
  created() {},
  computed: {
    zonesData() {
      return getZonesData();
    },
    primaryCenterX() {
      return this.primaryCenter ? this.primaryCenter.x : 0;
    },
    primaryCenterY() {
      return this.primaryCenter ? this.primaryCenter.y : 0;
    },
    consciousCenterX() {
      return this.consciousCenter ? this.consciousCenter.x : 0;
    },
    consciousCenterY() {
      return this.consciousCenter ? this.consciousCenter.y : 0;
    },
    equalProfiles() {
      return this.primaryProfileId === this.consciousProfileId;
    }
  },
  methods: {
    getStyle(zoneId) {
      if (this.isPrimaryId(zoneId)) {
        return "fill-white";
      } else if (this.isConsciousId(zoneId)) {
        return "fill-gray";
      } else {
        return "fill-none";
      }
    },
    isPrimaryId(zoneId) {
      return this.primaryProfileId == zoneId;
    },
    isConsciousId(zoneId) {
      return this.consciousProfileId == zoneId;
    },
    getCenter(zoneId) {
      const element = this.getZoneElement(zoneId);
      if (element) {
        const points = element.getAttribute("points");
        const elementCenter = calculateCenter(zoneId, points);
        console.log(elementCenter);
        return elementCenter;
      }
    },
    getZoneElement(zoneId) {
      const id = `polygon-${zoneId}`;
      return this.$refs[id];
    }
  },
  watch: {
    primaryProfileId: function (value) {
      const zoneCenter = getCenter(value);
      this.primaryCenter = zoneCenter;
    },
    consciousProfileId: function (value) {
      const zoneCenter = getCenter(value);
      this.consciousCenter = zoneCenter;
    }
  }
};

function getCenter(zoneId) {
  const element = getZoneElement(zoneId);
  if (element) {
    const points = element.getAttribute("points");
    const elementCenter = calculateCenter(zoneId, points);
    return elementCenter;
  }
}

function getZoneElement(zoneId) {
  return document.querySelector(`polygon[data-name="${zoneId}"]`);
}

function calculateCenter(zoneId, points) {
  const elementCenter = center(points);
  const zoneData = getZoneData(zoneId);
  if (elementCenter) {
    return {
      x: elementCenter.x + zoneData.dx,
      y: elementCenter.y + zoneData.dy
    };
  } else {
    return { x: 0, y: 0 };
  }
}

function getZoneData(zoneId) {
  const zonesData = getZonesData();
  return zonesData.find(zone => zone.id == zoneId);
}

function getZonesData() {
  return ovalData.zonesData;
}
</script>

<style>
.ovalo {
  transform: scaley(1.7);
  transform-origin: center;
  margin-top: 20rem;
  margin-bottom: 20rem;
}
.fill-none {
  fill: none;
}
.opacity-0_4 {
  opacity: 0.4;
}
.shape-3 {
  clip-path: url(#shape-3-clip-path);
}
.fill-blue {
  fill: #0817f5;
}
.fill-red {
  fill: #e73322;
}
.fill-green {
  fill: #7bfa4c;
}
.fill-yellow {
  fill: #fffe54;
}
.fill-white {
  fill: #fff;
}
.fill-gray {
  fill: #ccc;
}
.fill-black {
  fill: #000;
}
.opacity-0_1 {
  opacity: 0.1;
}
.opacity-0_3 {
  opacity: 0.3;
}
.opacity-0_5 {
  opacity: 0.5;
}
.stroke-white-9px {
  stroke: #fff;
  stroke-width: 9px;
}
.stroke-miterlimit-10 {
  stroke-miterlimit: 10;
}
.shape-13 {
  fill: url(#shape-13-gradient);
}
.shape-14 {
  fill: url(#shape-14-gradient);
}
.shape-15 {
  fill: url(#shape-15-gradient);
}
.shape-16 {
  fill: url(#shape-16-gradient);
}
.shape-17 {
  fill: url(#shape-17-gradient);
}
.shape-18 {
  fill: url(#shape-18-gradient);
}
.shape-19 {
  fill: url(#shape-19-gradient);
}
.shape-20 {
  fill: url(#shape-20-gradient);
}
.shape-21 {
  fill: url(#shape-21-gradient);
}
.shape-22 {
  fill: url(#shape-22-gradient);
}
.shape-23 {
  fill: url(#shape-23-gradient);
}
.shape-24 {
  fill: url(#shape-24-gradient);
}
.shape-25 {
  fill: url(#shape-25-gradient);
}
.shape-26 {
  fill: url(#shape-26-gradient);
}
.shape-27 {
  fill: url(#shape-27-gradient);
}
.shape-28 {
  fill: url(#shape-28-gradient);
}
.shape-29 {
  fill: url(#shape-29-gradient);
}
.shape-30 {
  fill: url(#shape-30-gradient);
}
.shape-31 {
  fill: url(#shape-31-gradient);
}
.shape-32 {
  fill: url(#shape-32-gradient);
}
.shape-33 {
  fill: url(#shape-33-gradient);
}
.shape-34 {
  fill: url(#shape-34-gradient);
}
.shape-35 {
  fill: url(#shape-35-gradient);
}
.shape-36 {
  fill: url(#shape-36-gradient);
}
.shape-37 {
  fill: url(#shape-37-gradient);
}
.shape-38 {
  fill: url(#shape-38-gradient);
}
.shape-39 {
  fill: url(#shape-39-gradient);
}
.shape-40 {
  fill: url(#shape-40-gradient);
}
.shape-41 {
  fill: url(#shape-41-gradient);
}
.shape-42 {
  fill: url(#shape-42-gradient);
}
.shape-43 {
  fill: url(#shape-43-gradient);
}
.shape-44 {
  fill: url(#shape-44-gradient);
}
.shape-45 {
  fill: url(#shape-45-gradient);
}
.shape-46 {
  fill: url(#shape-46-gradient);
}
.shape-47 {
  clip-path: url(#shape-47-clip-path);
}
.shape-48 {
  fill: url(#shape-48-gradient);
}
.shape-49 {
  clip-path: url(#shape-49-clip-path);
}
.shape-50 {
  fill: url(#shape-50-gradient);
}
.shape-51 {
  fill: url(#shape-51-gradient);
}
.shape-52 {
  fill: url(#shape-52-gradient);
}
.white-circle-line {
  stroke-width: 4px;
  opacity: 0.2;
  stroke: url(#white-circle-line-gradient);
}
.font-size-40px {
  font-size: 40px;
}
.font-style {
  font-family: Comfortaa-Bold, Comfortaa;
  font-weight: 700;
}
.stroke-black-2px {
  stroke: #000;
  stroke-width: 2px;
}
.stroke-gray-2px {
  stroke: #797878;
  stroke-width: 2px;
}
.stroke-linecap-linejoin {
  stroke-linecap: round;
  stroke-linejoin: round;
}
.font-size-79px {
  font-size: 79px;
}
.font-size-42px {
  font-size: 42.07px;
}
.fill-multicolor {
  fill: url(#multicolor-gradient);
}
</style>
