<template>
  <o-card-form-layout
    title="Información de Facturación"
    :actions="actions"
    :loading="loading"
  >
    <v-container class="pb-0">
      <v-row>
        <v-col cols="12" class="py-0 px-1">
          <o-text-field
            label="Nombre Fiscal"
            v-model="billingInfo.name"
            required
          />
        </v-col>
        <v-col cols="12" class="py-0 px-1">
          <div class="subtitle-2 text-left pb-2 pl-3">Dirección Fiscal</div>
          <o-text-field
            label="Línea 1"
            v-model="billingInfo.address.line1"
            required
          />
        </v-col>
        <v-col cols="12" class="py-0 px-1">
          <o-text-field label="Línea 2" v-model="billingInfo.address.line2" />
        </v-col>
        <v-col cols="12" class="py-0 px-1">
          <o-text-field
            label="Ciudad"
            v-model="billingInfo.address.city"
            required
          />
        </v-col>
        <v-col cols="12" class="py-0 px-1">
          <o-text-field
            label="Estado / Provincia"
            v-model="billingInfo.address.state"
            required
          />
        </v-col>
        <v-col cols="12" class="py-0 px-1">
          <o-text-field
            label="Código Postal"
            v-model="billingInfo.address.postalCode"
            required
          />
        </v-col>
        <v-col cols="12" class="py-0 px-1">
          <o-autocomplete-field
            label="País"
            item-text="country"
            item-value="code"
            required
            v-model="billingInfo.address.country"
            :items="countries"
            @change="onSelectCountry"
          />
        </v-col>
        <v-col cols="12" class="pt-0 pb-2 px-1" v-if="showTaxCodeSelection">
          <div class="subtitle-2 text-left pb-2 pl-3">Tipo de Id. Fiscal</div>
          <v-radio-group
            v-model="billingInfo.taxCode"
            row
            class="mt-0 px-3"
            :rules="taxCodeValidation"
          >
            <v-radio
              :key="code.value"
              :label="code.label"
              :value="code.value"
              v-for="code in taxCodes"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" class="py-0 px-1" v-if="showTaxCodeInput">
          <o-text-field
            label="Id. Fiscal"
            v-model="billingInfo.taxId"
            :required="showTaxCodeInput"
          />
        </v-col>
      </v-row>
    </v-container>
  </o-card-form-layout>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import countries from "@/model/countries";
import authMixin from "@/mixins/authMixin";
import messageDialogMixin from "@/mixins/messageDialogMixin";

export default defineComponent({
  name: "BillingInfo",
  mixins: [authMixin, messageDialogMixin],
  data() {
    return {
      mode: null,
      countries: countries.filter(country => !country.extended),
      billingInfo: {
        name: "",
        address: {
          line1: "",
          line2: "",
          city: "",
          state: "",
          postalCode: "",
          country: null
        },
        taxCode: null,
        taxId: ""
      },
      taxCodeValidation: [
        v =>
          !this.showTaxCodeSelection ||
          !!this.billingInfo.taxCode ||
          "Este campo es requerido"
      ],
      loading: false
    };
  },
  computed: {
    actions() {
      const actions = [
        { type: "submit", label: "Continuar", handler: this.save }
      ];
      if (this.mode === "update") {
        actions.push({
          type: "secondary",
          label: "Cerrar",
          to: "/"
        });
      }
      return actions;
    },
    taxCodes() {
      if (this.billingInfo.address.country) {
        return this.billingInfo.address.country.taxCodes.map(code => ({
          label: code.split("_")[1].toUpperCase(),
          value: code
        }));
      }
      return [];
    },
    showTaxCodeSelection() {
      return (
        this.billingInfo.address.country &&
        this.billingInfo.address.country.taxCodes.length > 1
      );
    },
    showTaxCodeInput() {
      return (
        this.billingInfo.address.country &&
        this.billingInfo.address.country.taxCodes.length > 0
      );
    }
  },
  methods: {
    save(form) {
      if (form.validate()) {
        this.loading = true;
        this.$store
          .dispatch("saveBillingInfo", this.billingInfo)
          .then(() => {
            this.loading = false;
            this.$router.push("/");
          })
          .catch(error => {
            console.log(error);
            this.loading = false;
            this.errorDialog(error.message);
          });
      }
    },
    onSelectCountry() {
      if (this.billingInfo.address.country.taxCodes.length == 1) {
        this.billingInfo.taxCode = this.billingInfo.address.country.taxCodes[0];
      } else {
        this.billingInfo.taxCode = null;
      }
    }
  },
  created() {
    this.mode = this.$route.params.mode;
  }
});
</script>

<style></style>
