export const PRIMARY = "primary";
export const CONSCIOUS = "conscious";

export const MEMBERSHIP_STATUS = {
  ACTIVE: "Activa",
  CANCELLED: "Cancelada"
};

export const ASSESSMENT_STATUS = {
  SENT: "Enviado",
  SUBMITTED: "Aplicado"
};
