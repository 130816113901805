import messageDialogMixin from "./messageDialogMixin";

export default {
  mixins: [messageDialogMixin],
  data() {
    return {
      assessmentId: null,
      assessment: {}
    };
  },
  created() {
    this.assessmentId = this.$route.params.assessmentId;
  },
  computed: {
    assessmentStartURL() {
      return `/assessment/${this.assessmentId}/start`;
    },
    assessmentFormURL() {
      return `/assessment/${this.assessmentId}/form`;
    },
    assessmentQuestionsURL() {
      return `/assessment/${this.assessmentId}/questions`;
    },
    assessmentSubmitURL() {
      return `/assessment/${this.assessmentId}/submit`;
    }
  },
  methods: {
    loadAssessment() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("getAssessment", this.assessmentId)
          .then(assessment => {
            this.assessment = assessment;
            resolve(assessment);
          })
          .catch(error => {
            this.$store.dispatch("clearAssessment");
            reject(error);
          });
      });
    },
    getAndValidateAssessment() {
      const assessment = this.$store.getters.assessment;
      if (assessment && assessment.id === this.assessmentId) {
        this.assessment = assessment;
        return true;
      } else {
        this.$store.dispatch("clearAssessment");
        this.showErrorAndLeave(
          "Ha ocurrido un error leyendo los datos de la prueba"
        );
      }
    },
    showErrorAndBack(message) {
      this.assessment = {};
      this.errorDialog(message, () => {
        this.$router.go(-1);
      });
    },
    showErrorAndLeave(message) {
      this.assessment = {};
      this.errorDialog(message, () => {
        window.location = "https://globaldiscassessments.com";
      });
    }
  }
};
