import axios from "axios";
import firebase from "@/plugins/firebase";
import firebaseConfig from "@/config/firebase";

// TODO: Refactor "instance"
const instance = axios.create({
  baseURL: firebaseConfig.apiBaseURL,
});

const apiBaseURL = "https://api.higherupcoaching.com/";

export default {
  async products(type) {
    try {
      const params = { type };
      let userToken = "";

      if (type === "pack") {
        const currentUser = firebase.auth().currentUser;
        if (currentUser) {
          userToken = await firebase.auth().currentUser.getIdToken();
        } else {
          history.back();
        }
      }

      const response = await instance.get("/shop/products", {
        params,
        headers: {
          token: userToken,
        },
      });
      const data = processResponse(response);
      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async getProducts() {
    try {
      let response = await axios.get(apiBaseURL + "woocommerce/products");
      return response.data;
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  },
  async getProductsById(id) {
    try {
      let response = await axios.get(`${apiBaseURL}woocommerce/products/${id}`);
      return response.data;
    } catch (error) {
      console.error(error);

      throw new Error(error);
    }
  },
  async getProductsCategories() {
    try {
      let response = await axios.get(`${apiBaseURL}woocommerce/categories`);
      return response.data;
    } catch (error) {
      console.error(error);

      throw new Error(error);
    }
  },
};

function processResponse(response) {
  if (response.status === 200) {
    if (response.data && response.data) {
      return response.data;
    } else {
      return null;
    }
  } else {
    throw new Error(
      `Error ${response.status} on response for ${response.config.url}`
    );
  }
}
