<template>
  <v-autocomplete
    background-color="#ffffff"
    clearable
    dense
    outlined
    return-object
    :disabled="disabled"
    :hide-details="hideDetails"
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    :label="label"
    :required="required"
    :rules="computedRules"
    :value="value"
    @change="onChange"
    @input="onInput"
    @keyup.native="onKeydown"
  ></v-autocomplete>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

import validations from "@/plugins/validations";

export default defineComponent({
  name: "AutocompleteField",
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    rules: {
      type: Array,
      required: false
    },
    type: {
      type: String,
      required: false,
      default: "text"
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    items: {
      type: Array,
      required: true
    },
    itemText: {
      type: String,
      required: false,
      default: "text"
    },
    itemValue: {
      type: String,
      required: false,
      default: "value"
    },
    value: Object
  },
  computed: {
    computedRules() {
      const rules = [];
      if (this.required) {
        rules.push(validations.form.required_field);
      }
      if (this.rules) {
        rules.push(...this.rules);
      }
      return rules;
    }
  },
  methods: {
    onChange(event) {
      this.$emit("change", event);
    },
    onInput(event) {
      this.$emit("input", event);
    },
    onKeydown(event) {
      this.$emit("keydown", event);
    }
  }
});
</script>
