export default [
  // 1
  {
    attributes: [
      {
        name: "Entusiasta",
        value: "I"
      },
      {
        name: "Rápido",
        value: "D"
      },
      {
        name: "Lógico",
        value: "C"
      },
      {
        name: "Apacible",
        value: "S"
      }
    ],
    mostAttributeFormFieldId: "q01_ma",
    lessAttributeFormFieldId: "q01_la",
    mostTypeFormFieldId: "q01_mt",
    lessTypeFormFieldId: "q01_lt"
  },
  // 2
  {
    attributes: [
      {
        name: "Preciso",
        value: "C"
      },
      {
        name: "Decidido",
        value: "D"
      },
      {
        name: "Confiado",
        value: "I"
      },
      {
        name: "Generoso",
        value: "S"
      }
    ],
    mostAttributeFormFieldId: "q02_ma",
    lessAttributeFormFieldId: "q02_la",
    mostTypeFormFieldId: "q02_mt",
    lessTypeFormFieldId: "q02_lt"
  },
  // 3
  {
    attributes: [
      {
        name: "Amigable",
        value: "I"
      },
      {
        name: "Cauteloso",
        value: "C"
      },
      {
        name: "Franco",
        value: "D"
      },
      {
        name: "Solidario",
        value: "S"
      }
    ],
    mostAttributeFormFieldId: "q03_ma",
    lessAttributeFormFieldId: "q03_la",
    mostTypeFormFieldId: "q03_mt",
    lessTypeFormFieldId: "q03_lt"
  },
  // 4
  {
    attributes: [
      {
        name: "Elocuente",
        value: "I"
      },
      {
        name: "Controlado",
        value: "C"
      },
      {
        name: "Tolerante",
        value: "S"
      },
      {
        name: "Aventurero",
        value: "D"
      }
    ],
    mostAttributeFormFieldId: "q04_ma",
    lessAttributeFormFieldId: "q04_la",
    mostTypeFormFieldId: "q04_mt",
    lessTypeFormFieldId: "q04_lt"
  },
  // 5
  {
    attributes: [
      {
        name: "Atrevido",
        value: "D"
      },
      {
        name: "Consciente",
        value: "C"
      },
      {
        name: "Comunicativo",
        value: "I"
      },
      {
        name: "Moderado",
        value: "S"
      }
    ],
    mostAttributeFormFieldId: "q05_ma",
    lessAttributeFormFieldId: "q05_la",
    mostTypeFormFieldId: "q05_mt",
    lessTypeFormFieldId: "q05_lt"
  },
  // 6
  {
    attributes: [
      {
        name: "Buen compañero",
        value: "S"
      },
      {
        name: "Optimista",
        value: "I"
      },
      {
        name: "Investigador",
        value: "C"
      },
      {
        name: "Arriesgado",
        value: "D"
      }
    ],
    mostAttributeFormFieldId: "q06_ma",
    lessAttributeFormFieldId: "q06_la",
    mostTypeFormFieldId: "q06_mt",
    lessTypeFormFieldId: "q06_lt"
  },
  // 7
  {
    attributes: [
      {
        name: "Expresivo",
        value: "I"
      },
      {
        name: "Cuidadoso",
        value: "C"
      },
      {
        name: "Dominante",
        value: "D"
      },
      {
        name: "Sensible",
        value: "S"
      }
    ],
    mostAttributeFormFieldId: "q07_ma",
    lessAttributeFormFieldId: "q07_la",
    mostTypeFormFieldId: "q07_mt",
    lessTypeFormFieldId: "q07_lt"
  },
  // 8
  {
    attributes: [
      {
        name: "Extrovertido",
        value: "I"
      },
      {
        name: "Precavido",
        value: "C"
      },
      {
        name: "Constante",
        value: "S"
      },
      {
        name: "Activo",
        value: "D"
      }
    ],
    mostAttributeFormFieldId: "q08_ma",
    lessAttributeFormFieldId: "q08_la",
    mostTypeFormFieldId: "q08_mt",
    lessTypeFormFieldId: "q08_lt"
  },
  // 9
  {
    attributes: [
      {
        name: "Discreto",
        value: "C"
      },
      {
        name: "Complaciente",
        value: "S"
      },
      {
        name: "Encantador",
        value: "I"
      },
      {
        name: "Insistente",
        value: "D"
      }
    ],
    mostAttributeFormFieldId: "q09_ma",
    lessAttributeFormFieldId: "q09_la",
    mostTypeFormFieldId: "q09_mt",
    lessTypeFormFieldId: "q09_lt"
  },
  // 10
  {
    attributes: [
      {
        name: "Valiente",
        value: "D"
      },
      {
        name: "Motivador",
        value: "I"
      },
      {
        name: "Pacífico",
        value: "S"
      },
      {
        name: "Perfeccionista",
        value: "C"
      }
    ],
    mostAttributeFormFieldId: "q10_ma",
    lessAttributeFormFieldId: "q10_la",
    mostTypeFormFieldId: "q10_mt",
    lessTypeFormFieldId: "q10_lt"
  },
  // 11
  {
    attributes: [
      {
        name: "Reservado",
        value: "C"
      },
      {
        name: "Atento",
        value: "S"
      },
      {
        name: "Osado",
        value: "D"
      },
      {
        name: "Alegre",
        value: "I"
      }
    ],
    mostAttributeFormFieldId: "q11_ma",
    lessAttributeFormFieldId: "q11_la",
    mostTypeFormFieldId: "q11_mt",
    lessTypeFormFieldId: "q11_lt"
  },
  // 12
  {
    attributes: [
      {
        name: "Estimulante",
        value: "I"
      },
      {
        name: "Gentil",
        value: "S"
      },
      {
        name: "Perceptivo",
        value: "C"
      },
      {
        name: "Independiente",
        value: "D"
      }
    ],
    mostAttributeFormFieldId: "q12_ma",
    lessAttributeFormFieldId: "q12_la",
    mostTypeFormFieldId: "q12_mt",
    lessTypeFormFieldId: "q12_lt"
  },
  // 13
  {
    attributes: [
      {
        name: "Competitivo",
        value: "D"
      },
      {
        name: "Considerado",
        value: "S"
      },
      {
        name: "Convincente",
        value: "I"
      },
      {
        name: "Preparado",
        value: "C"
      }
    ],
    mostAttributeFormFieldId: "q13_ma",
    lessAttributeFormFieldId: "q13_la",
    mostTypeFormFieldId: "q13_mt",
    lessTypeFormFieldId: "q13_lt"
  },
  // 14
  {
    attributes: [
      {
        name: "Meticuloso",
        value: "C"
      },
      {
        name: "Obediente",
        value: "S"
      },
      {
        name: "Ideas Firmes",
        value: "D"
      },
      {
        name: "Alentador",
        value: "I"
      }
    ],
    mostAttributeFormFieldId: "q14_ma",
    lessAttributeFormFieldId: "q14_la",
    mostTypeFormFieldId: "q14_mt",
    lessTypeFormFieldId: "q14_lt"
  },
  // 15
  {
    attributes: [
      {
        name: "Popular",
        value: "I"
      },
      {
        name: "Reflexivo",
        value: "C"
      },
      {
        name: "Tenaz",
        value: "D"
      },
      {
        name: "Calmado",
        value: "S"
      }
    ],
    mostAttributeFormFieldId: "q15_ma",
    lessAttributeFormFieldId: "q15_la",
    mostTypeFormFieldId: "q15_mt",
    lessTypeFormFieldId: "q15_lt"
  },
  // 16
  {
    attributes: [
      {
        name: "Analítico",
        value: "C"
      },
      {
        name: "Audaz",
        value: "D"
      },
      {
        name: "Bondadoso",
        value: "S"
      },
      {
        name: "Promotor",
        value: "I"
      }
    ],
    mostAttributeFormFieldId: "q16_ma",
    lessAttributeFormFieldId: "q16_la",
    mostTypeFormFieldId: "q16_mt",
    lessTypeFormFieldId: "q16_lt"
  },
  // 17
  {
    attributes: [
      {
        name: "Persuasivo",
        value: "I"
      },
      {
        name: "Paciente",
        value: "S"
      },
      {
        name: "Autosuficiente",
        value: "D"
      },
      {
        name: "Certero",
        value: "C"
      }
    ],
    mostAttributeFormFieldId: "q17_ma",
    lessAttributeFormFieldId: "q17_la",
    mostTypeFormFieldId: "q17_mt",
    lessTypeFormFieldId: "q17_lt"
  },
  // 18
  {
    attributes: [
      {
        name: "Pausado",
        value: "S"
      },
      {
        name: "Resuelto",
        value: "D"
      },
      {
        name: "Prevenido",
        value: "C"
      },
      {
        name: "Vivaz",
        value: "I"
      }
    ],
    mostAttributeFormFieldId: "q18_ma",
    lessAttributeFormFieldId: "q18_la",
    mostTypeFormFieldId: "q18_mt",
    lessTypeFormFieldId: "q18_lt"
  },
  // 19
  {
    attributes: [
      {
        name: "Firme",
        value: "D"
      },
      {
        name: "Seguro de sí mismo",
        value: "I"
      },
      {
        name: "Previsivo",
        value: "S"
      },
      {
        name: "Cumplido",
        value: "C"
      }
    ],
    mostAttributeFormFieldId: "q19_ma",
    lessAttributeFormFieldId: "q19_la",
    mostTypeFormFieldId: "q19_mt",
    lessTypeFormFieldId: "q19_lt"
  },
  // 20
  {
    attributes: [
      {
        name: "De trato fácil",
        value: "I"
      },
      {
        name: "Compasivo",
        value: "S"
      },
      {
        name: "Cauto",
        value: "C"
      },
      {
        name: "Directo",
        value: "D"
      }
    ],
    mostAttributeFormFieldId: "q20_ma",
    lessAttributeFormFieldId: "q20_la",
    mostTypeFormFieldId: "q20_mt",
    lessTypeFormFieldId: "q20_lt"
  },
  // 21
  {
    attributes: [
      {
        name: "Evaluador",
        value: "C"
      },
      {
        name: "Comprensivo",
        value: "S"
      },
      {
        name: "Animado",
        value: "I"
      },
      {
        name: "Persistente",
        value: "D"
      }
    ],
    mostAttributeFormFieldId: "q21_ma",
    lessAttributeFormFieldId: "q21_la",
    mostTypeFormFieldId: "q21_mt",
    lessTypeFormFieldId: "q21_lt"
  },
  // 22
  {
    attributes: [
      {
        name: "Demostrativo",
        value: "I"
      },
      {
        name: "Detallista",
        value: "C"
      },
      {
        name: "Enérgico",
        value: "D"
      },
      {
        name: "Estable",
        value: "S"
      }
    ],
    mostAttributeFormFieldId: "q22_ma",
    lessAttributeFormFieldId: "q22_la",
    mostTypeFormFieldId: "q22_mt",
    lessTypeFormFieldId: "q22_lt"
  },
  // 23
  {
    attributes: [
      {
        name: "Sociable",
        value: "I"
      },
      {
        name: "Sistemático",
        value: "C"
      },
      {
        name: "Vigoroso",
        value: "D"
      },
      {
        name: "Sabe escuchar",
        value: "S"
      }
    ],
    mostAttributeFormFieldId: "q23_ma",
    lessAttributeFormFieldId: "q23_la",
    mostTypeFormFieldId: "q23_mt",
    lessTypeFormFieldId: "q23_lt"
  },
  // 24
  {
    attributes: [
      {
        name: "Cautivador",
        value: "I"
      },
      {
        name: "Conciliador",
        value: "S"
      },
      {
        name: "Exigente",
        value: "D"
      },
      {
        name: "Normativo",
        value: "C"
      }
    ],
    mostAttributeFormFieldId: "q24_ma",
    lessAttributeFormFieldId: "q24_la",
    mostTypeFormFieldId: "q24_mt",
    lessTypeFormFieldId: "q24_lt"
  },
  // 25
  {
    attributes: [
      {
        name: "Argumentador",
        value: "D"
      },
      {
        name: "Metódico",
        value: "C"
      },
      {
        name: "Servicial",
        value: "S"
      },
      {
        name: "Desenvuelto",
        value: "I"
      }
    ],
    mostAttributeFormFieldId: "q25_ma",
    lessAttributeFormFieldId: "q25_la",
    mostTypeFormFieldId: "q25_mt",
    lessTypeFormFieldId: "q25_lt"
  },
  // 26
  {
    attributes: [
      {
        name: "Jovial",
        value: "I"
      },
      {
        name: "Exacto",
        value: "C"
      },
      {
        name: "Creativo",
        value: "D"
      },
      {
        name: "Ecuánime",
        value: "S"
      }
    ],
    mostAttributeFormFieldId: "q26_ma",
    lessAttributeFormFieldId: "q26_la",
    mostTypeFormFieldId: "q26_mt",
    lessTypeFormFieldId: "q26_lt"
  },
  // 27
  {
    attributes: [
      {
        name: "Inquieto",
        value: "D"
      },
      {
        name: "Amable",
        value: "S"
      },
      {
        name: "Divertido",
        value: "I"
      },
      {
        name: "Diplomático",
        value: "C"
      }
    ],
    mostAttributeFormFieldId: "q27_ma",
    lessAttributeFormFieldId: "q27_la",
    mostTypeFormFieldId: "q27_mt",
    lessTypeFormFieldId: "q27_lt"
  },
  // 28
  {
    attributes: [
      {
        name: "Prudente",
        value: "C"
      },
      {
        name: "Innovador",
        value: "D"
      },
      {
        name: "Espontáneo",
        value: "I"
      },
      {
        name: "Colaborador",
        value: "S"
      }
    ],
    mostAttributeFormFieldId: "q28_ma",
    lessAttributeFormFieldId: "q28_la",
    mostTypeFormFieldId: "q28_mt",
    lessTypeFormFieldId: "q28_lt"
  }
];
