import mailService from "../../services/mailService";
export default {
  state: () => ({
    order: [],
    account: [],
  }),
  mutations: {
    setOrderConfirmationResponse(state, order) {
      console.log(order);
      state.order = order;
    },
  },
  actions: {
    async sendOrderConfirmation({ commit }, order) {
      try {
        let data = await mailService.sendOrderConfirmation(await order);
        return commit("setOrderConfirmationResponse", data);
      } catch (error) {
        console.error(error);
        throw new Error(await error);
      }
    },
  },
  getters: {
    
  },
};
