<template>
  <vue-recaptcha
    :sitekey="sitekey"
    :size="captchaSize"
    @verify="markRecaptchaAsVerified"
  >
  </vue-recaptcha>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import { VueRecaptcha } from "vue-recaptcha";

export default defineComponent({
  components: { VueRecaptcha },
  props: {
    sitekey: {
      type: String,
      required: true
    }
  },
  methods: {
    markRecaptchaAsVerified() {
      this.$emit("verify", true);
    }
  },
  computed: {
    captchaSize() {
      return this.$vuetify.breakpoint.xs ? "compact" : "normal";
    }
  }
});
</script>

<style></style>
