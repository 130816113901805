<template>
  <o-card-form-layout title="Cambio de Contraseña" :actions="actions">
    <v-container class="pb-0">
      <v-row>
        <v-col cols="12" class="py-0 px-1">
          <o-password-field label="Contraseña Actual" v-model="password" />
        </v-col>
        <v-col cols="12" class="py-0 px-1">
          <o-password-field label="Nueva Contraseña" v-model="newPassword" />
        </v-col>
      </v-row>
    </v-container>
  </o-card-form-layout>
</template>

<script>
import messageDialogMixin from "@/mixins/messageDialogMixin";

export default {
  name: "ChangePassword",
  mixins: [messageDialogMixin],
  data() {
    return {
      password: "",
      newPassword: "",
      actions: [
        { type: "submit", label: "Continuar", handler: this.changePassword },
        {
          type: "secondary",
          label: "Cerrar",
          to: "/"
        }
      ]
    };
  },
  methods: {
    close() {
      this.$router.push("/");
    },
    changePassword(form) {
      if (form.validate()) {
        this.$store
          .dispatch("changePasswordLogin", {
            password: this.password,
            newPassword: this.newPassword
          })
          .then(() =>
            this.successDialog(
              "Se ha efectuado el cambio de contraseña exitosamente.",
              this.close
            )
          )
          .catch(error => this.errorDialog(error.message, this.close));
      }
    }
  }
};
</script>

<style scoped></style>
