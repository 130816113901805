<template lang="html">
  <v-container>
    <v-row>
      <v-col cols="3" class="hidden-sm-and-down"></v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title class="primary-title text-h6 secondary primary--text">
            Instrucciones
          </v-card-title>
          <v-card-text class="text-justify">
            <p>
              Por favor lea con cuidado las instrucciones antes de comenzar.
            </p>
            <p>
              El cuestionario que va a completar a continuación le tomará
              aproximadamente 20 minutos de su tiempo. En este lapso, asegúrese
              de poder encontrarse en un ambiente libre de interrupciones y de
              distracciones.
            </p>
            <p>
              Este test no evalúa respuestas correctas o incorrectas, o buenas o
              malas. Todos los estilos de comportamiento son únicos y valiosos.
            </p>
            <p>
              Se recomienda no sobre analizar las respuestas, trate de contestar
              con lo primero que se le venga a la mente.
            </p>
            <p>
              En cada ítem, debe seleccionar la opción que más lo describa y la
              opción que menos lo describa. Si no se identifica con las opciones
              propuestas, igualmente debe escoger una que se aproxime a lo que
              más lo describe y una a lo que menos lo describe. Cuando tenga
              dudas en su respuesta, ubíquese en su ambiente laboral para hacer
              su selección.
            </p>
            <p>
              Deberá responder a cada ítem para poder avanzar en la prueba a la
              siguiente respuesta.
            </p>
            <p>
              ¿Considera que en los próximos 20 minutos se encontrará libre de
              interrupciones y de distractores?, de ser así, puede comenzar.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3" class="hidden-sm-and-down"></v-col>
      <v-col cols="3" class="hidden-sm-and-down"></v-col>
      <v-col cols="12" md="6" class="align-content-center pt-0">
        <v-btn
          block
          class="secondary--text"
          color="primary"
          large
          :to="assessmentFormURL"
        >
          Continuar
        </v-btn>
      </v-col>
      <v-col cols="3" class="hidden-sm-and-down"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import assessmentMixin from "@/mixins/assessmentMixin";

export default defineComponent({
  mixins: [assessmentMixin],
  created() {
    this.loadAssessment()
      .then(assessment => {
        // TODO: Use constant
        if (assessment.status === "SUBMITTED") {
          this.$store.dispatch("clearAssessment");
          this.showErrorAndLeave("La prueba ya fue aplicada");
        }
      })
      .catch(error => {
        this.$store.dispatch("clearAssessment");
        this.showErrorAndLeave(
          "Ha ocurrido un error leyendo los datos de la prueba"
        );
      });
  }
});
</script>

<style lang="css" scoped>
.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
  color: rgba(0, 0, 0, 0.87);
}
</style>
