<template>
  <div></div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import messageDialogMixin from "@/mixins/messageDialogMixin";

export default defineComponent({
  mixins: [messageDialogMixin],
  mounted() {
    const status = this.$route.params.status;
    if (status === "succeed") {
      let purchaseRequired = true;
      let intervalId = setInterval(() => {
        this.$store
          .dispatch("finishCheckout")
          .then(accountInfo => {
            purchaseRequired = accountInfo.purchaseRequired;
            if (!purchaseRequired) {
              clearInterval(intervalId);
              this.successDialog("Su pago ha sido procesado con éxito", () =>
                this.$router.push("/")
              );
            }
          })
          .catch(error => {
            clearInterval(intervalId);
            this.errorDialog(
              "Ha ocurrido un error procesando su pago. Cierre e inicie sesión de nuevamente. Si el error persiste, comuníquese con nuestros canales comerciales",
              () => this.$router.push("/")
            );
          });
      }, 5000);
    } else if (status === "failed") {
      this.errorDialog(
        "Su pago no pudo ser procesado. Por favor, comuníquese con nuestros canales comerciales",
        this.$router.push("/")
      );
    }
  }
});
</script>
