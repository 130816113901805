<template>
  <o-card-form-layout title="Recuperar Contraseña" :actions="actions">
    <v-container class="pb-0">
      <v-row>
        <v-col cols="12" class="py-0 px-1">
          <o-password-field label="Nueva Contraseña" v-model="password" />
        </v-col>
      </v-row>
    </v-container>
  </o-card-form-layout>
</template>

<script>
import messageDialogMixin from "@/mixins/messageDialogMixin";

export default {
  name: "ChangePassword",
  mixins: [messageDialogMixin],
  data() {
    return {
      code: null,
      password: "",
      actions: [
        { type: "submit", label: "Continuar", handler: this.changePassword }
      ]
    };
  },
  methods: {
    close() {
      this.$router.push("/");
    },
    changePassword(form) {
      if (form.validate()) {
        this.$store
          .dispatch("recoverPassword", {
            code: this.code,
            password: this.password
          })
          .then(() =>
            this.successDialog(
              "Se ha efectuado el cambio de contraseña exitosamente.",
              this.close
            )
          )
          .catch(error => this.errorDialog(error.message, this.close));
      }
    },
    // TODO: Refactor to a function
    getParameterByName(name) {
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
      const results = regex.exec(location.search);
      return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
    }
  },
  created() {
    this.code = this.getParameterByName("oobCode");
    this.$store
      .dispatch("verifyCodeChangePassword", this.code)
      .then(response => {
        if (!response) {
          this.errorDialog(
            "La solicitud de cambio de contraseña es inválida.",
            this.close
          );
        }
      })
      .catch(error => {
        this.errorDialog(error.message, this.close);
      });
  }
};
</script>
