export default [
  {
    id: "introduction",
    label: "Introducción"
  },
  {
    id: "your_styles",
    label: "Sus Estilos de Comportamiento"
  },
  {
    id: "primary_style",
    label: "Estilo Primario"
  },
  {
    id: "conscious_style",
    label: "Estilo Consciente"
  },
  {
    id: "work_environment",
    label: "Ambiente de Trabajo Ideal"
  },
  {
    id: "understanding_others",
    label: "Entienda a los demás"
  },
  {
    id: "primary_style_descriptors",
    label: "Descriptores de su Estilo Primario"
  },
  {
    id: "factors_force",
    label: "Fuerza de los Factores"
  },
  {
    id: "personal_oval",
    label: "Circulo Personal"
  },
  {
    id: "personal_plan",
    label: "Plan de Progreso Personal"
  }
];
