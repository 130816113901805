const messages = {
  validation: {
    invalidEmail: "Correo Electrónico inválido",
    required: "Este campo es requerido",
    tickRecaptcha: "Debe verificar que Ud no es un robot"
  },
  errors: {
    // BEGIN of Firebase errors
    "auth/claims-too-large":
      "La carga útil de reclamaciones proporcionada a setCustomUserClaims() supera el tamaño máximo permitido de 1000 bytes.",
    "auth/email-already-exists":
      "El correo electrónico proporcionado ya está en uso por un usuario existente. Cada usuario debe tener un correo electrónico único.",
    "auth/user-not-found": "El usuario no existe",
    "auth/weak-password": "La contraseña debe tener al menos 6 caracteres.",
    "auth/email-already-in-use":
      "La dirección de correo electrónico ya está en uso.",
    "auth/network-request-failed": "Ha ocurrido un error de conexión.",
    "auth/wrong-password": "La contraseña es inválida",
    "auth/expired-action-code": "La solicitud de recuperación ha expirado.",
    "auth/invalid-action-code": "La solicitud de recuperación ha expirado.",
    "auth/too-many-requests":
      "El acceso a esta cuenta ha sido deshabilitado temporalmente debido a demasiados intentos fallidos de inicio de sesión. Puede reestrablecerlo, recuperando la contraseña o intentando más tarde.",
    "auth/id-token-expired": "El token de Firebase ha expirado.",
    "auth/id-token-revoked": "El token de Firebase ha sido revocado.",
    "auth/insufficient-permission":
      "La credencial usada para inicializar el Admin SDK tiene permisos insuficientes para acceder a los recursos de autenticación solicitados.",
    "auth/internal-error":
      "El Servidor de Autenticación encontró un error inesperado mientras trataba de procesar la petición.",
    "auth/invalid-argument":
      "Un argumento inválido fue provisto a un método de autenticación.",
    "auth/invalid-claims	":
      "Los atributos de reclamación provistos a setCustomUserClaims() son inválidos.",
    "auth/invalid-continue-uri":
      "La URL de continuación debe ser una cadena de URL válida.",
    "auth/invalid-creation-time	":
      "La hora de creación debe ser una cadena de fecha UTC válida.",
    "auth/invalid-credential":
      "La credencial usada para autenticar los SDKs del Admin no pueden ser usados para efectuar la acción deseada.",
    "auth/invalid-disabled-field":
      "El valor provisto para la propiedad de usuario desabilitada es inválido. Debe ser booleano.",
    "auth/invalid-display-name":
      "El valor provisto para la propiedad displayName del usuario es inválido. No debe ser una cadena vacía.",
    "auth/invalid-dynamic-link-domain":
      "El dominio del enlace dinámico provisto no está configurado o autorizado para el actual proyecto.",
    "auth/invalid-email":
      "El valor provisto para la propiedad email del usuario es inválido. Debe ser una cadena de correo electrónico.",
    "auth/invalid-email-verified":
      "El valor provisto para la propiedad emailVerified es inválido. Debe ser un booleano.",
    "auth/invalid-password":
      "El valor provisto para la propiedad password es inválido. Debe ser una cadena de al menos seis caracteres.",
    "auth/invalid-photo-url":
      "El valor provisto para la propiedad photoURL es inválido. Debe ser una cadena de URL.",
    // END of Firebase Errors
    invalidAccountInfo:
      "Información de la Cuenta inválida. Por favor cierre su sesión y vuelva a iniciarla. Si el error persiste, contacte nuestros canales de soporte",
    accountCreationError: "Ha ocurrido un error en la creación de la cuenta",
    // BEGIN Stripe Error Codes
    tax_id_invalid: "El Id. Fiscal suministrado es inválido."
    // END Stripe Error Codes
  }
};

export default messages;

export function getError(error) {
  if (error.code) {
    if (messages.errors[error.code]) {
      return { message: messages.errors[error.code] };
    } else {
      return { message: `Error ${error.code}` };
    }
  } else {
    return { message: error.message };
  }
}
