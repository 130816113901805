export default {
  zonesData: [
    {
      id: "1",
      type: "polygon",
      points: "1016.39,1096.00 893.00,1021.75 1016.39,948.32",
      dx: 105,
      dy: -355
    },
    {
      id: "2",
      type: "polygon",
      points: "1142.38,1024.00, 1017.72,1095.79 1017.72,948.11 1142.38,1024.00",
      dx: 93,
      dy: -355
    },
    {
      id: "3",
      type: "polygon",
      points:
        "1201,600.23 1128.09,588.11 1128.37,458.89 1328.41,493.39 1201,600.23",
      dx: 18,
      dy: -2
    },
    {
      id: "4",
      type: "polygon",
      points:
        "1254.81,661.75 1201,600.23 1328.41,493.39 1494.97,664 1254.81,661.75",
      dx: 0,
      dy: -30
    },
    {
      id: "5",
      type: "polygon",
      points:
        "1382.16,811.26 1214.93,713.71 1252.76,664 1494.97,664 1382.16,811.26",
      dx: -10,
      dy: 5
    },
    {
      id: "6",
      type: "polygon",
      points:
        "1382.16,811.26 1127.37,865.67 1126.76,736 1214.93,713.71 1382.16,811.26",
      dx: -10,
      dy: 0
    },
    {
      id: "7",
      type: "polygon",
      points:
        "1127.37,865.67 1126.76,736 1056.33,721.67 926.93,830.5 1127.37,865.67",
      dx: -10,
      dy: -10
    },
    {
      id: "8",
      type: "polygon",
      points:
        "1056.33,721.67 1003.25,664 766.34,664 926.93,830.5 1056.33,721.67",
      dx: -20,
      dy: 30
    },
    {
      id: "9",
      type: "polygon",
      points:
        "1035.76,612.18 1003.25,664 766.34,664 873.18,518.61 1035.76,612.18",
      dx: 0,
      dy: 0
    },
    {
      id: "10",
      type: "polygon",
      points:
        "1125.87,589.36 1035.76,612.18 873.18,518.61 1127.37,459.55 1125.87,589.36",
      dx: 0,
      dy: 0
    },
    {
      id: "11",
      type: "polygon",
      points:
        "1163.17,461.54 1128.37,458.89 1127.37,328.82 1184.54,332.21 1163.17,461.54",
      dx: 0,
      dy: 0
    },
    {
      id: "12",
      type: "polygon",
      points:
        "1199.67,464.86 1163.17,461.54 1184.54,332.21 1244.8,335.46 1199.67,464.86",
      dx: 0,
      dy: 0
    },
    {
      id: "13",
      type: "polygon",
      points:
        "1238.64,469.1 1199.67,464.86 1244.8,335.46 1310.18,345.05 1238.64,469.1",
      dx: 0,
      dy: 0
    },
    {
      id: "14",
      type: "polygon",
      points:
        "1284.39,475.38 1238.64,469.1 1310.18,345.05 1379.5,360.01 1284.39,475.38",
      dx: 0,
      dy: 0
    },
    {
      id: "15",
      type: "polygon",
      points:
        "1328.41,493.39 1284.39,475.38 1379.5,360.01 1456.87,385.74 1328.41,493.39",
      dx: 0,
      dy: 0
    },
    {
      id: "16",
      type: "polygon",
      points:
        "1385.72,514.3 1328.41,493.39 1456.87,385.74 1542.08,425.05 1385.72,514.3",
      dx: -12,
      dy: 0
    },
    {
      id: "17",
      type: "polygon",
      points:
        "1433.94,551.45 1385.72,514.3 1542.08,425.05 1627.07,481.17 1433.94,551.45",
      dx: 0,
      dy: 0
    },
    {
      id: "18",
      type: "polygon",
      points:
        "1480.37,600.9 1433.94,551.45 1627.07,481.17 1692.06,562.41 1480.37,600.9",
      dx: 0,
      dy: -5
    },
    {
      id: "19",
      type: "polygon",
      points:
        "1494.97,664 1480.37,600.9 1692.06,562.41 1720.59,664 1494.97,664",
      dx: 0,
      dy: 0
    },
    {
      id: "20",
      type: "polygon",
      points:
        "1720.59,664 1494.97,664 1476.87,723.5 1692.72,762.81 1720.59,664",
      dx: -43,
      dy: 10
    },
    {
      id: "21",
      type: "polygon",
      points:
        "1627.23,845.89 1432.92,775.16 1476.87,723.5 1692.72,762.81 1627.23,845.89",
      dx: 0,
      dy: 0
    },
    {
      id: "22",
      type: "polygon",
      points:
        "1540.76,903.5 1382.16,811.26 1432.92,775.16 1627.23,845.89 1540.76,903.5",
      dx: 0,
      dy: 0
    },
    {
      id: "23",
      type: "polygon",
      points:
        "1459.13,943.31 1327.8,832.94 1382.16,811.26 1540.76,903.5 1459.13,943.31",
      dx: 0,
      dy: 0
    },
    {
      id: "24",
      type: "polygon",
      points:
        "1380.83,965.87 1459.13,943.31 1327.8,832.94 1280.76,847.76 1380.83,965.87",
      dx: 10,
      dy: 0
    },
    {
      id: "25",
      type: "polygon",
      points:
        "1309.87,980.5 1380.83,965.87 1280.76,847.76 1237.81,855.92 1309.87,980.5",
      dx: 5,
      dy: 0
    },
    {
      id: "26",
      type: "polygon",
      points:
        "1244.13,991.75 1309.87,980.5 1237.81,855.92 1195.87,863.5 1244.13,991.75",
      dx: 5,
      dy: 0
    },
    {
      id: "27",
      type: "polygon",
      points:
        "1184.87,995.5 1244.13,991.75 1195.87,863.5 1161.81,864.9 1184.87,995.5",
      dx: 5,
      dy: 0
    },
    {
      id: "28",
      type: "polygon",
      points:
        "1127.37,997.06 1184.87,995.5 1161.81,864.9 1127.37,865.67 1127.37,997.06",
      dx: 5,
      dy: 0
    },
    {
      id: "29",
      type: "polygon",
      points:
        "1066.87,994.5 1127.37,997.06 1127.37,865.67 1090.18,863.68 1066.87,994.5",
      dx: 5,
      dy: 0
    },
    {
      id: "30",
      type: "polygon",
      points:
        "1008.55,989.76 1066.87,994.5 1090.18,863.68 1055.67,861.69 1008.55,989.76",
      dx: 5,
      dy: 0
    },
    {
      id: "31",
      type: "polygon",
      points:
        "944.87,978.5 1008.55,989.76 1055.67,861.69 1016.75,853.86 944.87,978.5",
      dx: 5,
      dy: 0
    },
    {
      id: "32",
      type: "polygon",
      points:
        "1016.75,853.86 944.87,978.5 874.51,963.22 971.39,845.76 1016.75,853.86",
      dx: -20,
      dy: 25
    },
    {
      id: "33",
      type: "polygon",
      points:
        "971.39,845.76 874.51,963.22 797.05,939.8 926.93,830.5 971.39,845.76",
      dx: -20,
      dy: 15
    },
    {
      id: "34",
      type: "polygon",
      points:
        "797.05,939.8 717.9,899.51 878.07,807.22 926.93,830.5 797.05,939.8",
      dx: 0,
      dy: -5
    },
    {
      id: "35",
      type: "polygon",
      points:
        "717.9,899.51 632.96,845.1 824.74,772.77 878.07,807.22 717.9,899.51",
      dx: 0,
      dy: -2
    },
    {
      id: "36",
      type: "polygon",
      points:
        "632.96,845.1 566.6,763.48 781.87,724.5 824.74,772.77 632.96,845.1",
      dx: 0,
      dy: -2
    },
    {
      id: "37",
      type: "polygon",
      points: "781.87,724.5 566.6,763.48 538.07,664 766.34,664 781.87,724.5",
      dx: 0,
      dy: -2
    },
    {
      id: "38",
      type: "polygon",
      points: "766.34,664 538.07,664 566.6,566.39 782.93,601.56 766.34,664",
      dx: 0,
      dy: 0
    },
    {
      id: "39",
      type: "polygon",
      points:
        "782.93,601.56 566.6,566.39 631.47,483.46 823.93,553.51 782.93,601.56",
      dx: 0,
      dy: -3
    },
    {
      id: "40",
      type: "polygon",
      points:
        "823.93,553.51 631.47,483.46 714.58,427.04 873.18,518.61 823.93,553.51",
      dx: 0,
      dy: -1
    },
    {
      id: "41",
      type: "polygon",
      points:
        "873.18,518.61 714.58,427.04 795.6,386.16 924.85,494.66 873.18,518.61",
      dx: 3,
      dy: -1
    },
    {
      id: "42",
      type: "polygon",
      points:
        "972.16,479.99 924.85,494.66 795.6,386.16 873.85,362.67 972.16,479.99",
      dx: -4,
      dy: 0
    },
    {
      id: "43",
      type: "polygon",
      points:
        "1014.65,470.18 972.16,479.99 873.85,362.67 942.86,346.74 1014.65,470.18",
      dx: -5,
      dy: 0
    },
    {
      id: "44",
      type: "polygon",
      points:
        "1055.67,464.86 1014.65,470.18 942.86,346.74 1006.57,336.79 1055.67,464.86",
      dx: -5,
      dy: 0
    },
    {
      id: "45",
      type: "polygon",
      points:
        "1090.51,461.18 1055.67,464.86 1006.57,336.79 1067.19,329.15 1090.51,461.18",
      dx: -4,
      dy: 0
    },
    {
      id: "46",
      type: "polygon",
      points:
        "1128.37,458.89 1090.51,461.18 1067.19,329.15 1127.37,328.82 1128.37,458.89",
      dx: -5,
      dy: 0
    },
    {
      id: "47",
      type: "polygon",
      points:
        "1244.8,335.46 1127.37,328.82 1125.87,233 1279.3,241.23 1244.8,335.46",
      dx: -10,
      dy: -10
    },
    {
      id: "48",
      type: "polygon",
      points:
        "1379.5,360.01 1310.18,345.05 1244.8,335.46 1279.3,241.23 1455.82,272.42 1379.5,360.01",
      dx: 0,
      dy: -20
    },
    {
      id: "49",
      type: "polygon",
      points:
        "1542.08,425.05 1456.87,385.74 1379.5,360.01 1455.82,272.42 1667.53,351.65 1542.08,425.05",
      dx: 0,
      dy: -20
    },
    {
      id: "50",
      type: "polygon",
      points:
        "1692.06,562.41 1627.07,481.17 1542.08,425.05 1667.53,351.65 1864.59,533.21 1692.06,562.41",
      dx: 20,
      dy: -30
    },
    {
      id: "51",
      type: "polygon",
      points:
        "1900.42,664 1720.59,664 1692.06,562.41 1864.59,533.21 1900.42,664",
      dx: -10,
      dy: -5
    },
    {
      id: "52",
      type: "polygon",
      points:
        "1863.93,793.34 1692.72,762.81 1720.59,664 1900.42,664 1863.93,793.34",
      dx: -5,
      dy: -10
    },
    {
      id: "53",
      type: "polygon",
      points:
        "1664.85,974.5 1540.76,903.5 1627.23,845.89 1692.72,762.81 1863.93,793.34 1664.85,974.5",
      dx: 20,
      dy: 0
    },
    {
      id: "54",
      type: "polygon",
      points:
        "1453.38,1053.49 1380.83,965.87 1459.13,943.31 1535.45,906.15 1664.85,974.5 1453.38,1053.49",
      dx: 20,
      dy: 0
    },
    {
      id: "55",
      type: "polygon",
      points:
        "1453.38,1053.49 1279.3,1086.65 1244.13,991.75 1380.83,965.87 1453.38,1053.49",
      dx: -30,
      dy: 0
    },
    {
      id: "56",
      type: "polygon",
      points:
        "1279.3,1086.65 1125.87,1094 1127.37,997.06 1244.13,991.75 1279.3,1086.65",
      dx: -20,
      dy: -5
    },
    {
      id: "57",
      type: "polygon",
      points:
        "1125.87,1094 972.72,1083.99 1008.55,989.76 1127.37,997.06 1125.87,1094",
      dx: -15,
      dy: 0
    },
    {
      id: "58",
      type: "polygon",
      points:
        "972.72,1083.99 800.98,1051.56 874.51,963.22 1008.55,989.76 972.72,1083.99",
      dx: -15,
      dy: 0
    },
    {
      id: "59",
      type: "polygon",
      points:
        "800.98,1051.56 593.34,971.55 717.9,899.51 797.05,939.8 874.51,963.22 800.98,1051.56",
      dx: -15,
      dy: 0
    },
    {
      id: "60",
      type: "polygon",
      points:
        "717.9,899.51 593.34,971.55 398.71,792.68 566.6,763.48 632.96,845.1 717.9,899.51",
      dx: -35,
      dy: 5
    },
    {
      id: "61",
      type: "polygon",
      points:
        "400.37,792.35 359.23,666.6 538.07,664 566.6,763.48 400.37,792.35",
      dx: 10,
      dy: -15
    },
    {
      id: "62",
      type: "polygon",
      points: "359.56,664 395.39,534.54 566.6,566.39 538.07,664 359.56,664",
      dx: 15,
      dy: -5
    },
    {
      id: "63",
      type: "polygon",
      points:
        "566.6,566.39 395.39,534.54 589.87,354.5 714.58,427.04 631.47,483.46 566.6,566.39",
      dx: -20,
      dy: -25
    },
    {
      id: "64",
      type: "polygon",
      points:
        "873.85,362.67 795.6,386.16 714.58,427.04 589.87,354.5 800.41,275.31 873.85,362.67",
      dx: -25,
      dy: -15
    },
    {
      id: "65",
      type: "polygon",
      points:
        "1006.57,336.79 942.86,346.74 873.85,362.67 800.41,275.31 972.06,242.56 1006.57,336.79",
      dx: -10,
      dy: -15
    },
    {
      id: "66",
      type: "polygon",
      points:
        "1127.37,328.82 1067.19,329.15 1006.57,336.79 972.06,242.56 1125.87,233 1127.37,328.82",
      dx: -10,
      dy: -15
    },
    {
      id: "67",
      type: "polygon",
      points:
        "1279.3,241.23 1125.87,233 972.06,242.56 950.82,178.85 1129.87,168 1304.52,176.2 1279.3,241.23",
      dx: -20,
      dy: -10
    },
    {
      id: "68",
      type: "polygon",
      points:
        "1864.59,533.21 1667.53,351.65 1455.82,272.42 1279.3,241.23 1304.52,176.2 1644.94,263.79 1970.1,514.63 1864.59,533.21",
      dx: 50,
      dy: -40
    },
    {
      id: "69",
      type: "polygon",
      points:
        "1967.45,812.58 1863.93,793.34 1900.42,664 1864.59,533.21 1970.1,514.63 2009.59,663 1967.45,812.58",
      dx: 0,
      dy: -20
    },
    {
      id: "70",
      type: "polygon",
      points:
        "1303.85,1149.69 1279.3,1086.65 1453.38,1053.49 1664.85,974.5 1863.93,793.34 1967.45,812.58 1741.16,1018.3 1303.85,1149.69",
      dx: 70,
      dy: 20
    },
    {
      id: "71",
      type: "polygon",
      points:
        "1303.85,1149.69 1127.37,1159.64 948.17,1149.69 972.72,1083.99 1125.87,1094 1279.3,1086.65 1303.85,1149.69",
      dx: -25,
      dy: 5
    },
    {
      id: "72",
      type: "polygon",
      points:
        "948.17,1149.69 519.49,1019.62 290.55,811.92 398.71,792.68 593.34,971.55 800.98,1051.56 972.72,1083.99 948.17,1149.69",
      dx: -100,
      dy: 10
    },
    {
      id: "73",
      type: "polygon",
      points:
        "398.71,792.68 290.55,811.92 250.07,665.04 289.22,515.96 395.39,534.54 359.56,664 398.71,792.68",
      dx: -20,
      dy: -20
    },
    {
      id: "74",
      type: "polygon",
      points:
        "395.39,533.54 289.22,514.96 548.68,292.65 950.82,177.85 972.06,241.56 800.41,274.31 589.87,353.5 395.39,533.54",
      dx: -30,
      dy: -50
    }
  ]
};
