<template>
  <v-row class="ma-0 pt-0" v-if="isXS">
    <v-col class="text-center px-0 py-6 o-label">
      Para visualizar esta sección, rotar el dispositivo en sentido horizontal.
    </v-col>
  </v-row>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  computed: {
    isXS() {
      return this.$vuetify.breakpoint.name == "xs";
    }
  }
});
</script>
