import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
// TODO: Improve this export by injecting the signout callback in some way
import firebase from "@/plugins/firebase";
import store from "./store/index";
import "./registerServiceWorker";
import VueGoogleCharts from "vue-google-charts";
import VueCompositionAPI from "@vue/composition-api";

import AutocompleteField from "@/components/forms/AutocompleteField";
import Captcha from "@/components/forms/Captcha";
import CardLayout from "@/components/layouts/CardLayout";
import CardFormLayout from "@/components/layouts/CardFormLayout";
import EmailField from "@/components/forms/EmailField";
import MessageDialog from "@/components/dialogs/MessageDialog";
import PageLayout from "@/components/layouts/PageLayout";
import PasswordField from "@/components/forms/PasswordField";
import TextField from "@/components/forms/TextField";
import DropdownMenu from "@/components/menus/DropdownMenu";

Vue.use(VueGoogleCharts);

Vue.config.productionTip = false;

Vue.component("o-autocomplete-field", AutocompleteField);
Vue.component("o-captcha", Captcha);
Vue.component("o-card-layout", CardLayout);
Vue.component("o-card-form-layout", CardFormLayout);
Vue.component("o-email-field", EmailField);
Vue.component("o-message-dialog", MessageDialog);
Vue.component("o-page-layout", PageLayout);
Vue.component("o-password-field", PasswordField);
Vue.component("o-text-field", TextField);
Vue.component("o-dropdown-menu", DropdownMenu);

Vue.filter("currency", value => {
  const number = Number.parseFloat(value);
  if (Number.isNaN(number)) {
    return Number.NaN;
  } else {
    return number.toLocaleString("es-ES");
  }
});

const vue = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

// TODO: Improve this export by injecting the signout callback in some way
firebase.auth().onAuthStateChanged(function (user) {
  if (!user) {
    vue.$store.commit("setUser", null);
  }
});
