import { PRIMARY, CONSCIOUS } from "./constants";

const percentages = {};

percentages[PRIMARY] = {};
percentages[CONSCIOUS] = {};

percentages[PRIMARY]["D"] = {
  0: { percentage: 100, intensity: 7 },
  1: { percentage: 92, intensity: 7 },
  2: { percentage: 84, intensity: 6 },
  3: { percentage: 76, intensity: 6 },
  4: { percentage: 68, intensity: 5 },
  5: { percentage: 60, intensity: 5 },
  6: { percentage: 52, intensity: 4 },
  7: { percentage: 44, intensity: 3 },
  8: { percentage: 36, intensity: 3 },
  9: { percentage: 28, intensity: 2 },
  10: { percentage: 20, intensity: 2 },
  11: { percentage: 12, intensity: 1 },
  12: { percentage: 12, intensity: 1 },
  13: { percentage: 10, intensity: 1 },
  14: { percentage: 10, intensity: 1 },
  15: { percentage: 10, intensity: 1 },
  16: { percentage: 10, intensity: 1 },
  17: { percentage: 10, intensity: 1 },
  18: { percentage: 10, intensity: 1 },
  19: { percentage: 10, intensity: 1 },
  20: { percentage: 10, intensity: 1 },
  21: { percentage: 10, intensity: 1 },
  22: { percentage: 10, intensity: 1 },
  23: { percentage: 10, intensity: 1 },
  24: { percentage: 10, intensity: 1 },
  25: { percentage: 10, intensity: 1 }
};

percentages[PRIMARY]["I"] = {
  0: { percentage: 100, intensity: 7 },
  1: { percentage: 93, intensity: 7 },
  2: { percentage: 85, intensity: 6 },
  3: { percentage: 79, intensity: 6 },
  4: { percentage: 71, intensity: 5 },
  5: { percentage: 65, intensity: 5 },
  6: { percentage: 58, intensity: 4 },
  7: { percentage: 51, intensity: 4 },
  8: { percentage: 43, intensity: 3 },
  9: { percentage: 37, intensity: 3 },
  10: { percentage: 29, intensity: 2 },
  11: { percentage: 22, intensity: 2 },
  12: { percentage: 14, intensity: 1 },
  13: { percentage: 10, intensity: 1 },
  14: { percentage: 10, intensity: 1 },
  15: { percentage: 10, intensity: 1 },
  16: { percentage: 10, intensity: 1 },
  17: { percentage: 10, intensity: 1 },
  18: { percentage: 10, intensity: 1 },
  19: { percentage: 10, intensity: 1 },
  20: { percentage: 10, intensity: 1 },
  21: { percentage: 10, intensity: 1 },
  22: { percentage: 10, intensity: 1 },
  23: { percentage: 10, intensity: 1 },
  24: { percentage: 10, intensity: 1 },
  25: { percentage: 10, intensity: 1 },
  26: { percentage: 10, intensity: 1 },
  27: { percentage: 10, intensity: 1 },
  28: { percentage: 10, intensity: 1 }
};

percentages[PRIMARY]["S"] = {
  0: { percentage: 100, intensity: 7 },
  1: { percentage: 93, intensity: 7 },
  2: { percentage: 85, intensity: 6 },
  3: { percentage: 79, intensity: 6 },
  4: { percentage: 71, intensity: 5 },
  5: { percentage: 65, intensity: 5 },
  6: { percentage: 58, intensity: 4 },
  7: { percentage: 51, intensity: 4 },
  8: { percentage: 43, intensity: 3 },
  9: { percentage: 37, intensity: 3 },
  10: { percentage: 29, intensity: 2 },
  11: { percentage: 22, intensity: 2 },
  12: { percentage: 14, intensity: 1 },
  13: { percentage: 10, intensity: 1 },
  14: { percentage: 10, intensity: 1 },
  15: { percentage: 10, intensity: 1 },
  16: { percentage: 10, intensity: 1 },
  17: { percentage: 10, intensity: 1 },
  18: { percentage: 10, intensity: 1 },
  19: { percentage: 10, intensity: 1 },
  20: { percentage: 10, intensity: 1 },
  21: { percentage: 10, intensity: 1 },
  22: { percentage: 10, intensity: 1 },
  23: { percentage: 10, intensity: 1 },
  24: { percentage: 10, intensity: 1 },
  25: { percentage: 10, intensity: 1 },
  26: { percentage: 10, intensity: 1 },
  27: { percentage: 10, intensity: 1 },
  28: { percentage: 10, intensity: 1 }
};

percentages[PRIMARY]["C"] = {
  0: { percentage: 100, intensity: 7 },
  1: { percentage: 92, intensity: 7 },
  2: { percentage: 85, intensity: 6 },
  3: { percentage: 77, intensity: 6 },
  4: { percentage: 70, intensity: 5 },
  5: { percentage: 62, intensity: 5 },
  6: { percentage: 54, intensity: 4 },
  7: { percentage: 46, intensity: 3 },
  8: { percentage: 38, intensity: 3 },
  9: { percentage: 31, intensity: 2 },
  10: { percentage: 23, intensity: 2 },
  11: { percentage: 15, intensity: 1 },
  12: { percentage: 15, intensity: 1 },
  13: { percentage: 10, intensity: 1 },
  14: { percentage: 10, intensity: 1 },
  15: { percentage: 10, intensity: 1 },
  16: { percentage: 10, intensity: 1 },
  17: { percentage: 10, intensity: 1 },
  18: { percentage: 10, intensity: 1 },
  19: { percentage: 10, intensity: 1 },
  20: { percentage: 10, intensity: 1 },
  21: { percentage: 10, intensity: 1 },
  22: { percentage: 10, intensity: 1 },
  23: { percentage: 10, intensity: 1 },
  24: { percentage: 10, intensity: 1 },
  25: { percentage: 10, intensity: 1 }
};

percentages[CONSCIOUS]["D"] = {
  0: { percentage: 10, intensity: 1 },
  1: { percentage: 16, intensity: 1 },
  2: { percentage: 24, intensity: 2 },
  3: { percentage: 32, intensity: 2 },
  4: { percentage: 40, intensity: 3 },
  5: { percentage: 48, intensity: 3 },
  6: { percentage: 56, intensity: 4 },
  7: { percentage: 64, intensity: 5 },
  8: { percentage: 72, intensity: 5 },
  9: { percentage: 80, intensity: 6 },
  10: { percentage: 88, intensity: 6 },
  11: { percentage: 96, intensity: 7 },
  12: { percentage: 96, intensity: 7 },
  13: { percentage: 100, intensity: 7 },
  14: { percentage: 100, intensity: 7 },
  15: { percentage: 100, intensity: 7 },
  16: { percentage: 100, intensity: 7 },
  17: { percentage: 100, intensity: 7 },
  18: { percentage: 100, intensity: 7 },
  19: { percentage: 100, intensity: 7 },
  20: { percentage: 100, intensity: 7 },
  21: { percentage: 100, intensity: 7 },
  22: { percentage: 100, intensity: 7 },
  23: { percentage: 100, intensity: 7 },
  24: { percentage: 100, intensity: 7 },
  25: { percentage: 100, intensity: 7 }
};

percentages[CONSCIOUS]["I"] = {
  0: { percentage: 10, intensity: 1 },
  1: { percentage: 10, intensity: 1 },
  2: { percentage: 14, intensity: 1 },
  3: { percentage: 21, intensity: 2 },
  4: { percentage: 29, intensity: 2 },
  5: { percentage: 35, intensity: 3 },
  6: { percentage: 43, intensity: 3 },
  7: { percentage: 51, intensity: 4 },
  8: { percentage: 58, intensity: 4 },
  9: { percentage: 64, intensity: 5 },
  10: { percentage: 71, intensity: 5 },
  11: { percentage: 78, intensity: 6 },
  12: { percentage: 85, intensity: 6 },
  13: { percentage: 92, intensity: 7 },
  14: { percentage: 99, intensity: 7 },
  15: { percentage: 99, intensity: 7 },
  16: { percentage: 99, intensity: 7 },
  17: { percentage: 99, intensity: 7 },
  18: { percentage: 99, intensity: 7 },
  19: { percentage: 99, intensity: 7 },
  20: { percentage: 99, intensity: 7 },
  21: { percentage: 99, intensity: 7 },
  22: { percentage: 100, intensity: 7 },
  23: { percentage: 100, intensity: 7 },
  24: { percentage: 100, intensity: 7 },
  25: { percentage: 100, intensity: 7 },
  26: { percentage: 100, intensity: 7 },
  27: { percentage: 100, intensity: 7 },
  28: { percentage: 100, intensity: 7 }
};

percentages[CONSCIOUS]["S"] = {
  0: { percentage: 10, intensity: 1 },
  1: { percentage: 10, intensity: 1 },
  2: { percentage: 14, intensity: 1 },
  3: { percentage: 21, intensity: 2 },
  4: { percentage: 29, intensity: 2 },
  5: { percentage: 35, intensity: 3 },
  6: { percentage: 43, intensity: 3 },
  7: { percentage: 51, intensity: 4 },
  8: { percentage: 58, intensity: 4 },
  9: { percentage: 64, intensity: 5 },
  10: { percentage: 71, intensity: 5 },
  11: { percentage: 78, intensity: 6 },
  12: { percentage: 85, intensity: 6 },
  13: { percentage: 92, intensity: 7 },
  14: { percentage: 99, intensity: 7 },
  15: { percentage: 99, intensity: 7 },
  16: { percentage: 99, intensity: 7 },
  17: { percentage: 99, intensity: 7 },
  18: { percentage: 99, intensity: 7 },
  19: { percentage: 99, intensity: 7 },
  20: { percentage: 99, intensity: 7 },
  21: { percentage: 99, intensity: 7 },
  22: { percentage: 100, intensity: 7 },
  23: { percentage: 100, intensity: 7 },
  24: { percentage: 100, intensity: 7 },
  25: { percentage: 100, intensity: 7 },
  26: { percentage: 100, intensity: 7 },
  27: { percentage: 100, intensity: 7 },
  28: { percentage: 100, intensity: 7 }
};

percentages[CONSCIOUS]["C"] = {
  0: { percentage: 10, intensity: 1 },
  1: { percentage: 15, intensity: 1 },
  2: { percentage: 23, intensity: 2 },
  3: { percentage: 31, intensity: 2 },
  4: { percentage: 39, intensity: 3 },
  5: { percentage: 46, intensity: 3 },
  6: { percentage: 54, intensity: 4 },
  7: { percentage: 62, intensity: 5 },
  8: { percentage: 69, intensity: 5 },
  9: { percentage: 77, intensity: 6 },
  10: { percentage: 85, intensity: 6 },
  11: { percentage: 92, intensity: 7 },
  12: { percentage: 92, intensity: 7 },
  13: { percentage: 100, intensity: 7 },
  14: { percentage: 100, intensity: 7 },
  15: { percentage: 100, intensity: 7 },
  16: { percentage: 100, intensity: 7 },
  17: { percentage: 100, intensity: 7 },
  18: { percentage: 100, intensity: 7 },
  19: { percentage: 100, intensity: 7 },
  20: { percentage: 100, intensity: 7 },
  21: { percentage: 100, intensity: 7 },
  22: { percentage: 100, intensity: 7 },
  23: { percentage: 100, intensity: 7 },
  24: { percentage: 100, intensity: 7 },
  25: { percentage: 100, intensity: 7 },
  26: { percentage: 100, intensity: 7 }
};

export default percentages;
