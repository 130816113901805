import phrases from "./phrases";
import workEnvironment from "./workEnvironment";

const profiles = [
  {
    code: "D",
    id: 1,
    key: "3000",
    name: "Dominante",
    ovalId: 68
  },
  {
    code: "I",
    id: 2,
    key: "0300",
    name: "Influyente",
    ovalId: 70
  },
  {
    code: "S",
    id: 3,
    key: "0030",
    name: "Sostenedor",
    ovalId: 72
  },
  {
    code: "C",
    id: 4,
    key: "0003",
    name: "Cauteloso",
    ovalId: 74
  },
  {
    code: "Di",
    id: 5,
    key: "3200",
    name: "Pionero",
    ovalId: 51
  },
  {
    code: "DI",
    id: 6,
    key: "3300",
    name: "Pionero",
    ovalId: 69
  },
  {
    code: "Id",
    id: 7,
    key: "2300",
    name: "Pionero",
    ovalId: 52
  },
  {
    code: "Ds",
    id: 8,
    key: "3020",
    name: "Director Conciliador",
    ovalId: 49
  },
  {
    code: "DS",
    id: 9,
    key: "3030",
    name: "Director Conciliador",
    ovalId: 2
  },
  {
    code: "Sd",
    id: 10,
    key: "2030",
    name: "Conciliador Director",
    ovalId: 59
  },
  {
    code: "Dc",
    id: 11,
    key: "3002",
    name: "Realizador",
    ovalId: 47
  },
  {
    code: "DC",
    id: 12,
    key: "3003",
    name: "Realizador",
    ovalId: 67
  },
  {
    code: "Cd",
    id: 13,
    key: "2003",
    name: "Realizador",
    ovalId: 66
  },
  {
    code: "Is",
    id: 14,
    key: "0320",
    name: "Relacionador",
    ovalId: 56
  },
  {
    code: "IS",
    id: 15,
    key: "0330",
    name: "Relacionador",
    ovalId: 71
  },
  {
    code: "Si",
    id: 16,
    key: "0230",
    name: "Relacionador",
    ovalId: 57
  },
  {
    code: "Ic",
    id: 17,
    key: "0302",
    name: "Motivador Analizador",
    ovalId: 54
  },
  {
    code: "IC",
    id: 18,
    key: "0303",
    name: "Analizador Motivador",
    ovalId: 1
  },
  {
    code: "Ci",
    id: 19,
    key: "0203",
    name: "Analizador Motivador",
    ovalId: 64
  },
  {
    code: "Sc",
    id: 20,
    key: "0032",
    name: "Coordinador",
    ovalId: 61
  },
  {
    code: "SC",
    id: 21,
    key: "0033",
    name: "Coordinador",
    ovalId: 73
  },
  {
    code: "Cs",
    id: 22,
    key: "0023",
    name: "Coordinador",
    ovalId: 62
  },
  {
    code: "Dis",
    id: 23,
    key: "3210",
    name: "Pionero Conciliador",
    ovalId: 18
  },
  {
    code: "Di=s",
    id: 24,
    key: "3220",
    name: "Director Relacionador",
    ovalId: 17
  },
  {
    code: "DSi",
    id: 25,
    key: "3230",
    name: "Director Conciliador Motivador",
    ovalId: 5
  },
  {
    code: "Dsi",
    id: 26,
    key: "3120",
    name: "Director Relacionador",
    ovalId: 19
  },
  {
    code: "Dic",
    id: 27,
    key: "3201",
    name: "Pionero Analizador",
    ovalId: 14
  },
  {
    code: "Di=c",
    id: 28,
    key: "3202",
    name: "Director Motivador Analizador",
    ovalId: 15
  },
  {
    code: "DCi",
    id: 29,
    key: "3203",
    name: "Realizador Motivador",
    ovalId: 50
  },
  {
    code: "Dci",
    id: 30,
    key: "3102",
    name: "Realizador Motivador",
    ovalId: 16
  },
  {
    code: "Dsc",
    id: 31,
    key: "3021",
    name: "Director Coordinador",
    ovalId: 11
  },
  {
    code: "Ds=c",
    id: 32,
    key: "3022",
    name: "Director Coordinador",
    ovalId: 12
  },
  {
    code: "DSc",
    id: 33,
    key: "3032",
    name: "Director Conciliador Analizador",
    ovalId: 3
  },
  {
    code: "Dcs",
    id: 34,
    key: "3012",
    name: "Realizador Conciliador",
    ovalId: 13
  },
  {
    code: "Ids",
    id: 35,
    key: "2310",
    name: "Pionero Conciliador",
    ovalId: 20
  },
  {
    code: "Id=s",
    id: 36,
    key: "2320",
    name: "Motivador Director Conciliador",
    ovalId: 21
  },
  {
    code: "IDs",
    id: 37,
    key: "3320",
    name: "Pionero Conciliador",
    ovalId: 53
  },
  {
    code: "Isd",
    id: 38,
    key: "1320",
    name: "Relacionador Director",
    ovalId: 22
  },
  {
    code: "Idc",
    id: 39,
    key: "2301",
    name: "Pionero Analizador",
    ovalId: 23
  },
  {
    code: "Id=c",
    id: 40,
    key: "2302",
    name: "Motivador Realizador",
    ovalId: 24
  },
  {
    code: "IDc",
    id: 41,
    key: "3302",
    name: "Pionero Analizador",
    ovalId: 48
  },
  {
    code: "Icd",
    id: 42,
    key: "1302",
    name: "Motivador Realizador",
    ovalId: 25
  },
  {
    code: "Isc",
    id: 43,
    key: "0321",
    name: "Relacionador Analizador",
    ovalId: 26
  },
  {
    code: "Is=c",
    id: 44,
    key: "0322",
    name: "Motivador Coordinador",
    ovalId: 27
  },
  {
    code: "ISc",
    id: 45,
    key: "0332",
    name: "Relacionador Analizador",
    ovalId: 55
  },
  {
    code: "Ics",
    id: 46,
    key: "0312",
    name: "Motivador Coordinador",
    ovalId: 28
  },
  {
    code: "Sdi",
    id: 47,
    key: "2130",
    name: "Conciliador Pionero",
    ovalId: 29
  },
  {
    code: "Sd=i",
    id: 48,
    key: "2230",
    name: "Conciliador Pionero",
    ovalId: 30
  },
  {
    code: "SId",
    id: 49,
    key: "2330",
    name: "Relacionador Director",
    ovalId: 58
  },
  {
    code: "Sid",
    id: 50,
    key: "1230",
    name: "Relacionador Director",
    ovalId: 31
  },
  {
    code: "Sic",
    id: 51,
    key: "0231",
    name: "Relacionador Analizador",
    ovalId: 32
  },
  {
    code: "Si=c",
    id: 52,
    key: "0232",
    name: "Conciliador Motivador Analizador",
    ovalId: 33
  },
  {
    code: "SCi",
    id: 53,
    key: "0233",
    name: "Coordinador Motivador",
    ovalId: 65
  },
  {
    code: "Sci",
    id: 54,
    key: "0132",
    name: "Coordinador Motivador",
    ovalId: 34
  },
  {
    code: "Sdc",
    id: 55,
    key: "2031",
    name: "Conciliador Realizador",
    ovalId: 35
  },
  {
    code: "Sd=c",
    id: 56,
    key: "2032",
    name: "Conciliador Realizador",
    ovalId: 36
  },
  {
    code: "SCd",
    id: 57,
    key: "2033",
    name: "Coordinador Director",
    ovalId: 60
  },
  {
    code: "Scd",
    id: 58,
    key: "1032",
    name: "Coordinador Director",
    ovalId: 37
  },
  {
    code: "Cdi",
    id: 59,
    key: "2103",
    name: "Realizador Motivador",
    ovalId: 44
  },
  {
    code: "Cd=i",
    id: 60,
    key: "2203",
    name: "Analizador Pionero",
    ovalId: 45
  },
  {
    code: "CId",
    id: 61,
    key: "2303",
    name: "Analizador Motivador Director",
    ovalId: 9
  },
  {
    code: "Cid",
    id: 62,
    key: "1203",
    name: "Analizador Pionero",
    ovalId: 46
  },
  {
    code: "Cis",
    id: 63,
    key: "0213",
    name: "Analizador Relacionador",
    ovalId: 38
  },
  {
    code: "Ci=s",
    id: 64,
    key: "0223",
    name: "Analizador Relacionador",
    ovalId: 39
  },
  {
    code: "CIs",
    id: 65,
    key: "0323",
    name: "Analizador Motivador Conciliador",
    ovalId: 7
  },
  {
    code: "Csi",
    id: 66,
    key: "0123",
    name: "Coordinador Motivador",
    ovalId: 40
  },
  {
    code: "Cds",
    id: 67,
    key: "2013",
    name: "Realizador Conciliador",
    ovalId: 41
  },
  {
    code: "Cd=s",
    id: 68,
    key: "2023",
    name: "Analizador Director Conciliador",
    ovalId: 42
  },
  {
    code: "CDs",
    id: 69,
    key: "3023",
    name: "Realizador Conciliador",
    ovalId: 63
  },
  {
    code: "Csd",
    id: 70,
    key: "1023",
    name: "Coordinador Director",
    ovalId: 43
  },
  {
    code: "DIS",
    id: 71,
    key: "3330",
    name: "Director Relacionador",
    ovalId: 4
  },
  {
    code: "ICD",
    id: 72,
    key: "3303",
    name: "Motivador Realizador",
    ovalId: 6
  },
  {
    code: "SCD",
    id: 73,
    key: "3033",
    name: "Conciliador Realizador",
    ovalId: 8
  },
  {
    code: "CSI",
    id: 74,
    key: "0333",
    name: "Analizador Relacionador",
    ovalId: 10
  }
];

function calculateKey(data) {
  const key = {};
  let factors = [];
  // Profiles 1 to 4
  for (let factor in data) {
    const value = data[factor].percentage;
    if (value < 50) {
      key[factor] = 0;
    } else {
      key[factor] = 3;
      factors.push({ factor: factor, value: value });
    }
  }
  // Profiles 5 to 74
  if (factors.length == 2) {
    // Profiles 5 to 22
    const rest = factors[0].value - factors[1].value;
    if (rest >= 10) {
      key[factors[1].factor]--;
    } else if (rest <= -10) {
      key[factors[0].factor]--;
    }
  } else if (factors.length == 3) {
    // Profiles 23 to 74
    factors = factors.sort((a, b) => b.value - a.value);
    let firstTwoAreEqual = true;
    if (factors[0].value - factors[1].value > 0) {
      key[factors[1].factor]--;
      firstTwoAreEqual = false;
    }
    if (factors[0].value - factors[2].value > 0) {
      key[factors[2].factor]--;
    }
    if (!firstTwoAreEqual && factors[1].value - factors[2].value > 0) {
      key[factors[2].factor]--;
    }
  }
  return (
    key.D.toString() + key.I.toString() + key.S.toString() + key.C.toString()
  );
}

/**
 * Attach the phrases of a profile to the data of the specific profile.
 * @param {*} profile
 * @param {*} profileData
 */
function attachPhrases(profile, profileData) {
  const profilePhrases = phrases[profile.id];
  if (Array.isArray(profilePhrases)) {
    profileData.phrases = profilePhrases;
  } else if (profilePhrases.get) {
    let percentage = null;
    if (profile.code == "D") {
      percentage = profileData.values.D.percentage;
    } else if (profile.code == "I") {
      percentage = profileData.values.I.percentage;
    } else if (profile.code == "S") {
      percentage = profileData.values.S.percentage;
    } else if (profile.code == "C") {
      percentage = profileData.values.C.percentage;
    }
    profileData.phrases = profilePhrases.get(percentage);
  }
}

function attachWorkEnvironment(profile, profileData) {
  const workEnvironmentText = workEnvironment[profile.id];
  if (typeof workEnvironmentText == "string") {
    profileData.workEnvironment = workEnvironmentText;
  } else if (workEnvironmentText.get) {
    let percentage = null;
    if (profile.code == "D") {
      percentage = profileData.values.D.percentage;
    } else if (profile.code == "I") {
      percentage = profileData.values.I.percentage;
    } else if (profile.code == "S") {
      percentage = profileData.values.S.percentage;
    } else if (profile.code == "C") {
      percentage = profileData.values.C.percentage;
    }
    profileData.workEnvironment = workEnvironmentText.get(percentage);
  }
}

// TODO: Move calculeKey to SubmitAssessment?
function processProfile(profileData) {
  profileData.key = calculateKey(profileData.values);
  const selectedProfile = profiles.find(
    profile => profile.key == profileData.key
  );
  profileData.id = selectedProfile.id;
  profileData.code = selectedProfile.code;
  profileData.name = selectedProfile.name;
  profileData.ovalId = selectedProfile.ovalId;
  attachPhrases(selectedProfile, profileData);
  attachWorkEnvironment(selectedProfile, profileData);
}

export default {
  populate(data) {
    processProfile(data.profiles.primary);
    processProfile(data.profiles.conscious);
  }
};
