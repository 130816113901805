export default [
  // D
  {
    factor: "D",
    approach: "¿Cómo responde a problemas y retos?",
    descriptors: [
      "Aventurero",
      "Atrevido",
      "Decidido",
      "Directo",
      "Creativo",
      "Innovador",
      "Impaciente",
      "Asertivo",
      "Resuelve problemas",
      "Con iniciativa",
      "Dominante",
      "Osado",
      "Competitivo",
      "Activo",
      "Rápido",
      "Orientado a los resultados"
    ],
    oppositeDescriptors: [
      "Paciente",
      "Tradicional",
      "Tranquilo",
      "Discreto",
      "Calmado",
      "Conforme",
      "Amable",
      "Buen Escucha",
      "Predecible",
      "Estable",
      "Conservador",
      "Complaciente"
    ],
    interpretation:
      "De ritmo muy rápido y actitud determinada a la hora de enfrentar nuevos problemas. Se enfoca en obtener resultados. Su actitud es activa, directa y arriesgada, incluso cuando los resultados pudieran estar equivocados.",
    oppositeInterpretation:
      "De ritmo pausado por su tendencia a pensar mucho antes de actuar. Responde a los problemas nuevos de una forma premeditada y organizada. Investiga y trata de controlar todos los aspectos. Prefiere resolver los problemas de rutina y no los nuevos."
  },
  // I
  {
    factor: "I",
    approach: "¿Cómo interactúa con los demás y se relaciona?",
    descriptors: [
      "Encantador",
      "Confidente",
      "Convincente",
      "Entusiasta",
      "Inspirado",
      "Optimista",
      "Persuasivo",
      "Popular",
      "Sociable",
      "Confiado",
      "Desenvuelto",
      "Seductor",
      "Motivador",
      "Influenciador",
      "Alma de la fiesta",
      "Orientado a las relaciones"
    ],
    oppositeDescriptors: [
      "Reflexivo",
      "Tímido",
      "Introvertido",
      "Distante",
      "Metódico",
      "Perfeccionista",
      "Frío",
      "Cuidadoso",
      "Paciente",
      "Preciso",
      "Busca Hechos",
      "Investigador"
    ],
    interpretation:
      "De actitud sociable, impulsiva y dinámica. Persona muy conversadora, abierta y confiada, incluso con los recién conocidos y de actitud extrovertida a la hora de hacer nuevas amistades. Le encanta conocer gente nueva.",
    oppositeInterpretation:
      "De actitud reservada a la hora de conocer y tratar con gente nueva. Prefiere relacionarse con las personas de su entorno conocido, sus amigos y allegados. Persona muy controlada con sus emociones. Actitud reflexiva cuando evalúa a los demás y entabla nuevas relaciones."
  },
  // S
  {
    factor: "S",
    approach: "¿Cómo responde al ritmo de su medio ambiente?",
    descriptors: [
      "Amable",
      "Amigable",
      "Sabe escuchar",
      "Paciente",
      "Complaciente",
      "Relajado",
      "Tradicional",
      "Estable",
      "Consciente",
      "Predecible",
      "Jugador de equipo",
      "Comprensivo",
      "Armonioso",
      "Leal",
      "Pasivo",
      "Orientado a las personas"
    ],
    oppositeDescriptors: [
      "Directo",
      "Sentido de Urgencia",
      "Espontáneo",
      "Extrovertido",
      "Impetuoso",
      "Decidido",
      "Atrevido",
      "Innovador",
      "Osado",
      "Arriesgado",
      "Dominante",
      "Impaciente"
    ],
    interpretation:
      "De actitud disciplinada y leal. Prefiere un ambiente controlado, seguro y predecible. Prefiere la estabilidad al cambio. Es excelente miembro de equipo. Trabaja para un líder y una causa. Con habilidad para escuchar y se caracteriza por su paciencia.",
    oppositeInterpretation:
      "De actitud flexible, dinámica, y audaz. Prefiere ambientes poco estructurados, libres de controles. Le gusta el cambio y lo novedoso. Le gusta expresar sus ideas libremente. Se aburre fácilmente con lo rutinario. Tiene un gran sentido de urgencia y poca paciencia."
  },
  // C
  {
    factor: "C",
    approach: "¿Cómo responde a normas y protocolos?",
    descriptors: [
      "Exacto",
      "Analítico",
      "Consciente",
      "Equilibrado",
      "Cortés",
      "Metódico",
      "Diplomático",
      "Busca hechos",
      "Estándares altos",
      "Ordenado y Pulcro",
      "Paciente",
      "Preciso",
      "Recto",
      "Detallista",
      "Perfeccionista",
      "Orientado a los procesos"
    ],
    oppositeDescriptors: [
      "Rebelde",
      "Entusiasta",
      "Optimista",
      "Desafiante",
      "Extrovertido",
      "Independiente",
      "Popular",
      "Descuidado",
      "Confiado",
      "Rápido",
      "Promotor",
      "Espontáneo"
    ],
    interpretation:
      "Persona apegada a las normas, procedimientos y protocolos formales. Persona muy respetuosa de la autoridad. Prefiere hacer las cosas correctamente y conforme a los manuales de procedimientos. Las reglas no son flexibles para usted, están allí para cumplirlas. Se rige por altos estándares de calidad. Es hábil en el control y seguimiento.",
    oppositeInterpretation:
      "Favorece la obtención de resultados sobre el cumplimiento de las normas, ya que, su actitud es poco apegada a los protocolos y procedimientos formales. Prefiere recorrer el camino fácil para hacer las cosas. Se adapta a las demandas del momento. Utiliza las normas sólo como referencias flexibles."
  }
];
