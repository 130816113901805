import assessmentService from "@/services/assessmentService";

export default {
  state: () => ({
    currentStep: 0,
    assessment: null,
    answers: null,
    processingRequest: false
  }),
  mutations: {
    back(state) {
      if (state.currentStep > 0) {
        state.currentStep--;
      }
    },
    // TODO: Fix structure
    clear(state) {
      state.currentStep = 0;
      state.assessment = null;
    },
    next(state) {
      state.currentStep++;
    },
    setAnswers(state, answers) {
      state.answers = answers;
    },
    setAssessment(state, assessment) {
      state.assessment = assessment;
    },
    /**
     * Sets the processing request state flag.
     * @param {Object} state Store state object.
     * @param {boolean} processingRequest Processing request flag value.
     */
    setProcessingRequest(state, processingRequest) {
      state.processingRequest = processingRequest;
    }
  },
  actions: {
    clearAssessment({ commit }) {
      commit("clear");
    },
    createAssessment({ commit }, assessment) {
      commit("setProcessingRequest", true);
      return new Promise((resolve, reject) => {
        assessmentService
          .create(assessment)
          .then(assessmentId => {
            commit("setProcessingRequest", false);
            resolve(assessmentId);
          })
          .catch(error => {
            commit("setProcessingRequest", false);
            reject(error);
          });
      });
    },
    async getAssessment({ commit, getters }, assessmentId) {
      try {
        let assessment = getters.assessment;
        if (!assessment || assessment.id !== assessmentId) {
          commit("setProcessingRequest", true);
          assessment = await assessmentService.get(assessmentId);
          commit("setAssessment", assessment);
          commit("setProcessingRequest", false);
        }
        return assessment;
      } catch (error) {
        commit("setProcessingRequest", false);
        throw error;
      }
    },
    back({ commit }) {
      commit("back");
    },
    next({ commit }) {
      commit("next");
    },
    setAnswers({ commit }, answers) {
      commit("setAnswers", answers);
    },
    setAssessment({ commit }, assessment) {
      return new Promise(resolve => {
        commit("setAssessment", assessment);
        resolve();
      });
    },
    submitAssessment({ commit }) {
      commit("setProcessingRequest", true);
      return new Promise((resolve, reject) => {
        const assessment = this.getters.assessment;
        assessment.answers = this.getters.answers;
        assessmentService
          .submitAssessment(assessment)
          .then(() => {
            commit("clear");
            commit("setProcessingRequest", false);
            resolve();
          })
          .catch(error => {
            commit("setProcessingRequest", false);
            reject(new Error("Ha ocurrido un error inesperado"));
          });
      });
    },
    getReportData({ commit }, reportId) {
      commit("setProcessingRequest", true);
      return new Promise((resolve, reject) => {
        assessmentService
          .getReportData(reportId)
          .then(data => {
            commit("setProcessingRequest", false);
            resolve(data);
          })
          .catch(error => {
            commit("setProcessingRequest", false);
            reject(error);
          });
      });
    },
    listAssessments({}, opts) {
      const accountInfo = this.getters.accountInfo;
      return assessmentService.listAssessments(accountInfo.id, opts);
    },
    resendAssessment({ commit }, payload) {
      commit("setProcessingRequest", true);
      const { assessment, assessmentId } = payload;
      return new Promise((resolve, reject) => {
        assessmentService
          .resendAssessment(assessment, assessmentId)
          .then(() => {
            commit("setProcessingRequest", false);
            resolve();
          })
          .catch(error => {
            commit("setProcessingRequest", false);
            reject(error);
          });
      });
    },
    deleteAssessment({ commit }, assessmentId) {
      commit("setProcessingRequest", true);
      return new Promise((resolve, reject) => {
        assessmentService
          .deleteAssessment(assessmentId)
          .then(() => {
            commit("setProcessingRequest", false);
            resolve();
          })
          .catch(error => {
            commit("setProcessingRequest", false);
            reject(error);
          });
      });
    }
  },
  getters: {
    answers(state) {
      return state.answers;
    },
    currentStep(state) {
      return state.currentStep;
    },
    assessment(state) {
      return state.assessment;
    },
    processingRequest(state) {
      return state.processingRequest;
    }
  }
};
