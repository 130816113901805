<template>
  <o-page-layout mode="wide">
    <o-card-layout page-mode="wide" style="background: white !important">
      <v-container fluid>
        <v-row>
          <v-col class="text-left pt-0 pt-md-3" md="6" cols="10">
            <div>
              <h1>Estado de la Membresía</h1>
              {{ subscriptionInfo.status }}
            </div>
            <div>
              <span class="text-subtitle-2">Fecha de Expiración:</span>
              {{ subscriptionInfo.expiresAt }}
            </div>
          </v-col>
          <v-col
            cols="2"
            class="text-right content-align-center pt-0"
            v-if="isXS && !purchaseRequired"
          >
            <o-dropdown-menu icon="fas fa-ellipsis-v" :actions="mainAditionalActions" />
          </v-col>
          <v-spacer v-if="!!isXS"></v-spacer>
          <v-col class="text-right pt-0 pt-md-3" md="6" cols="12">
            <v-row class="text-center">
              <v-col>
                <div class="border-card-status">
                  <v-col class="px-0">
                    <div class="text-h5">{{ stats.available }}</div>
                    <div class="text-subtitle-2">Disponibles</div>
                  </v-col>
                </div>
              </v-col>
              <v-col>
                <div class="border-card-status">
                  <v-col class="px-0">
                    <div class="text-h5">{{ stats.sent }}</div>
                    <div class="text-subtitle-2">Enviados</div>
                  </v-col>
                </div>
              </v-col>
              <v-col>
                <div class="border-card-status">
                  <v-col class="px-0">
                    <div class="text-h5">{{ stats.submitted }}</div>
                    <div class="text-subtitle-2">Aplicados</div>
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="text-right justify-space-between align-center">
          <v-col cols="12" md="5">
            <o-text-field
              label="Filtrar por Aplicante / Receptor"
              hide-details
              v-model="textFilter"
            />
          </v-col>
          <v-col cols="6" sm="3" md="2" class="py-0 py-md-3">
            <v-switch
              color="secondary"
              dense
              label="Enviados"
              value="SENT"
              v-model="status"
            ></v-switch>
          </v-col>
          <v-col cols="6" sm="3" md="2" class="py-0 py-md-3">
            <v-switch
              color="secondary"
              dense
              label="Aplicados"
              value="SUBMITTED"
              v-model="status"
            ></v-switch>
          </v-col>
          <v-col
            class="text-right content-align-center"
            cols="12"
            md="2"
            :sm="purchaseRequired ? 6 : 5"
            v-if="!isXS"
          >
            <v-btn color="secondary" @click="sendAssessment" v-if="!purchaseRequired">
              <v-icon left dark>far fa-file</v-icon>
              Nueva Prueba
            </v-btn>
            <v-btn color="error" @click="purchaseMoreAssessments" v-else>
              <v-icon left dark>fas fa-shopping-cart</v-icon>
              Comprar Pruebas
            </v-btn>
          </v-col>
          <v-col
            cols="1"
            md="auto"
            class="text-right content-align-center"
            v-if="!isXS && !purchaseRequired"
          >
            <o-dropdown-menu icon="fas fa-ellipsis-v" :actions="mainAditionalActions" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              id="assessments-table"
              dense
              disable-pagination
              fixed-header
              hide-default-footer
              item-key="id"
              :headers="headers"
              :height="tableHeightStr"
              :items="filteredAssessments"
              style="overflow-y: scroll; height: 15rem"
            >
              <template v-slot:item.actions="{ item }">
                <o-dropdown-menu icon="fas fa-ellipsis-v" :actions="getActions(item)" />
              </template>
            </v-data-table>
            <v-btn block @click="listAssessments()" v-show="tableOptions.loadMore">
              Ver Más
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </o-card-layout>
  </o-page-layout>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

import purchaseGuardMixin from "@/mixins/purchaseGuardMixin";
import messageDialogMixin from "@/mixins/messageDialogMixin";
import { MEMBERSHIP_STATUS } from "@/model/assessment/constants";

const HEADER_HEIGHT = 80;
const HIDDEN_LEFT_COL_PADDING = 12;
const MID_COL_PADDING = 12;
const CARD_PADDING = 16;
const COUNTER_SECTION_HEIGHT = 78;
const FILTER_SECTION_HEIGHT = 86;
const TABLE_CONTAINER_PADDING = 12;
const TABLE_HEADER_HEIGHT = 32;
const TABLE_ROW_HEIGHT = 32;
const VIEW_MORE_HEIGHT = 36;
const HIDDEN_RIGHT_COL_PADDING = 12;
const HIDDEN_CARD_ACTIONS = 16;
const FOOTER_HEIGHT = 32;

const NET_OCCUPIED_VERTICAL_SPACE =
  HEADER_HEIGHT +
  HIDDEN_LEFT_COL_PADDING * 2 +
  MID_COL_PADDING +
  CARD_PADDING * 2 +
  COUNTER_SECTION_HEIGHT +
  FILTER_SECTION_HEIGHT +
  TABLE_CONTAINER_PADDING * 2 +
  HIDDEN_RIGHT_COL_PADDING * 2 +
  HIDDEN_CARD_ACTIONS * 2 +
  FOOTER_HEIGHT;

const MIN_TABLE_HEIGHT = TABLE_HEADER_HEIGHT + TABLE_ROW_HEIGHT * 5;

export default defineComponent({
  mixins: [purchaseGuardMixin, messageDialogMixin],
  data() {
    return {
      subscriptionInfo: null,
      // Data Table
      headers: [
        {
          text: "Aplicante",
          align: "start",
          value: "applicantName",
        },
        {
          text: "",
          sortable: false,
          align: "start",
          value: "applicantEmail",
        },
        {
          text: "Receptor",
          align: "start",
          value: "receiverName",
        },
        {
          text: "",
          sortable: false,
          align: "start",
          value: "receiverEmail",
        },
        {
          text: "Estado",
          align: "center",
          value: "statusText",
        },
        {
          text: "Enviado",
          align: "center",
          value: "sentAt",
        },
        {
          text: "Aplicado",
          align: "center",
          value: "submittedAt",
        },
        {
          text: "",
          sortable: false,
          aligh: "end",
          value: "actions",
        },
      ],
      tableOptions: {
        loading: false,
        page: 1,
        last: null,
        loadMore: true,
      },
      textFilter: "",
      status: ["SENT", "SUBMITTED"],
      assessments: [],
      mainAditionalActions: [
        {
          label: "Comprar Pruebas",
          icon: "fas fa-shopping-cart",
          handler: this.purchaseMoreAssessments,
        },
      ],
    };
  },
  computed: {
    stats() {
      return (
        this.$store.getters.accountInfo.accountStats || {
          available: 0,
          sent: 0,
          submitted: 0,
        }
      );
    },
    itemsPerPage() {
      const n = (this.tableHeight - TABLE_HEADER_HEIGHT) / TABLE_ROW_HEIGHT;
      return Math.floor(n > 10 ? n : 10);
    },
    tableHeight() {
      const vh = Math.max(document.body.clientHeight || 0, window.innerHeight || 0);
      const initialTableHeight =
        vh -
        NET_OCCUPIED_VERTICAL_SPACE -
        (this.tableOptions.loadMore ? VIEW_MORE_HEIGHT : 0);
      return initialTableHeight > MIN_TABLE_HEIGHT
        ? initialTableHeight
        : MIN_TABLE_HEIGHT;
    },
    tableHeightStr() {
      return `${this.tableHeight}px`;
    },
    filteredAssessments() {
      let result = this.assessments.filter((assessment) => {
        return this.status.includes(assessment.status);
      });

      if (this.textFilter.length > 0) {
        const filter = this.textFilter.toLowerCase();
        result = result.filter((assessment) => {
          return (
            assessment.applicantName.toLowerCase().includes(filter) ||
            assessment.applicantEmail.toLowerCase().includes(filter) ||
            assessment.receiverName.toLowerCase().includes(filter) ||
            assessment.receiverEmail.toLowerCase().includes(filter)
          );
        });
      }

      return result;
    },
    isXS() {
      return this.$vuetify.breakpoint.name == "xs";
    },
    purchaseRequired() {
      return this.stats.available === 0;
    },
  },
  methods: {
    sendAssessment() {
      this.$router.push("/assessment/send");
    },
    listAssessments(reload) {
      if (reload) {
        this.assessments = [];
        this.tableOptions.last = null;
      }

      this.tableOptions.loading = true;
      const n = this.itemsPerPage;
      const opts = {
        limit: n,
        start: this.tableOptions.last,
      };

      this.$store.dispatch("listAssessments", opts).then((response) => {
        if (response.data.length < n) {
          this.tableOptions.loadMore = false;
        }
        response.data.forEach((d) => this.assessments.push(d));
        this.tableOptions.last = response.last;
        this.tableOptions.loading = false;
      });
    },
    getActions(assessment) {
      const actions = [];

      if (assessment.status === "SENT") {
        actions.push({
          label: "Reenviar Prueba",
          icon: "fas fa-paper-plane",
          handler: () => this.resendAssessment(assessment),
        });
        actions.push({
          label: "Eliminar Prueba",
          icon: "fas fa-trash",
          handler: () => this.deleteAssessment(assessment),
        });
      } else if (assessment.status === "SUBMITTED") {
        actions.push({
          label: "Ver Reporte",
          icon: "fas fa-file-pdf",
          handler: () => this.viewAssessmentReport(assessment),
        });
        actions.push({
          label: "Reenviar Reporte",
          icon: "fas fa-paper-plane",
          handler: () => this.resendAssessment(assessment),
        });
      }

      return actions;
    },
    resendAssessment(assessment) {
      this.$router.push(`/assessment/${assessment.id}/resend`);
    },
    deleteAssessment(assessment) {
      this.decisionDialog(
        `Está por eliminar la prueba de ${assessment.applicantName} de manera irreversible. ¿Desea continuar con la operación?`,
        [
          { label: "No" },
          {
            label: "Sí",
            handler: () => this.deleteAssessmentProceed(assessment),
          },
        ]
      );
    },
    deleteAssessmentProceed(assessment) {
      this.$store
        .dispatch("deleteAssessment", assessment.id)
        .then(() => {
          this.successDialog("La eliminación de la prueba fue exitosa", () => {
            this.listAssessments(true);
            this.$store.dispatch("getAccountInfo");
          });
        })
        .catch((error) => {
          this.errorDialog("Ha ocurrido un error en la opeación");
        });
    },
    viewAssessmentReport(assessment) {
      this.$router.push(`/report/${assessment.reportId}`);
    },
    purchaseMoreAssessments() {
      this.$router.push("/shop/pack");
    },
  },
  created() {
    const subscriptionInfo = this.$store.getters.accountInfo.subscriptionInfo;
    this.subscriptionInfo = {
      status: subscriptionInfo ? MEMBERSHIP_STATUS[subscriptionInfo.status] : "-",
      expiresAt: subscriptionInfo
        ? new Date(subscriptionInfo.expiresAt * 1000).toLocaleDateString("es-ES")
        : "-",
    };
    this.listAssessments();

    let floatingAction = null;
    if (this.purchaseRequired) {
      floatingAction = {
        icon: "fas fa-shopping-cart",
        color: "error",
        handler: this.purchaseMoreAssessments,
      };
    } else {
      floatingAction = {
        icon: "far fa-file",
        color: "primary",
        handler: this.sendAssessment,
      };
    }
    this.$emit("set-floating-action", floatingAction);
  },
  beforeRouteLeave(to, from, next) {
    this.$emit("set-floating-action", null);
    next();
  },
});
</script>

<style>
.border-card-status {
  border-radius: 10px;
  background: #eeeeee;
}
</style>
