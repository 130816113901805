<template lang="html">
  <v-container>
    <v-row>
      <v-col cols="3" class="hidden-sm-and-down pa-0"></v-col>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-title class="primary-title text-h6 secondary primary--text">
            Datos Personales
          </v-card-title>
          <v-card-text>
            <v-form lazy-validation ref="form" v-model="isFormValid">
              <v-row class="px-2">
                <v-col cols="12" class="pb-0 pt-8">
                  <v-text-field
                    id="name"
                    name="name"
                    label="Nombre y Apellido"
                    outlined
                    clearable
                    required
                    v-model="form.fields.applicantName"
                    :rules="form.rules.applicantName"
                    prepend-icon="fas fa-user"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="px-2">
                <v-col cols="12" class="py-0">
                  <v-select
                    id="age"
                    name="age"
                    label="Rango de Edad"
                    outlined
                    clearable
                    required
                    v-model="form.fields.age"
                    :rules="form.rules.age"
                    prepend-icon="fas fa-birthday-cake"
                    :items="ageRanges"
                    item-text="item"
                    item-value="item"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="px-2">
                <v-col cols="12" class="py-0">
                  <v-text-field
                    id="company"
                    name="company"
                    label="Empresa (opcional)"
                    outlined
                    clearable
                    required
                    v-model="form.fields.company"
                    :rules="form.rules.company"
                    prepend-icon="fas fa-building"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="px-2">
                <v-col cols="12" class="py-0">
                  <v-text-field
                    id="position"
                    name="position"
                    label="Cargo (opcional)"
                    outlined
                    clearable
                    required
                    v-model="form.fields.position"
                    :rules="form.rules.position"
                    prepend-icon="fas fa-user-tie"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="3" class="hidden-sm-and-down"></v-col>
      <v-col cols="3" class="hidden-sm-and-down pt-0"></v-col>
      <v-col cols="6" md="3" class="align-content-center pr-1 pt-0">
        <v-btn
          block
          class="primary--text"
          color="secondary"
          large
          :to="assessmentStartURL"
        >
          Atrás
        </v-btn>
      </v-col>
      <v-col cols="6" md="3" class="align-content-center pl-1 pt-0">
        <v-btn
          block
          class="secondary--text"
          color="primary"
          large
          @click="next()"
        >
          Siguiente
        </v-btn>
      </v-col>
      <v-col cols="3" class="hidden-sm-and-down pa-0"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import assessmentMixin from "@/mixins/assessmentMixin";

import assessmentModel from "@/model/assessment/assessment";
import validations from "@/plugins/validations";

export default defineComponent({
  mixins: [assessmentMixin],
  created() {
    if (this.getAndValidateAssessment()) {
      this.form.fields.applicantName = this.assessment.applicantName;
      this.form.fields.age = this.assessment.age;
      this.form.fields.company = this.assessment.company;
      this.form.fields.position = this.assessment.position;
    }
  },
  data() {
    return {
      ageRanges: assessmentModel.ageRanges,
      isFormValid: false,
      form: {
        fields: {
          applicantName: null,
          age: null,
          company: null,
          position: null
        },
        rules: {
          applicantName: [validations.form.required_field],
          age: [validations.form.required_field],
          company: [],
          position: []
        }
      },
      assessment: null
    };
  },
  methods: {
    next() {
      if (this.$refs.form.validate()) {
        this.assessment.applicantName = this.form.fields.applicantName;
        this.assessment.age = this.form.fields.age;
        this.assessment.company = this.form.fields.company;
        this.assessment.position = this.form.fields.position;
        this.$store.dispatch("setAssessment", this.assessment).then(() => {
          this.$router.push(this.assessmentQuestionsURL);
        });
      }
    }
  }
});
</script>

<style lang="css" scoped></style>
