import axios from "axios";
import firebaseConfig from "@/config/firebase.json";

import assessmentModel from "@/model/assessment/assessment";
import percentages from "@/model/assessment/percentages";
import profiles from "@/model/assessment/profiles";
import descriptors from "@/model/assessment/descriptors";
import { ASSESSMENT_STATUS } from "@/model/assessment/constants";

import f from "@/plugins/formatter";
import firebase from "@/plugins/firebase";

const instance = axios.create({
  baseURL: firebaseConfig.apiBaseURL
});

export default {
  create: async assessment => {
    const newAssessment = f.objectCamelToSnakeCase(assessment, true);
    const assessmentId = await instance.post("/assessment/", newAssessment);
    return assessmentId;
  },
  get: async assessmentId => {
    const doc = await firebase
      .firestore()
      .collection("assessments")
      .doc(assessmentId)
      .get();
    if (doc.exists) {
      const assessment = doc.data();
      assessment.id = assessmentId;
      return f.objectSnakeToCamelCase(assessment, true);
    } else {
      return null;
    }
  },
  submitAssessment: async assessment => {
    const answers = assessment.answers;

    const newAssessment = f.objectCamelToSnakeCase(assessment, true);
    delete newAssessment.id;
    delete newAssessment.answers;
    newAssessment.version = "2";
    newAssessment.profiles = parseAnswers(answers);

    try {
      await instance.put(`/assessment/${assessment.id}/submit`, newAssessment);
    } catch (error) {
      throw error;
    }
  },
  getReportData: async reportId => {
    if (reportId) {
      try {
        const snapshot = await firebase
          .firestore()
          .collection("assessments")
          .where("report_id", "==", reportId)
          .get();

        if (!snapshot.empty) {
          const doc = snapshot.docs[0];
          if (doc.exists) {
            let data = f.objectSnakeToCamelCase(doc.data(), false);
            data = percentages.get(data);
            profiles.populate(data);
            data.descriptors = descriptors;
            delete data.receiverName;
            delete data.receiverEmail;
            delete data.accountId;
            return data;
          } else {
            throw new Error(`Assessment not found for Report Id = ${reportId}`);
          }
        } else {
          throw new Error(`Assessment not found for Report Id = ${reportId}`);
        }
      } catch (error) {
        throw error;
      }
    } else {
      throw new Error("Se requiere Id del Reporte");
    }
  },
  listAssessments: async (accountId, opts) => {
    if (accountId) {
      const start = (opts && opts.start) || Date.now();
      const limit = (opts && opts.limit) || 10;
      const snapshot = await firebase
        .firestore()
        .collection("assessments")
        .where("account_id", "==", accountId)
        .orderBy("sent_at", "desc")
        .startAfter(start)
        .limit(limit)
        .get();

      let assessments = [];
      const last = snapshot.docs[snapshot.docs.length - 1];

      if (!snapshot.empty) {
        assessments = snapshot.docs.map(doc => {
          const data = f.objectSnakeToCamelCase(doc.data(), true);
          data.id = doc.id;
          data.statusText = ASSESSMENT_STATUS[data.status];
          data.sentAt = new Date(data.sentAt).toLocaleDateString("es-ES");
          data.submittedAt = data.submittedAt
            ? new Date(data.submittedAt).toLocaleDateString("es-ES")
            : "-";
          return data;
        });
      }

      return {
        data: assessments,
        last: last ? last.data().sent_at : start
      };
    }
  },
  resendAssessment: async (assessment, assessmentId) => {
    const newAssessment = f.objectCamelToSnakeCase(assessment, false);
    await instance.put(`/assessment/${assessmentId}/resend`, newAssessment);
  },
  deleteAssessment: async assessmentId => {
    await instance.delete(`/assessment/${assessmentId}`);
  }
};

function parseAnswers(answers) {
  const consciousProfileId = [];
  const primaryProfileId = [];
  const consciousCounters = { D: 0, I: 0, S: 0, C: 0 };
  const primaryCounters = { D: 0, I: 0, S: 0, C: 0 };

  assessmentModel.questions.forEach((question, i) => {
    const answerThatMost = answers[i]["up"].index;
    const answerThatLess = answers[i]["dw"].index;
    const attributeThatMost = question.attributes[answerThatMost];
    const attributeThatLess = question.attributes[answerThatLess];
    const mt = attributeThatMost.value;
    const lt = attributeThatLess.value;
    consciousProfileId.push(mt);
    primaryProfileId.push(lt);
    if (hasToCount(mt, i)) {
      consciousCounters[mt]++;
    }
    if (hasToCount(lt, i)) {
      primaryCounters[lt]++;
    }
  });

  return {
    primary: {
      factorsPerAnswer: primaryProfileId.join(""),
      values: primaryCounters
    },
    conscious: {
      factorsPerAnswer: consciousProfileId.join(""),
      values: consciousCounters
    }
  };
}

function hasToCount(factor, questionIndex) {
  if (factor === "D" && [2, 17, 27].includes(questionIndex)) {
    // Count 0 for questions 3, 18, 28
    return false;
  } else if (factor === "C" && [13, 16].includes(questionIndex)) {
    // Count 0 for questions 14, 17
    return false;
  }
  return true;
}
